<template>
<div class="pa-body">

<!--
<div class="opt r">
    <i class="options icon-dots-vertical" @click="Options([
        {text: 'Nuevo Administrador', function: () => {modalAccount={role: 'local_admin'}}, if: tab=='Administrador'},
        {text: 'Nueva cuenta de Caja', function: () => {modalAccount={role: 'cashier'}}, if: tab=='Caja'},
        {text: 'Nueva cuenta de Mesero', function: () => {modalAccount={role: 'waiter'}}, if: tab=='Mesero'},
        {text: 'Nuevo cuenta Delivery', function: () => {modalAccount={role: 'delivery'}}, if: tab=='Delivery'},
        {text: 'Nuevo cuenta Cocina', function: () => {modalAccount={role: 'kitchen'}}, if: tab=='Cocina'},
    ])"></i>
</div>
-->


    <div class="mb20">

        <div class="flex align-center mb20">
            <div class="f18 flex-fill">Administradores</div>
            <i class="options icon-plus" @click="modalAccount={role: 'local_admin'}" v-tooltip="'Crear cuenta administrador'"></i>
        </div>

        <div class="secondary-text mt10 ta-center" v-if="!accountsGroup.local_admin.length">No hay cuentas</div>
        <div class="" v-else>                  
            <div class="section mb10 selectable"
            v-for="(account, index) in accountsGroup.local_admin"
            :key="index"
            @click="LoadHistory(account)">
                <div class="fill flex align-center">
                    
                    <avatar class="mr10"
                    :label="account.display_name"
                    :color="Util.AccountType(account).color">
                    </avatar>

                    <div class="flex-fill column">
                        <div class="flex-fill bold">{{account.display_name}}</div>
                        <div class="secondary-text">Creada el {{Util.Date(account.created_at)}}</div>
                    </div>
                    <div class="options icon-dots-vertical" @click.stop="() => {Options([
                        {text: 'Eliminar Cuenta', class: 'error-color', function: () => {DeleteAccount(account)}, confirm: '¿Eliminar cuenta definitivamente?'},
                        {text: 'Editar Cuenta', function: () => {$refs.accountForm.Open(account.id)}}
                    ], account.display_name)}"></div>
                </div>
            </div>
        </div>

    </div>

    <div class="mb20" v-if="Local.type == 'LOCAL'">

        <div class="flex align-center mb20">
            <div class="f18 flex-fill">Cajeros</div>
            <i class="options icon-plus" @click="modalAccount={role: 'cashier'}" v-tooltip="'Crear cuenta de caja'"></i>
        </div>

        <div class="secondary-text mt10 ta-center" v-if="!accountsGroup.cashier.length">No hay cuentas</div>
        <div class="" v-else>
            <div class="section mb10 selectable"
            v-for="(account, index) in accountsGroup.cashier"
            :key="index"
            @click="LoadHistory(account)">
                <div class="fill flex align-center">
                    
                    <avatar class="mr10"
                    :label="account.display_name"
                    :color="Util.AccountType(account).color">
                    </avatar>

                    <div class="flex-fill column">
                        <div class="flex-fill bold">{{account.display_name}}</div>
                        <div class="secondary-text">Creada el {{Util.Date(account.created_at)}}</div>
                    </div>
                    <div class="options icon-dots-vertical" @click.stop="() => {Options([
                        {text: 'Eliminar Cuenta', class: 'error-color', function: () => {DeleteAccount(account)}, confirm: '¿Eliminar cuenta definitivamente?'},
                        {text: 'Editar Cuenta', function: () => {$refs.accountForm.Open(account.id)}}
                    ], account.display_name)}"></div>
                </div>
            </div>
        </div>

    </div>

    <div class="mb20" v-if="Local.modules.module_tables && Local.type == 'LOCAL'">

        <div class="flex align-center mb20">
            <div class="f18 flex-fill">Meseros</div>
            <i class="options icon-plus" @click="modalAccount={role: 'waiter'}" v-tooltip="'Crear cuenta de mesero'"></i>
        </div>

        <div class="secondary-text mt10 ta-center" v-if="!accountsGroup.waiter.length">No hay cuentas</div>
        <div class="" v-else>                    
            <div class="section mb10 selectable"
            v-for="(account, index) in accountsGroup.waiter"
            :key="index"
            @click="LoadHistory(account)">
                <div class="fill flex align-center">
                    
                    <avatar class="mr10"
                    :label="account.display_name"
                    :color="Util.AccountType(account).color">
                    </avatar>

                    <div class="flex-fill column">
                        <div class="flex-fill bold">{{account.display_name}}</div>
                        <div class="secondary-text">Creada el {{Util.Date(account.created_at)}}</div>
                    </div>
                    <div class="options icon-dots-vertical" @click.stop="() => {Options([
                        {text: 'Eliminar Cuenta', class: 'error-color', function: () => {DeleteAccount(account)}, confirm: '¿Eliminar cuenta definitivamente?'},
                        {text: 'Editar Cuenta', function: () => {$refs.accountForm.Open(account.id)}}
                    ], account.display_name)}"></div>
                </div>
            </div>
        </div>

    </div>

    <div class="mb20" v-if="Local.type == 'LOCAL'">

        <div class="flex align-center mb20">
            <div class="f18 flex-fill">Repartidores</div>
            <i class="options icon-plus" @click="modalAccount={role: 'delivery'}" v-tooltip="'Crear cuenta de repartidor'"></i>
        </div>

        <div class="secondary-text mt10 ta-center" v-if="!accountsGroup.delivery.length">No hay cuentas</div>
        <div class="" v-else>                    
            <div class="section mb10 selectable"
            v-for="(account, index) in accountsGroup.delivery"
            :key="index"
            @click="LoadHistory(account)">
                <div class="fill flex align-center">

                    <avatar class="mr10"
                    :label="account.display_name"
                    :color="Util.AccountType(account).color">
                    </avatar>

                    <div class="flex-fill column">
                        <div class="flex-fill bold">{{account.display_name}}</div>
                        <div class="secondary-text">Creada el {{Util.Date(account.created_at)}}</div>
                    </div>
                    <div class="options icon-dots-vertical" @click.stop="() => {Options([
                        {text: 'Eliminar Cuenta', class: 'error-color', function: () => {DeleteAccount(account)}, confirm: '¿Eliminar cuenta definitivamente?'},
                        {text: 'Editar Cuenta', function: () => {$refs.accountForm.Open(account.id)}}
                    ], account.display_name)}"></div>
                </div>
            </div>
        </div>

    </div>

    <div v-if="Local.type == 'LOCAL'">

        <div class="flex align-center mb20">
            <div class="f18 flex-fill">Cocina</div>
            <i class="options icon-plus" @click="modalAccount={role: 'kitchen'}" v-tooltip="'Crear cuenta de cocina'"></i>
        </div>

        <div class="secondary-text mt10 ta-center" v-if="!accountsGroup.kitchen.length">No hay cuentas</div>
        <div class="" v-else>                    
            <div class="section mb10"
            v-for="(account, index) in accountsGroup.kitchen"
            :key="index">
                <div class="fill flex align-center">

                    <avatar class="mr10"
                    :label="account.display_name"
                    :color="Util.AccountType(account).color">
                    </avatar>

                    <div class="flex-fill column">
                        <div class="flex-fill bold">{{account.display_name}}</div>
                        <div class="secondary-text">Creada el {{Util.Date(account.created_at)}}</div>
                    </div>
                    <div class="options icon-dots-vertical" @click.stop="() => {Options([
                        {text: 'Eliminar Cuenta', class: 'error-color', function: () => {DeleteAccount(account)}, confirm: '¿Eliminar cuenta definitivamente?'},
                        {text: 'Editar Cuenta', function: () => {$refs.accountForm.Open(account.id)}}
                    ], account.display_name)}"></div>
                </div>
            </div>
        </div>

    </div>


<modal v-model="modalAccount" :dir="['right', 'center']" :modalClass="['fill', 'sm']" title="Nueva Cuenta">
    <div class="section" v-if="modalAccount">
        <input-field class="mb15"
        v-model="modalAccount.display_name"
        label="Nombre">
        </input-field>

        <input-field class="mb15"
        v-model="modalAccount.user"
        label="Usuario"
        placeholder="Para iniciar sesión">
        </input-field>

        <input-field class="mb15"
        v-model="modalAccount.phone"
        label="Teléfono"
        placeholder="(Opcional)">
        </input-field>

        <input-field class="mb15"
        v-model="modalAccount.password"
        label="Contraseña"
        type="password">
        </input-field>

        <input-field
        v-model="modalAccount.password_confirmation"
        label="Repetir Contraseña"
        type="password">
        </input-field>

    </div>
    
    <button slot="actions" class="primary purple" @click="SaveAccount()">Guardar</button>
    
</modal>

<modal v-model="modalHistory" dir="right" :modalClass="['fill', 'md right h100']" :title="`Historial de ${modalHistory ? modalHistory.account.display_name : null}`" hideActions>
    <vertical-content @OnBottom="LoadHistory()" class="pa20">
        <div class="" v-if="modalHistory && modalHistory.data.length > 0">
            <div class="entry"
            v-for="(entry, index) in modalHistory.data"
            :key="index"
            @click="ClickHistoryEntry(entry)">
                <div class="flex fill align-center">
                    <div class="flex column flex-fill">
                        <div class="bold">{{Util.Date(entry.date, 'LLLL')}}</div>
                        <div class="secondary-text">{{entry.title}}</div>
                    </div>
                    <!--<i v-if="entry.type.indexOf('TRANSACTION') > -1" class="icon icon-arrow-right c4 ml10"></i>-->
                </div>
            </div>
        </div>
        <div class="secondary-text ta-center mt10" v-else>No hay datos.</div>
    </vertical-content>
</modal>

<transaction-details ref="transactionDetails"></transaction-details>
<account-form ref="accountForm"></account-form>

</div>
</template>

<script>
export default {
    data() {
        return {
            tab: null,
            tabs: null,
            roles: {
                local_admin: 'Administrador',
                cashier: 'Cajero',
                delivery: 'Repartidor'
            },
            accounts: [],
            local: null,
            isEditAddress: false,
            isEdit: true,
            isNew: false,
            loading: false,
            modalSubLocal: false,
            subLocal: {},
            modalAccount: false,
            accountData: {},
            modalPayMethods: null,
            modalExpenses: null,
            dictDays: {'monday': 'Lunes', 'tuesday': 'Martes', 'wednesday':'Miercoles', 'thursday':'Jueves', 'friday':'Viernes', 'saturday':'Sábado', 'sunday':'Domingo'},
            modalReports: null,
            modalNewReport: null,
            modalPlan: false,
            modalNewAlert: null,
            sections: null,
            modalHistory: null,
            tabsAccount: {
                'local_admin': ['General', 'Reportes', 'Alertas'],
                'cashier': ['General', 'Restricciones'],
                'delivery': ['General']
            },
            reportTimes: [
                {text: '00:00 hrs', value: 0},
                {text: '01:00 hrs', value: 1},
                {text: '02:00 hrs', value: 2},
                {text: '03:00 hrs', value: 3},
                {text: '04:00 hrs', value: 4},
                {text: '05:00 hrs', value: 5},
                {text: '06:00 hrs', value: 6},
                {text: '07:00 hrs', value: 7},
                {text: '08:00 hrs', value: 8},
                {text: '09:00 hrs', value: 9},
                {text: '10:00 hrs', value: 10},
                {text: '11:00 hrs', value: 11},
                {text: '12:00 hrs', value: 12},
                {text: '13:00 hrs', value: 13},
                {text: '14:00 hrs', value: 14},
                {text: '15:00 hrs', value: 15},
                {text: '16:00 hrs', value: 16},
                {text: '17:00 hrs', value: 17},
                {text: '18:00 hrs', value: 18},
                {text: '19:00 hrs', value: 19},
                {text: '20:00 hrs', value: 20},
                {text: '21:00 hrs', value: 21},
                {text: '22:00 hrs', value: 22},
                {text: '23:00 hrs', value: 23},
            ]
        }
    },
    mounted() {
    
        this.tabs = ['Administrador'];
        if(this.Local.type == 'LOCAL') this.tabs.push('Caja');
        if(this.Local.type == 'LOCAL') this.tabs.push('Delivery');
        if(this.Local.type == 'LOCAL' && this.Local.modules.module_tables) this.tabs.push('Mesero');
        if(this.Local.type == 'LOCAL') this.tabs.push('Cocina');

    },
    methods: {
        Init(force) {
            if(force || !this.accounts.length)
            {
                this.Loading();
                axios.get(`/accounts/${this.Local.id}`).then((res) => {
                    //Util.DefaultLocalConfig(res.data);
                    this.accounts = res.data;
                    this.Response(res);
                }).catch(err => {
                    this.Response(err);
                });
            }
        },
        SaveAccount() {
            this.$root.$emit('Loading');
            axios.put(`/account/${this.Local.id}`, this.modalAccount).then(res => {
                this.$root.$emit('Response', res);
                this.accounts.push(res.data);
                this.modalAccount = false;
            }).catch(err => {
                this.$root.$emit('Response', err);
            });
        },
        DeleteAccount(account) {
            this.$root.$emit('Loading');
            axios.delete(`/account/${account.id}`).then(res => {
                this.$root.$emit('Response', res);
                for(var i = 0; i < this.accounts.length; i++)
                    if(this.accounts[i].id == account.id) return this.accounts.splice(i, 1);
            }).catch(err => {
                this.$root.$emit('Response', err);
            });
        },
        LoadHistory(account)
        {
            if(account) this.modalHistory={account: account, data: []};
            this.$root.$emit('Loading');
            axios.get(`/account/${this.modalHistory.account.id}/history${this.modalHistory.last?'/'+this.modalHistory.last : ''}`).then(res => {
                this.$root.$emit('Response', res);
                //var list = this.modalHistory.data.concat(res.data);
                if(res.data.length > 0) this.modalHistory.last = res.data[res.data.length-1].date;
                this.$set(this.modalHistory, 'data', this.modalHistory.data.concat(res.data));
            }).catch(err => {
                this.$root.$emit('Response', err);
            });
        },
        ClickHistoryEntry(entry)
        {
            if(entry.id_transaction)
                this.$refs.transactionDetails.Open(entry.id_transaction);
        }
    },
    computed: { 
        accountsGroup() {
            var ret = {
                local_admin: [],
                delivery: [],
                cashier: [],
                kitchen: [],
                waiter: []
            };
            for(var i = 0; i < this.accounts.length; i++)
            {
                var account = this.accounts[i];
                if(ret[account.role])
                    ret[account.role].push(account);
            }
            return ret;
        }
    }
}
</script>

<style>
.logo-image {
    height: 100px;
}
.logo-image img {
    height: 100%;
}
.local-gradient {
    background: linear-gradient(to bottom, rgb(0, 181, 255) 0%,white 15%);
}
</style>