<template>
<div>

    <div v-if="label" class="bold">{{label}}</div>
    <div class="secondary-text" v-if="description">{{description}}</div>

    <div @click="readonly ? null : (!isOpen ? Open() : Close())" ref="el">
        
        <slot></slot>
        <div v-show="!isHidden" class="input-group flex w100">
            <button v-if="icon" :class="`transparent icon icon-${icon}`"></button>
            <div v-else ref="input" :class="`${(currentItem && currentItem.icon) || pre ? 'pre' : ''} input-field w100 input-select relative after selectable ${inputClass} ${label || description ? 'mt10' : ''}`">
                
                <button class="absolute fill trbl0 bgt"></button>
                <div class="pre" v-if="currentItem && currentItem.icon">
                    <i :class="`icon icon-${currentItem.icon}`"></i>
                </div>
                <div class="pre" v-else-if="pre">
                    <i :class="`icon icon-${pre}`"></i>
                </div>
                <!--<input readonly :value="currentItem ? currentItem.text : ''" class="selectable" v-unselectable>-->
                <div class="input contain" v-if="currentItem && currentItem.text">{{currentItem.text}}</div>
                <div class="input cgray2 contain placeholder" v-else>{{placeholder}}</div>
                <div class="after">
                    <i @click.stop="SelectItem(null); $emit('OnClearItem')" v-if="cleareable" v-show="currentItem && currentItem.text" :class="`selectable icon icon-cross`"></i>
                    <i v-else :class="`icon icon-${selectIcon}`"></i>
                </div>
            </div>
            <slot name="after"></slot>
        </div>

    </div>


    <modal v-model="isOpen" 
    hideActions 
    :domElement="$refs.el" 
    :offset="coverInput ? {x: -17, width: 34, y: -57} : null" 
    :hideBackdrop="desktop" :dir="['up', coverInput ? 'pop' : 'center']" 
    :modalClass="['w100 up']" 
    :emitEvents="mobile"
    ref="modal">
        <div class="input-popover" ref="options">
               
                <div :class="`flex column ${inputBottom ? 'reverse' : ''}`">
                    
                    <input-field v-if="canType" ref="modalInput" class="search"
                    v-model="search" :class="` ${inputBottom ? 'pt10' : 'pb10'}`" inputClass="secondary" 
                    :submit="canType === true" :pre="canType === true ? (pre ? pre : '') : 'search'" :cleareable="canType && canType !== true"
                    :placeholder="canType === true ? 'Buscar o ingresar otro valor...' : 'Buscar...'"
                    @OnEnter="canType === true ? EnterItem($event) : null">
                    </input-field>

                    <div v-if="isOpen">
                    
                        <div class="pa20 ta-center" v-if="apiLoading">
                            <i class="icon icon-semi-circle spin"></i>
                        </div>
                        <div v-if="api && !apiLoading" :class="`flex column ${reverse ? 'reverse' : ''}`">
                            
                            <button v-for="(item, index) in apiValues" :key="index" @click="ClickOnApiItem(item)" class="pb5 pt5"
                            ref="optionsButtons">
                                    <div class="flex fill align-center">
                                        <i v-if="item.icon" :class="`mr8 icon icon-${item.icon}`"></i>
                                        <div class="flex column">
                                            <span class="flex-fill ta-left" style="min-width: 56px">{{item.text}}</span>
                                            <div v-if="item.description" class="secondary-text ta-left">{{item.description}}</div>
                                        </div>
                                    </div>
                            </button>
                        </div>

                        <div v-if="!api" :class="`flex column ${reverse ? 'reverse' : ''}`">
                            <button v-for="(item, index) in visibleItems" :key="index" @click="ClickOnItem(item)" v-show="!('if' in item) || item.if"
                            :class="`pt5 pb5 ${currentItem && item.value == currentItem.value ? 'selected' : ''} ${item.class}`"
                            ref="optionsButtons"
                            :disabled="!!item.separator">
                                
                                    <div class="flex fill align-center">
                                        <i v-if="item.icon" :class="`mr8 icon icon-${item.icon}`"></i>
                                        <div class="flex column">
                                            <span class="flex-fill ta-left" style="min-width: 56px">{{item.text}}</span>
                                            <div v-if="item.description" class="secondary-text ta-left">{{item.description}}</div>
                                        </div>
                                    </div>
                            </button>
                        </div>
                    </div>
                </div>
        </div>
    </modal>

  
    
</div>
</template>

<script>
export default {
    props: {
        value: {
            type: [String, Number, Boolean, Array, Object, Function],
            default: null
        },
        items: {
            type: Array,
            default: () => []
        },
        canType: {
            type: [Boolean, String],
            default: false
        },
        placeholder: {
            type: String,
            default: ''
        },
        pre: {
            type: String,
            default: null
        },
        icon: {
            type: String,
            default: null
        },
        inputClass: {
            type: String,
            default: null
        },
        label: {
            type: String,
            default: null
        },
        description: {
            type: String,
            default: null
        },
        isHidden: {
            type: Boolean,
            default: false,
        },
        cleareable: {
            type: Boolean,
            default: false,
        },
        reverse: {
            type: Boolean,
            default: false,
        },
        coverInput: {
            type: Boolean,
            default: false
        },
        selectIcon: {
            type: String,
            default: 'arrow-down'
        },
        api: {
            type: Object,
            default: null
        },
        customValueFunction: {
            type: Function,
            default: null
        },
        triggerFirstValueChanged: {
            type: Boolean,
            default: true
        },
        readonly: {
            type: Boolean,
            default: false
        },
        callbackOnly: {
            type: Boolean,
            default: false
        },
    },
    data() {
        return {
            uid: null,
            currentItem: null,
            currentIndex: -1,
            isOpen: false,
            search: '',
            spacerWidth: 0,
            apiTimer: null,
            apiValues: [],
            apiLoading: false,
            arrowMovementIndex: -1,
        }
    },
    beforeCreate() {
        
    },
    mounted() {
        this.uid = this.Util.UID();
        this.SelectItem(this.value);    
    },
    methods: {
        Open() {
            this.apiValues = []
            this.search = ''
            if(this.isOpen) return;
            this.isOpen = true;

            setTimeout(() => {
                if(this.canType && this.$refs.modalInput)
                {
                
                    if(this.canType != 'search' || this.desktop) this.$refs.modalInput.Focus();
                }

                if(this.$refs.options)
                {
                    var selected = this.$refs.options.querySelector('.selected');
                    if(selected) selected.scrollIntoView();
                }

            }, this.mobile ? 400 : 50);

        },
        Close() {
            this.isOpen = false;
        },
        SelectItem(value) {
            for(var i = 0; i < this.items.length; i++)
            {
                var item = this.items[i];
                if('value' in item && item.value === value)
                {
                    this.currentIndex = i;
                    this.currentItem = item;
                    this.isOpen = false;
                    return;
                }
            }
            if(this.canType && this.canType !== 'search')
            {
                this.currentIndex = -1;
                this.currentItem = {text: value, value: value};
                return
            }
            
            if(this.callbackOnly) return this.currentItem = {callbackOnly: true};

            if(this.items && this.items.length)
            {
                var item = this.items[0];
                if('value' in item)
                {
                    this.currentItem = item;
                    this.currentIndex = 0;
                }
            }
                      
        },
        EnterItem(value)
        {
            for(var i = 0; i < this.items.length; i++)
            {
                var item = this.items[i];
                if('text' in item && item.text === value)
                {
                    this.currentIndex = i;
                    this.currentItem = item;
                    this.isOpen = false;
                    this.$emit('OnEnterItem', ret);
                    return;
                }
            }

            var ret =  {text: value, value: value};
            if(this.customValueFunction)
            {
                ret = this.customValueFunction(value);
            }

            this.currentIndex = -1;
            this.currentItem = ret;
            this.isOpen = false;
            this.$emit('OnEnterItem', ret);
        },
        ClickOnItem(item)
        {
            if(item.separator) return;
            if(this.$refs.popper) this.$refs.popper.hide();
            if(this.$refs.mobileList) this.$refs.mobileList.Close();
            if(item.confirm) return this.Confirm({
                function: item.function,
                class: item.class,
                text: item.text,
                title: item.confirm,
                icon: item.icon
            });
            
            if(item.function) {
                item.function();
            }else {
                this.SelectItem(item.value) ;
            }
        },
        SearchAPI() 
        {
            if(this.apiTimer) clearTimeout(this.apiTimer);
            this.apiTimer = null;
            this.apiLoading = true;
            axios.get(this.api.url.replace('{SEARCH}', this.search)).then(res => {
                var ret = [];
                res.data.forEach(item => {
                    ret.push({
                        text: this.api.text ? item[this.api.text] : '',
                        description: this.api.description ? item[this.api.description] : '',
                        value: item
                    });
                })
                this.apiValues = ret;
                this.apiLoading = false;
            }).catch(err => {
                console.log(err);
                this.apiLoading = false;
            });
        },
        ClickOnApiItem(item)
        {
            this.isOpen = false;
            this.$emit('OnItemSelected', item.value);
        },
        ArrowKeysMovement(event)
        {
            var options = this.$refs.optionsButtons;
            if(!options || !options.length) return;
            var dir = 0;
            if(event.key == 'ArrowDown') dir = 1;
            else if(event.key == 'ArrowUp') dir = -1;
            if(dir !== 0)
            {
                this.arrowMovementIndex = (this.arrowMovementIndex + dir) % options.length;
                if(this.arrowMovementIndex < 0) this.arrowMovementIndex = options.length - 1;
                if(dir > 0) this.$refs.options.scrollTop = 9999999999;
                options[this.arrowMovementIndex].focus();
            }
        },
        CleanCurrentItem(){
            this.currentItem = { text : null, value : null}
        }
    },
    computed: {
        visibleItems() {
            if(!this.canType) return this.items;
            var ret = [];
            this.items.forEach(item => {
                if(this.Util.InString(`${item.text} ${item.description ? item.description : ''}`, this.search)) ret.push(item);
            });
            return ret;
        },
        inputBottom() {
            if(this.mobile) return true;
            if(this.isOpen) return this.$refs.modal && this.$refs.modal.fixedPosition && 'bottom' in this.$refs.modal.fixedPosition;
        }
    },
    watch: { 
        isOpen: function(n, o)
        {          
            document.removeEventListener('keydown', this.ArrowKeysMovement);
            if(n) document.addEventListener('keydown', this.ArrowKeysMovement);
        },
        currentItem: function(n, o) 
        {   
            // console.log("current", this.currentItem);
            // console.log("NEW",n);
            // console.log("OLD",o);
            //if(!n) return
            if(n && n.callbackOnly) return;
            if(JSON.stringify(n) !== JSON.stringify(o))
            {
                this.$emit('input', n.value);
                if(o || this.triggerFirstValueChanged)
                {
                    this.$emit('OnValueChanged', n.value);
                    if(this.callbackOnly) this.currentItem = {callbackOnly: true};
                }
            }
        },
        value: function(n, o) {
            //console.log('value');
            //console.log(n);
            this.SelectItem(n);
        },
        search: function(n, o) {
            if(this.api) {
                if(this.apiTimer) clearTimeout(this.apiTimer);
                this.apiTimer = null;
                if(n) 
                {
                    this.apiLoading = true;
                    this.apiTimer = setTimeout(this.SearchAPI, 200);
                }
                else
                {
                    this.apiLoading = false;
                }
            }
        },
        items: function(n, o) {
            if(o)
                this.SelectItem(this.value);
        },
        currentIndex: function(n, o) {
            this.arrowMovementIndex = n;
        }
    }
}
</script>

<style lang="scss">
.popover {
    padding: 16px;
    border: 1px solid var(--gray-2);
    border-radius: 10px;
    &.sm {
        max-width: 340px;
    }
    z-index: 1000;
}

.input-select {
    background-color: var(--white);
}

.input-popover {
    padding: 16px;
    overflow-y: auto;
    max-height: 40vh;
    border: 1px solid var(--gray-2);
    border-radius: 10px;
    background-color: var(--white);
    
    button:not(.primary) {
        width: 100%;
        justify-content: left;
        background: transparent;
        font-weight: normal;
        margin-bottom: 2px;
        min-height: 40px;
        height: auto !important;
        color: var(--black);
        &:not([disabled]):hover {
            background-color: var(--gray-3);
            color: var(--blue);
        }
        &.selected {
            background-color: var(--gray-cyan);
            color: var(--blue);
            font-weight: 500;
            
            &::after {
                content: "q";
                font-family: "influye-admin-font" !important;
                margin-left: 16px;
            }

        }

        &.error-color {
            color: var(--red);
            &:hover
            {
                color: var(--red);
                background-color: rgba(var(--red-rgb), 0.05);
            }
        }
    }

    .search {
        position: sticky;
        top: 0;
        background-color: var(--white);
        //outline: 10px solid var(--white);
        box-shadow: 0px -20px 0px 0px var(--white);
    }

    .reverse {
        .search {
            bottom: 0;
            top: unset;
            box-shadow: 0px 20px 0px 0px var(--white);
        }
    }

   

}

.smooth-white {
    position: relative;
    &::before {
        position: absolute;
        content: '';
        background: linear-gradient(white, transparent);
        height: 24px;
        left: 0;
        right: 0;
        top: 0px;
        pointer-events: none;
    }
    &::after {
        position: absolute;
        content: '';
        background: linear-gradient(transparent, white);
        height: 24px;
        left: 0;
        right: 0;
        bottom: 0px;
        pointer-events: none;
    }
}

//Mobile
@media (max-width: 820px) {
    .popover {
        border: none;
        &.sm {
            max-width: 100%;
        }
    }
    .input-popover {
        max-height: 80vh;
        border: none;
    }
}

.dark {

    .input-select {
        background-color: transparent;
    }

    .popover {
        border: none;
    }

    .input-popover {
        border: none;
        background-color: var(--dark-bg-3);
        
        button:not(.primary) {
            color: var(--dark-fc-1);
            &:not([disabled]):hover {
                background-color: var(--dark-bg-1);
                color: var(--dark-purple);
            }
            &.selected {
                background-color: var(--dark-bg-1);
                color: var(--cyan);
            }

            &.error-color {
                color: var(--red);
                &:hover
                {
                    color: var(--red);
                    background-color: rgba(var(--red-rgb), 0.05);
                }
            }
        }

        .search {
            background-color: var(--dark-bg-2);
            //outline: 10px solid var(--dark-bg-2);
            box-shadow: 0px -20px 0px 0px var(--dark-bg-2);
        }

        .reverse {
            .search {
                bottom: 0;
                top: unset;
                box-shadow: 0px 20px 0px 0px var(--dark-bg-2);
            }
        }

    }
}
</style>
