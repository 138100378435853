<template>
<div class="relative fill flex column justify-center align-center ofy login-bg">

    <video autoplay muted loop class="absolute trbl0 fill" style="object-fit: fill;">
        <source src="https://backend.influye.app/storage/resources/bggradient.mp4" type="video/mp4">
    </video>

    

    <div class="absolute trbl0 fill flex column justify-center align-center">

        <!--<a href="https://influyeapp.cl" target="_blank" class="absolute more" style="top: 10px; right: 10px;">{{desktop ? 'Ver planes y más información' : 'Más Información'}} <i class="icon icon-external"></i></a>-->

        <div class="flex column w100 align-center absolute" style="bottom: 10px; left: 0;">
            <a href="https://influyeapp.cl" target="_blank" class="more mb5">Ver planes y más información</a>
            <div class="flex">
                <a href="https://influye.tawk.help/category/informaci%C3%B3n-legal" target="_blank" class="more mr10">Condiciones y Política de privacidad</a>
            </div>
        </div>

        <img src="../../assets/logo-login.png" width="200px" style="margin-bottom: 30px">
        
        <div class="hs">

            <div class="section" style="width: 400px" v-if="updates && desktop">
                <div class="updates-container" >
                    <div class="f18 mb20 cselected">¿Que hay de nuevo?</div>
                    <div class="entry" v-for="update in updates" :key="update.url">
                        <div class="title">{{update.title}}</div>
                        <div class="description">{{update.description}}</div>
                        <a :href="update.url" target="_blank">Leer más...</a>
                    </div>
                </div>
            </div>

            <div class="flex column section" style="width: 300px">

                <div class="flex column justify-center h100" v-if="login===false">
                    <div>
                        <div class="f18 mb20 cselected">Iniciar Sesión</div>
                    </div>
                    <input-field inputClass="secondary"
                    v-model="local"
                    placeholder="Nombre del local"
                    @OnEnter="EnterLocal()">
                    </input-field>

                    <button class="primary purple mt15" @click="EnterLocal()">Entrar</button>
                    
                </div>
                
                <div class="flex column justify-center h100" v-if="login ===true">
                    
                    <div v-if="localData" class="ta-center mb10">
                        <img v-if="localData.logotype" :src="img(`logos/${localData.logotype}`)" style="max-width: 150px; max-height: 100px; border-radius: 50%">
                        <div v-else style="font-size: 24px; font-weight: 700; margin-bottom: 20px; color: #3d3b45">{{localData.name}}</div>
                        <div class="secondary-text ta-center mt10 selectable" @click="LeaveLocal()">Cambiar Local</div>
                    </div>

                    <input-field class="mb15" ref="inputUser"
                    v-model="account.user"
                    placeholder="Cuenta de usuario"
                    @OnEnter="Login()"
                    inputClass="secondary">
                    </input-field>

                    <input-field class="mb15"
                    v-model="account.password"
                    type="password"
                    placeholder="Contraseña"
                    @OnEnter="Login()"
                    inputClass="secondary">
                    </input-field>

                    <button class="primary purple" @click="Login()" :disabled="!account.user || !account.password">Iniciar Sesión</button>

                    <div class="secondary-text ta-center mt10 selectable" v-if="login && !localData" @click="login=false">Atrás</div>

                </div>

            </div>
            
        </div>
    </div>

</div>
</template>

<script>
export default {
    data() {
        return {
            account: {},
            local: null,
            localData: null,
            login: null,
            updates: null
        }
    },
    mounted() {
        
        axios.get('/updates/log').then(res => {
            this.updates = res.data;
        }).catch(err => {

        });

        NativeStorage.getItem('owner', (val) => {
            this.localData = val;
            this.login = true;
            if(val.server)
            {
                this.Util.SetServer(val.server, 'DEFAULT');
            }
        }, () => {
            this.login = false;
        });
        
        if(this.$route.query.local)
        {
            this.local = this.$route.query.local;
            this.Util.SetServer();
            this.EnterLocal();
        }
        if(this.$route.query.user)
            this.$set(this.account, 'user', this.$route.query.user);
        if(this.$route.query.password)
            this.$set(this.account, 'password', this.$route.query.password);
        
    },
    methods: {
        Login() {
            this.$root.$emit('Loading');
            var data = {
                user: this.localData ? `${this.localData.slug}.${this.account.user}` : this.account.user,
                password: this.account.password
            };
            axios.post('/login', data).then((res) => {
                if(res.data.error)
                {
                    this.Response(res.data);
                }
                else
                {
                    this.$root.$emit('Response', res);
                    NativeStorage.setItem('token', res.data.token, () => {
                        this.$store.commit('login', {token: res.data.token, app: this.$root});
                    }, () => {

                    });
                }
            }).catch(err => {
                this.Response(err);
            });
        },
        EnterLocal() {
            if(!this.local)
            {
                this.login = true;
            }
            else
            {
                this.Loading();
                axios.get(`/login/local/${this.local}`).then(res => {
                    this.Response(res);
                    this.localData = res.data;

                    this.Util.SetServer(res.data.server);

                    this.login = true;
                    NativeStorage.setItem('owner', res.data, () => {}, () => {});
                    this.$nextTick(() => {
                        this.$refs.inputUser.Focus();
                    });
                }).catch(err => {
                    this.Response(err);
                });
            }
        },
        LeaveLocal() {
            this.local = null;
            this.localData = null;
            this.login = false;
            NativeStorage.remove('owner');
            this.Util.SetServer();
        },
    },
    computed: {
        
    },
}
</script>
<style lang="scss">
.login-bg {
    background-color: var(--purple);

    a.more {
        color:white; 
        text-decoration: none;
    }

}

video {
  object-fit: fill;
}

.updates-container {
    max-height: 307px;
    overflow-y: auto;

    a, a:visited {
        color: var(--purple);
    }

    .entry {
        margin-top: 10px; 
        padding: 10px 0px;
        border-color: var(--gray-2-5);
        &:nth-child(2) {
            border-top: none;
            margin-top: 0px;
            padding-top: 0px;
        }
        .title {
            font-size: 18px;
            font-weight: 500;
        }
        .description {
            margin: 10px 0px;
            font-size: 13px;
        }
    }

}

.dark {
    
    .updates-container {
        a, a:visited {
            color: var(--cyan);
        }

        .entry {
            border-color: var(--dark-outline);
        }

    }
}
</style>