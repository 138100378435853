<template>
<div class="flex-fill flex column ofy">
    <div class="c3 tabs" v-if="tabs.length > 1" ref="tabs">
        <div v-for="(tab, index) in tabs"
        :key="index"
        :class="'pointer tab unselectable ' + (tabIndex==index ? 'tab-selected' : '')"
        @click="ChangeTab(index)">
            <div>{{tab}}<span v-if="tabCount[tab]" :class="`tab-count ${tabCountNotification ? 'notification' : ''}`">{{tabCount[tab]}}</span></div>
        </div>
    </div>
    <div class="flex-fill fill ofy" style="overflow-x: hidden">
        <div class="fill ofy" style="overflow-x: hidden" ref="container">
            <div class="fill swiper-wrapper tab-height">
                <div :class="`swiper-slide tab-height ofy ${tabs.length > 1 && mobile ? '' : 'swiper-no-swiping'}`" ref="tab"
                v-for="(tab, index) in tabs"
                :key="index">
                    <div class="fill ofy">
                        <slot :name="keyByTab ? tab : `tab${index}`"></slot>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="show-mobile" style="position: absolute; right: 10px; top: 10px; color: white"
    v-for="(tab, index) in tabs"
    :key="index">
        <slot v-if="tabIndex==index" :name="keyByTab ? `options ${tab}` : `options tab${index}`"></slot>
    </div>
    <slot></slot>
</div>
</template>

<script>
import { setTimeout, setInterval, clearInterval } from 'timers';
export default {
    props: {
        tabs: {
            type: Array,
            default: () => []
        },
        headerClass: {
            type: String,
            default: ''
        },
        direction: {
            type: String,
            default: 'column'
        },
        keyByTab: {
            type: Boolean,
            default: false
        },
        defaultTab: {
            type: Number,
            default: 0
        },
        icons: {
            type: Array,
            default: null
        },
        checkScroll: {
            type: Boolean,
            default: false
        },
        alwaysVisible: {
            type: Boolean,
            default: false
        },
        tabCount: {
            type: Object,
            default: () => {return {}}
        },
        tabCountNotification: {
            type: Boolean,
            default: false
        },
    },
    data() {
        return {
            tabIndex: 0,
            swiper: null,
            timer: null,
            lastScroll: {},
        }
    },
    mounted() {
        this.tabIndex = this.defaultTab;
        this.$emit('input', this.keyByTab ? this.tabs[this.tabIndex] : this.tabIndex);

        window.addEventListener('resize', this.OnWindowResized);
        setTimeout(() => {
            this.OnWindowResized();
        }, 500);

        var Swiper = require('swiper');
        this.swiper = new Swiper(this.$refs.container, {
            noSwiping: true,
            noSwipingClass: 'swiper-no-swiping',
            spaceBetween: 100,
            onSlideChangeEnd: () => {
                this.ChangeTab(this.swiper.activeIndex);
            }
            /*
            on: {
                slideChangeEnd: () => {
                    this.ChangeTab(this.swiper.activeIndex);
                }
            }
            */
        });
        
        if(this.checkScroll)
        {
            this.timer = setInterval(() => {
                if(this.$refs.tab.length >= this.tabIndex && this.$refs.tab[this.tabIndex]) 
                {
                    var tab = this.$refs.tab[this.tabIndex];
                    var scrollValue = tab.scrollTop + tab.offsetHeight;
                    var lastScroll = this.lastScroll[this.tabIndex] ? this.lastScroll[this.tabIndex] : 0;
                    if(scrollValue != lastScroll && scrollValue >= tab.scrollHeight)
                    {
                        this.lastScroll[this.tabIndex] = scrollValue;
                        this.$emit('OnBottom', this.keyByTab ? this.tabs[this.tabIndex] : this.tabIndex);
                    }
                }
            }, 250);
        }

    },
    methods: {
        ChangeTab(index) {
            if(index === this.tabIndex) return;
            this.tabIndex = index;
            this.$emit('OnTabChanged', this.keyByTab ? this.tabs[this.tabIndex] : this.tabIndex);
            this.$emit(`OnTab${this.keyByTab ? this.tabs[this.tabIndex].replace(/ /g, '').normalize('NFD').replace(/[\u0300-\u036f]/g, "") : this.tabIndex}`);
            this.$emit('input', this.keyByTab ? this.tabs[this.tabIndex] : this.tabIndex);
            this.swiper.swipeTo(index);
        },
        OnWindowResized() 
        {
            /*
            var height = this.$refs.container.parentNode.getBoundingClientRect();
            if(height)
                this.$refs.container.style.height = height.height + 'px';
            */
            var height = document.defaultView.getComputedStyle(this.$refs.container.parentNode).height;
            this.$refs.container.style.height = height;
        }
    },
    watch: {
        tabIndex: {
            handler: function(n, o) {
                var tab = this.$refs.tabs.childNodes[n];
                scrollTo(tab, {elementToScroll: this.$refs.tabs, horizontal: true, offset: -100}).then();
                //this.$refs.tabs.scrollLeft = tab.offsetLeft;
            }
        }
    },
    beforeDestroy() {
        if(this.timer)
        {
            clearInterval(this.timer);
        }
        window.removeEventListener('resize', this.OnWindowResized);
    }
}
</script>

<style lang="scss">
.tabs {
    border-bottom: 1px solid var(--gray-2);
    display: flex;
    font-weight: 500;
    overflow-x: auto;
    flex-shrink: 0;
    z-index: 1;
}
.tab {
    flex-shrink: 0;
    padding: 10px 20px;
    text-align: center;
    color: var(--gray-1);

    &:not(.tab-selected):hover {
        color: var(--cyan);
    }

}
.tab-selected {
    border-radius: 5px;
    color: var(--purple);
}
.tab-height {
    height: 100% !important;
}
.tab-count {
    background-color: #666;
    color: var(--white);
    min-width: 19px;
    padding: 0px 5px;
    display: inline-block;
    border-radius: 20px;
    margin-left: 10px;
}
.tab-count.notification {
    background-color: rgba(236, 85, 85, 1);
}

.dark {
    .tabs {
        border-bottom: none;
    }
    .tab {
        color: var(--dark-fc-2);

        &:not(.tab-selected):hover {
            color: var(--dark-purple);
        }

    }
    .tab-selected {
        color: var(--cyan);
    }
}
</style>
