<template>
<div class="flex-fill fill flex column ofy">

<div class="opt r">
    <i title="Opciones" class="options icon-dots-vertical ml5" v-show="tab == 'Pendientes'" @click="Options([
        { text: 'Exportar', function: () => ExportPendingPayments() },
    ])"></i>
    <input-date :icon="mobile ? 'calendar' : null" 
        slot="options"
        v-show="tab == 'Historial'"
        inputClass="secondary" 
        type="DATE"
        format="timestamp"
        v-model="dateFilter"
        modalOnly
        :toEndDay="true"
        @OnAcceptDate="Bottom()">
    </input-date>
</div>
<tabs v-if="tabs" :tabs="tabs" :keyByTab="true" v-model="tab" 
    @OnTabHistorial="!historial.length ? Bottom() : null">

    <div slot="Pendientes" class="pa-body">
        <div class="mb20" v-if="canPayTransactions">
            <div class="f18 mb20">Transacciones</div>
            <div class="secondary-text ta-center mt10" v-if="!pendingPayments.length">No hay pagos pendientes</div>
            
            <div :class="`section mb10 selectable`"
            v-for="(pendingPayment, index) in pendingPayments"
            :key="index"
            @click="ViewTransactions(pendingPayment)">
                <div class="flex fill align-center">
                    <div class="flex column flex-fill">
                        <div class="bold">{{pendingPayment.data}}</div>
                        <div class="secondary-text"><span class="bold">Transacciones: </span>{{Util.Number(pendingPayment.transactions.length)}}</div>
                        <div class="secondary-text"><span class="bold">Rango: </span>{{Util.Date(pendingPayment.first_transaction, mobile?'L':'LL')}} - {{Util.Date(pendingPayment.last_transaction, mobile?'L':'LL')}}</div>
                    </div>
                    <div class="bold">{{Util.Price(pendingPayment.total_money)}}</div> 
                    <div class="options icon-dots-vertical ml10" @click.stop="Options([
                        {text: 'Imprimir', function: () => {Print(pendingPayment)}, if: !!Local.modules.module_service},
                        {text: 'Imprimir Todas', function: () => {PrintAll(pendingPayment)}, confirm: `Se imprimiran ${pendingPayment.transactions.length} voucher. ¿Desea continuar?`, if: !!Local.modules.print_customer},
                        {text: 'Liquidar Pago Pendiente', class: 'selected-color', function: () => modalPayPendingPayment=pendingPayment},
                    ])"></div>
                </div>
            </div>
            
        </div>

        <div class="" v-if="canPayInvoices">
            <div class="f18 mb20">Facturas de Proveedor</div>
            <div class="secondary-text ta-center mt10" v-if="!pendingInvoices.length">No hay facturas pendientes</div>
            <div :class="`section selectable mb10`"
            v-for="invoice in pendingInvoices"
            :key="invoice.id"
            @click="$refs.invoiceDetails.Open(invoice)">
                <div class="flex fill align-center">
                    <div class="flex column flex-fill">
                        <div class="bold">Factura N°{{invoice.number}}</div>
                        <div class="secondary-text"><span class="bold">Proveedor: </span>{{invoice.custom_data.provider.name}} - {{Util.Rut(invoice.custom_data.provider.rut)}}</div>
                        <div class="secondary-text"><span class="bold">Ingresado: </span>{{Util.Date(invoice.created_at)}}</div>
                    </div>
                    <div class="bold">{{Util.Price(invoice.total)}}</div>
                </div>
            </div>
        </div>

    </div>

    <vertical-content ref="verticalHistory" slot="Historial" class="pa-body" @OnBottom="Bottom(true)">
        <div class="secondary-text ta-center mt10" v-if="!historial.length">No hay datos.</div>
        <div class="" v-else>
            <div class="section mb10 selectable"
            v-for="(receipt, index) in historial"
            :key="index"
            @click="HistoryClick(receipt)">
                <div class="flex fill align-center">
                    <div class="flex column flex-fill">
                        <div class="bold">{{receipt.method}}</div>
                        <div class="secondary-text"><span class="bold">Fecha: </span>{{Util.Date(receipt.date)}}</div>
                        <div class="secondary-text" v-if="receipt.type=='TRANSACTIONS'"><span class="bold">Transacciones: </span>{{Util.Number(receipt.transactions.length)}}</div>
                        <div class="secondary-text"><span class="bold">Método de Pago: </span>{{Util.PayMethod(receipt.pay_method)}}</div>
                    </div>
                    <div class="bold">{{Util.Price(receipt.total_money)}}</div>
                    <div v-if="receipt.type == 'TRANSACTIONS'" class="options icon-dots-vertical ml10" @click.stop="Options([
                        {text: 'Imprimir', function: () => {Print(receipt)}, if: !!Local.modules.module_service},
                        {text: 'Imprimir Todas', function: () => {PrintAll(receipt)}, confirm: `Se imprimiran ${receipt.transactions.length} voucher. ¿Desea continuar?`, if: !!Local.modules.module_service},
                        {text: 'Ver Transacciones', function: () => {TransactionsList(receipt.transactions)}},
                    ])"></div>
                </div>
            </div>
        </div>
    </vertical-content>

</tabs>

<modal v-model="modalPayPendingPayment" dir="center" :modalClass="'sm'" :title="modalPayPendingPayment ? modalPayPendingPayment.data : ''">
    <div v-if="modalPayPendingPayment">
        <div class="section">
            <div class="mb15">
                <div class="bold">Transacciones</div>
                <div>{{Util.Number(modalPayPendingPayment.transactions.length)}}</div>
            </div>
            <div class="mb15">
                <div class="bold">Total a Pagar</div>
                <div>{{Util.Price(modalPayPendingPayment.total_money)}}</div>
            </div>

            <input-select
            label="Método de pago"
            v-model="modalPayPendingPayment.method"
            :items="possiblePayMethods"
            canType="search">
            </input-select>
        </div>

    </div>
    
    <button slot="actions" class="primary purple" @click="PayPendingPayment()">Liquidar Pago Pendiente</button>
    
</modal>



<transactions-list ref="transactionsList"></transactions-list>
<invoice-details ref="invoiceDetails" @OnInvoicePaid="UpdatePendingInvoices" @OnInvoiceDeleted="UpdatePendingInvoices"></invoice-details>
<pending-payment-details ref="pendingPaymentDetails"></pending-payment-details>
</div>
</template>

<script>
export default {
    data() {
        return {
            pendingPayments: [],
            modalPayPendingPayment: null,
            dateFilter: null,
            historial: [],
            historialDate: null,
            acceptsCash: true,
            payMethods: [],
            tabs: null,
            tab: null,
            pendingInvoices: [],
            searchFactura: null,
        }
    },
    mounted() {

        this.tabs = [];
        this.tabs.push('Pendientes');
        if(this.isAdmin) this.tabs.push('Historial');

        this.$nextTick(() => {
            this.Reset();
        });
        this.$root.$on('PendingPaymentPaid', this.Reset);
    },
    methods: {
        Reset() {
            this.$root.$emit('Loading');
            axios.get(`/payments/pending/${this.Local.id}?v2=true`).then(res => {
                this.$root.$emit('Response', res);
                this.pendingPayments = res.data.pending;
                this.pendingInvoices = res.data.invoices;
                this.payMethods = res.data.methods;
                this.acceptCash = res.data.accept_cash;
                var moment = require('moment');
                this.historialDate = moment().unix();
            }).catch(err => {
                this.$root.$emit('Response', err);
            });
            this.historial = [];
            this.modalPayPendingPayment = null;
            this.$refs.transactionsList.Close();
        },
        ExportPendingPayments(){
            this.Loading();
            axios.get(`/payments/pending/${this.Local.id}/export_pending`, {responseType : 'blob'}).then(res => {
                this.Response(res);
                this.Util.DownloadFile(res, `pagos_pendientes_${Util.Today().date.replace(/\//g, '-')}.xls`);
            }).catch(err => {
                this.Response(err);
            });   
        },
        ViewTransactions(pendingPayment)
        {
            
            this.Loading();
            axios.get(`/payments/pending/${this.Local.id}/${pendingPayment.data}`).then(res => {
                this.Response(res);
                this.$refs.transactionsList.Open(res.data, `Transacciones ${pendingPayment.data}`)
            }).catch(err => {
                this.Response(err);
            });
        },
        PayPendingPayment() {
            this.$root.$emit('Loading');
            var data = {
                pending_payment: this.modalPayPendingPayment.data,
                method: this.modalPayPendingPayment.method
            };
            axios.put(`/payments/pending/${this.Local.id}`, data).then(res => {
                this.$root.$emit('Response', {info: `${this.modalPayPendingPayment.data} pagado exitósamente.`});
                this.pendingPayments = this.pendingPayments.filter((v,i,a) => {
                    return v.data !== this.modalPayPendingPayment.data;
                });
                this.modalPayPendingPayment = null;
                this.Print(res.data);
                this.Reset();
            }).catch(err => {
                this.$root.$emit('Response', err);
            });
        },
        Bottom(bottom=false) {
            this.$root.$emit('Loading');
            this.$refs.verticalHistory.Reset()
            if(!bottom) this.historial = []
            this.$nextTick(() => {
                axios.get(`/payments/pending/${this.Local.id}/paid/${!bottom ? this.dateFilter : this.historialDate}`).then(res => {
                    this.historial = this.historial.concat(res.data);
                    this.historialDate = res.data[res.data.length-1].date;
                    this.$root.$emit('Response', res);
                }).catch(err => {
                    this.$root.$emit('Response', err);
                });
            })
        },
        TransactionsList(list)
        {
            var ids = [];
            for(var i = 0; i < list.length; i++)
                ids.push(list[i].id)
            this.$refs.transactionsList.Open(ids);
        },
        Print(data) 
        {

            var first = Math.min(data.transactions[0].created_at, data.transactions[data.transactions.length-1].created_at);
            var last = Math.max(data.transactions[0].created_at, data.transactions[data.transactions.length-1].created_at);

            var moment = require('moment');
            var arr = [
                {text: this.Local.name, align: 'CT', width: 2, height: 2},
                {text: 'Item: ' + (data.data ? data.data : data.method)},
                {text: 'Fecha: ' + moment().format('HH:mm DD/MM/YYYY')},
                {text: 'Estado: ' + (data.date ? `PAGADO EL ${this.Util.Date(data.date, 'HH:mm DD/MM/YYYY')}` : 'PENDIENTE')},
                {line: true},
                {text: 'Transacciones: ' + data.transactions.length},
                {text: 'Primera: ' + moment.unix(first).format('HH:mm DD/MM/YYYY')},
                {text: 'Ultima: ' + moment.unix(last).format('HH:mm DD/MM/YYYY')},
                {line: true},
            ];

            for(var i = 0; i < data.transactions.length; i++)
                arr.push({text: [`#${this.Util.Zeropad(data.transactions[i].id_ticket, 10)}`, this.Util.Number(data.transactions[i].total_money)]});

            arr.push({line: true});
            arr.push({text: ['Total', this.Util.Number(data.total_money)]});

            this.Util.NotifyService('print', {data: arr, id: Math.random().toString()});

        },
        PrintAll(data)
        {
            var ids = [];
            for(var i = 0; i < data.transactions.length; i++)
                ids.push(data.transactions[i].id);

            this.Loading();
            axios.post(`/transactions/${this.Local.id}`, {ids: ids}).then(res => {
                this.Response(res);
                for(var i = 0; i < res.data.length; i++)
                {
                    this.Util.PrintTransaction(res.data[i], 'DEFAULT');
                }
            }).catch(err => {
                this.Response(err);
            });
        },
        HistoryClick(receipt)
        {
            if(receipt.type == 'INVENTORY_INVOICE') return this.$refs.invoiceDetails.Open(receipt.custom_data.id_invoice);
            if(receipt.type == 'TRANSACTIONS') return this.$refs.pendingPaymentDetails.Open(receipt);
        },
        UpdatePendingInvoices(invoice)
        {
            for(var i = 0; i < this.pendingInvoices.length; i++)
            {
                if(this.pendingInvoices[i].id == invoice.id)
                {
                    this.pendingInvoices.splice(i, 1);
                    return;
                }
            }
        },
    },
    computed: {
        possiblePayMethods() {
            let ret = [];
            for(var i = 0; i < this.payMethods.length; i++)
            {
                ret.push({text: this.Util.PayMethod(this.payMethods[i]), value: this.payMethods[i]});
            }
            return ret;
        },
        canPayTransactions() {
            return !!(this.isAdmin || this.Auth.restrictions.pay_pending_payments);
        },
        canPayInvoices() {
			return !!(this.isAdmin || this.Auth.restrictions.pay_pending_inventory_invoices);
		},
    },
    beforeDestroy() {
        this.$root.$off('PendingPaymentPaid', this.Reset);
    }
}
</script>

<style>

</style>
