<template>
<div>
    <i v-if="icon" @click="modalDate=true" :class="`options icon-${icon}`"></i>
    <div class="flex column">
        <div v-if="label" class="bold mb10">{{label}}</div>
        <div v-if="description" class="secondary-text mb10">{{description}}</div>
    </div>
    <div :class="`input-group input-date ${type}`">
        
        <div :class="`input-field after date ${inputClass}`" v-if="type == 'DATETIME' || type == 'DATE'">
            
            <input-facade v-if="!icon && !modalOnly" v-model="inputDate" mask="##/##/####" placeholder="DD/MM/YYYY" @blur="CheckDateInput" :masked="true"/>
            <div @click="modalDate=true" class="input" ref="inputDate" v-if="modalOnly && !icon" style="cursor: pointer;">{{inputDate}}</div>
            <!--<input type="text" v-model="currentDate">-->
            <div class="after" v-if="!icon">
               
                <i class="icon icon-calendar selectable" ref="iconCalendar" @click="modalDate=true"></i>

            </div>

            <modal v-model="modalDate" :dir="['up', 'center']" :modalClass="['up w100', '']" :domElement="modalOnly ? $refs.inputDate : null" :hideBackdrop="desktop && modalOnly" :hideActions="modalOnly">
                <calendar v-model="currentDate" @OnClickDate="modalOnly ? ClickAcceptDate() : null"></calendar>
                <button slot="actions" class="primary purple" @click="ClickAcceptDate()">Aceptar</button>
            </modal>

        </div>

        <div :class="`input-field after time ${inputClass}`" v-if="type == 'DATETIME' || type == 'TIME'">
            
                <input-facade v-model="inputTime" mask="##:##" placeholder="HH:MM" @blur="CheckTimeInput" :masked="true" ref="inputTime"/>
                <!--<input type="text" v-model="currentDate">-->
                
                <div class="after">
                    <i @click="modalTime=true" class="icon icon-clock selectable"></i>    
                </div>

                <modal v-model="modalTime"  :dir="['up', 'center']" :modalClass="['up w100', '']" :domElement="$refs.inputTime ? $refs.inputTime.$el : null" :hideBackdrop="desktop" :hideActions="desktop">
                    <div class="time-picker flex column ofy">
                        <div class="flex ofy flex-fill">
                            <div class="fill ofy flex column hours scroll-menu" style="overflow-x: hidden" ref="containerHour">
                                <button ref="itemHour"
                                v-for="i in 24" 
                                :key="i" 
                                :class="`cell ${currentHour == i-1 ? 'selected' : ''}`"
                                @click="SetTime(i-1)">{{Util.Zeropad(i-1, 2)}}</button>
                            </div>
                            <div class="fill ofy flex column minutes scroll-menu" style="overflow-x: hidden" ref="containerMinute">
                                <button ref="itemMinute"
                                v-for="i in minutes" 
                                :key="i" 
                                :class="`cell ${currentMinute == i ? 'selected' : ''}`"
                                @click="SetTime(null, i)">{{Util.Zeropad(i, 2)}}</button>
                            </div>
                        </div>
                    </div>
                    <button slot="actions" class="primary purple" @click="modalTime=false">Aceptar</button>
                </modal>

        </div>

        <slot name="after"></slot>

    </div>

</div>
</template>

<script>
var moment = require('moment');
export default {
    props: {
        value: {
            type: [String, Number, Object],
            default: null
        },
        label: {
            type: String,
            default: null
        },
        description: {
            type: String,
            default: null
        },
        type: {
            type: String,
            default: 'DATETIME'    
        },
        format: {
            type: String,
            default: null //null = moment object, timestamp, minutes, moment format string
        },
        minutesStep: {
            type: Number,
            default: 1
        },
        minDate: {
            type: String,
            default: null
        },
        inputClass: {
            type: [String],
            default: ''    
        },
        icon: {
            type: String,
            default: null
        },
        modalOnly: {
            type: Boolean,
            default: false,
        },
        toEndDay : {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            currentValue: null,
            currentValueMoment: null,
            currentDate: null,
            currentTime: null,
            inputDate: null,
            inputTime: null,

            modalDate: null,
            modalTime: null,
            modal: false,
            data: null,
            values: {},
            
            minimunDate: null
        }
    },
    mounted() {
        /*
        if(this.minDate)
        {
            if(this.minDate == 'today')
            {
                this.minimunDate = new Date();
            }
        }

        this.values = {};
        
        this.UpdateValues();
        this.currentValue = this.values.string;
        
        if(!this.value)
            this.$emit('input', this.values.value);
        */
       this.ParseInput(this.value);
    },
    methods: {
        ParseInput(val) {
            
            var date = null;
            if(val)
            {
                if(this.format === null)
                    endValue = this.currentValueMoment;
                else if(this.format == 'timestamp')
                    date = moment.unix(val);
                else if(this.format == 'minutes')
                {
                    //TODO   
                }
                else
                {
                    date = moment(val, this.format);
                }
            }
            if(!date || !date.isValid || !date.isValid()) date = moment();
            this.currentValueMoment = date;
            this.currentValue = this.currentValueMoment.format('DD/MM/YYYY HH:mm');
            this.currentDate = this.currentValueMoment.format('DD/MM/YYYY');
            this.currentTime = this.currentValueMoment.format('HH:mm');
        },
        CheckDateInput()
        {
            var date = moment(this.inputDate, 'DD/MM/YYYY');
            if(date.isValid()) this.currentDate = date.format('DD/MM/YYYY');
            else this.inputDate = this.currentDate;
            this.currentValue = `${this.currentDate} ${this.currentTime}`;
            this.currentValueMoment = moment(this.currentValue, 'DD/MM/YYYY HH:mm');
        },
        CheckTimeInput()
        {
            var time = moment(this.inputTime, 'HH:mm');
            if(time.isValid()) this.currentTime = time.format('HH:mm');
            else this.inputTime = this.currentTime;
            this.currentValue = `${this.currentDate} ${this.currentTime}`;
            this.currentValueMoment = moment(this.currentValue, 'DD/MM/YYYY HH:mm');
        },
        Open(){
            this.modal = true;
        },
        SetTime(h, m) {
            if(isNaN(parseInt(h))) h = this.currentHour === null ? 0 : this.currentHour;
            if(isNaN(parseInt(m))) m = this.currentMinute === null ? 0 : this.currentMinute;
            this.currentTime = `${this.Util.Zeropad(h, 2)}:${this.Util.Zeropad(m, 2)}`;
        },
        SetMinutes(m) {
            this.data.minute(m);
            this.UpdateValues();
        },
        SetDate(date) {
            this.data = moment(date);
            this.data.hour(this.values.hour);
            this.data.minute(this.values.minute);
            this.UpdateValues();
        },
        UpdateValues() {
            var formats = {
                DATETIME: 'DD/MM/YYYY HH:mm',
                DATE: 'DD/MM/YYYY',
                TIME: 'HH:mm',
            };

            //Valor esperado
            var endValue = null;
            if(this.format === null)
                endValue = this.data;
            else if(this.format == 'timestamp')
                endValue = this.data.unix();
            else if(this.format == 'minutes')
                endValue = this.data.hour()*60 + this.data.minute();
            else
                endValue = this.data.format(this.format);

            this.values = {
                hour: this.data.hour(),
                minute: this.data.minute(),
                day: this.data.day(),
                month: this.data.month(),
                year: this.data.year(),
                date: this.data.toDate(),
                string: this.data.format(formats[this.type]),
                value: endValue
            }
            
        },
        Save() {
            this.currentValue = this.values.string;
            this.modal = false;
            this.$emit('input', this.values.value);
            this.$emit('OnValueChanged', this.values.value);
        },
        OnOpen() {
            setTimeout(() => {
                if(this.$refs.containerHour)
                    scrollTo(this.$refs.itemHour[this.currentHour], {elementToScroll: this.$refs.containerHour, minDuration: 1, maxDuration: 1}).then();
                if(this.$refs.containerMinute && this.$refs.itemMinute[this.currentMinute])
                    scrollTo(this.$refs.itemMinute[this.currentMinute], {elementToScroll: this.$refs.containerMinute, minDuration: 1, maxDuration: 1}).then();
            }, 10);
        },
        ClickAcceptDate() {
            this.modalDate = null;
            this.$emit('OnAcceptDate', this.values.value);
        }
    },
    computed: {
        minutes() {
            var ret = [];
            var current = 0;
            while(current < 60)
            {
                ret.push(current);
                current += this.minutesStep;
            }
            return ret;
        },
        currentHour() {
            if(!this.currentTime || typeof this.currentTime !== 'string' || this.currentTime.split(':').length !== 2) return null;
            return parseInt(this.currentTime.split(':')[0]);
        },
        currentMinute() {
            if(!this.currentTime || typeof this.currentTime !== 'string' || this.currentTime.split(':').length !== 2) return null;
            return parseInt(this.currentTime.split(':')[1]);
        },
    },
    watch: {
        modal: function(n, o) {
            if(n) this.OnOpen();
        },
        value: function(n, o) 
        {
            this.ParseInput(n);
            /*
            if(n)
            {
                if(typeof n == 'object' || typeof n == 'array')
                    this.data = n.clone();
                else if(typeof n == 'number')
                    this.data = moment.unix(n);
                else
                    this.data = moment(n, this.format);
            }
            else
            {
                this.data = moment();
            }
            this.UpdateValues();
            this.currentValue = this.values.string;
            */
        },
        currentValue: function(n, o) 
        {
            var endValue = null;
            if(this.format === null){
                endValue = this.currentValueMoment;
            } else if(this.format == 'timestamp'){
                if(this.toEndDay){
                    this.currentValueMoment.set({ hour: 23, minute: 59, second: 59, millisecond: 0 })
                }
                endValue = this.currentValueMoment.unix();
            } else if(this.format == 'minutes'){
                endValue = this.currentValueMoment.hour()*60 + this.currentValueMoment.minute();
            }else{
                endValue = this.currentValueMoment.format(this.format);
            }

            this.$emit('input', endValue);
            this.$emit('OnValueChanged', endValue);
            this.SetDate(endValue);
        },
        currentDate: function(n, o)
        {
            this.inputDate = n;
            this.currentValue = `${this.currentDate} ${this.currentTime}`;
            this.currentValueMoment = moment(this.currentValue, 'DD/MM/YYYY HH:mm');
        },
        currentTime: function(n, o)
        {
            this.inputTime = n;
            this.currentValue = `${this.currentDate} ${this.currentTime}`;
            this.currentValueMoment = moment(this.currentValue, 'DD/MM/YYYY HH:mm');
        },
        modalDate: function(n, o)
        {
            setTimeout(() => {
                this.$emit('OnModalDateChanged', !!n);
            }, 100);
        },
        modalTime: function(n, o)
        {
            setTimeout(() => {
                this.$emit('OnModalTimeChanged', !!n);
            }, 100);
        }
    }
}
</script>

<style lang="scss">
.input-date {
    display: flex;

    .input-field {
        width: 100%;
        border-radius: 10px;
    }

    &.DATETIME 
    {    
        .input-field:nth-child(2) {
            input 
            {
                border-left: none;
            }
        }
    }

    .date.sm {
        width: 130px;
    }
    .time.sm {
        width: 90px;
    }

}

.time-picker {

    padding: 24px; 
    height: 300px;

    .minutes, .hours {
        padding: 8px;
        scroll-snap-type: y proximity;
    }

    .cell {
        display: flex;
        background-color: transparent;
        height: 40px;
        width: 40px;
        justify-content: center;
        align-items: center;
        color: var(--black);
        border-radius: 50%;
        font-weight: normal;
        flex-shrink: 0;
        scroll-snap-align: start;

        &:hover {
            background-color: var(--gray-3);
        }

        &.selected {
            background-color: var(--gray-cyan);
            color: var(--blue);
            font-weight: 600;
        }

    }
}

.time-option {
    text-align: center;
    padding: 10px;
    cursor: pointer;
    border-radius: 5px;
    color: #CCC;
}
.time-option.selected {
    font-weight: bold;
    color: rgb(0, 181, 255);
}
.time-option:hover {
    background-color: whitesmoke;
    color: #666;
}
.vuecal {
    padding: 5px;
    box-shadow: none;
    border-bottom: 1px solid #EEE;
}
.vuecal__title-bar {
    background-color: var(--white);
    font-size: 12px;
    font-weight: bold;
    color: black;
}
.vuecal__arrow {
    color: #CCC;
    padding: 10px;
}
.vuecal__heading {
    color: #CCC;
    font-size: 11px;
    font-weight: 500;
}
.vuecal__cell::before {
    border: none;
}
.vuecal__cell {
    font-size: 12px;
    font-weight: 600;
}
.vuecal__cell.out-of-scope {
    opacity: 0.5;
}
.vuecal__cell.selected {
    background-color: rgb(0, 181, 255);
    color: var(--white);
    border-radius: 3px;
}
.vuecal__weekdays-headings{
    border: none;
}
.vuecal__cell-date {
    cursor: pointer;
}

.dark {
    .cell {
        background-color: transparent;
        color: var(--dark-fc-1);
        &:hover {
            background-color: var(--dark-bg-2);
        }
        &.selected {
            background-color: var(--dark-cyan);
            color: var(--cyan);
        }
    }
}

</style>
