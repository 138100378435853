<template>
<div>
    <modal v-model="modal" :title="title" dir="right" :modalClass="['fill', 'md right h100']" hideActions>
        <tabs v-model="tab" class="flex-fill" :tabs="tabs" :keyByTab="true" @OnTabEstadisticas="GetDeliveryStatisticsDetails">
            <vertical-content slot="Transacciones" v-if="transactions && transactions.length > 0" @OnBottom="LoadTransactionsChunk()">
                <div class="section">
                    <div class="entry selectable"
                    v-for="(transaction, index) in transactions"
                    :key="index"
                    @click="$refs.details.Open(transaction)">
                        <div class="flex fill align-center">
                            <div class="flex column">
                                <div class="bold">#{{Util.Zeropad(transaction.id_ticket, 10)}}</div>
                                <div class="secondary-text"><span class="bold">Fecha: </span>{{Util.Date(transaction.created_at, 'DD/MM/YYYY HH:mm')}}</div>
                                <div class="secondary-text"><span class="bold">Método de Pago: </span><span v-for="(payment, index2) in transaction.payment" :key="index2">{{Util.PayMethod(payment.method)}} </span></div>
                                <div class="secondary-text">Para {{Util.SaleType[transaction.type]}} <span v-if="transaction.customer.name">a {{transaction.customer.name}} <i v-if="transaction.id_user" style="color: rgb(0, 151, 255)" class="icon icon-corbatin"></i></span></div>
                            </div>
                            <div class="flex-fill"></div>
                            <div class="flex column ta-right">
                                <div class="bold">{{Util.Price(transaction.total_money)}}</div>
                                <div class="secondary-text flex align-center" v-if="transaction.type == 'DELIVERY'" v-html="DeliveryCost(transaction)"></div>
                            </div>
                            <!--<i class="icon icon-arrow-right-o c4"></i>-->
                        </div>
                    </div>
                </div>
                <div class="secondary-text ta-center mt10" v-if="!transactions.length">No hay transacciones.</div>
            </vertical-content>

            <div slot="Estadísticas" class="pa20">
                <div v-if="statsConfig && statsConfig.label" class="mb20">
                    <div class="flex align-center fill">
                        <div v-if="statsConfig.color" class="percentage-icon cw" :style="{'background-color': statsConfig.color, width: '20px', height: '20px'}"></div>
                        <div class="flex column">
                            <span class="f18">{{statsConfig.label}}</span>
                            <span class="secondary-text"><span class="bold">Transacciones: </span>{{Util.Number(transactionsId.length)}}</span>
                        </div>
                        <div class="flex-fill"></div>
                        <span class="bold" v-if="statsConfig.total">{{Util.Price(statsConfig.total)}}</span>
                    </div>
                </div>
                <div v-if="statsDetails">
                    <div v-if="sales.length > 0 && statsConfig.sale !== false" class="mb20 well">
                        <div class="f18 mb20">Tipo de Venta</div>
                        <chart
                            v-if="Util.ArraySum(sales, 'total_money') != 0"
                            :data="[sales]"
                            dataset="total_money"
                            type="pie"
                            class="mb20"
                            :label="(data) => {return saleType[data.data]}"
                            :singleDataset="false"
                            :maxDatasets="7"
                            :showLegend="false"
                            :xLabelFormat="null"
                            :yLabelFormat="null"
                            height="200px"
                            :tooltip="(value, label, index) => {return `${label} ${Percent(value, Util.ArraySum(sales, 'total_money'))}%`}"
                            :customColor="[null, null]"
                            :keyColors="Util.Chart.orderType">
                        </chart>
                        <div class="scroll-modal-container">
                            <div class="entry" v-for="(sale, index) in sales" :key="index">
                                <div class="flex align-center fill">
                                    <div class="percentage-icon cw" :style="{'background-color': Util.ChartColor(index, sales.length, Util.Chart.orderType, sale.data)}">{{Percent(sale.total_money, Util.ArraySum(sales, 'total_money'))}}%</div>
                                    <div class="flex column">
                                        <span class="bold">{{saleType[sale.data]}}</span>
                                        <span class="secondary-text"><span class="bold">Transacciones: </span>{{Util.Number(sale.count)}}</span>
                                    </div>
                                    <div class="flex-fill"></div>
                                    <span class="bold">{{Util.Price(sale.total_money)}}</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div v-if="payMethods.length > 0 && statsConfig.method !== false" class="mb20 well">
                        <div class="f18 mb20">Métodos de Pago</div>
                        <chart
                            v-if="Util.ArraySum(payMethods, 'total_money') != 0"
                            :data="[payMethods]"
                            dataset="total_money"
                            type="pie"
                            class="mb20"
                            :label="(data) => {return data.data}"
                            :singleDataset="false"
                            :maxDatasets="7"
                            :showLegend="false"
                            :xLabelFormat="null"
                            :yLabelFormat="null"
                            height="200px"
                            :tooltip="(value, label, index) => {return `${Util.PayMethod(label)} ${Percent(value, Util.ArraySum(payMethods, 'total_money'))}%`}">
                        </chart>
                        <div class="scroll-modal-container">
                            <div class="entry" v-for="(payMethod, index) in payMethods" :key="index">
                                <div class="flex align-center fill">
                                    <div class="percentage-icon cw" :style="{'background-color': Util.ChartColor(index, payMethods.length)}">{{Percent(payMethod.total_money, Util.ArraySum(payMethods, 'total_money'))}}%</div>
                                    <div class="flex column">
                                        <span class="bold">{{Util.PayMethod(payMethod.data)}}</span>
                                        <span class="secondary-text"><span class="bold">Transacciones: </span>{{Util.Number(payMethod.count)}}</span>
                                    </div>
                                    <div class="flex-fill"></div>
                                    <span class="bold">{{Util.Price(payMethod.total_money)}}</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div v-if="mediumsSection.length > 0 && statsConfig.source !== false" class="mb20 well">
                        <div class="f18 mb20">Medio de Venta</div>
                        <chart
                            v-if="Util.ArraySum(mediumsSection, 'total_money') != 0"
                            :data="[mediumsSection]"
                            dataset="total_money"
                            type="pie"
                            class="mb20"
                            :label="(data) => {return mediums[data.data]}"
                            :singleDataset="false"
                            :maxDatasets="7"
                            :showLegend="false"
                            :xLabelFormat="null"
                            :yLabelFormat="null"
                            height="200px"
                            :tooltip="(value, label, index) => {return `${label} ${Percent(value, Util.ArraySum(mediumsSection, 'total_money'))}%`}"
                            :customColor="[null, null]"
                            :keyColors="Util.Chart.saleType">
                        </chart>
                        <div class="scroll-modal-container">
                            <div class="entry" v-for="(medium, index) in mediumsSection" :key="index">
                                <div class="flex align-center fill">
                                    <div class="percentage-icon cw" :style="{'background-color': Util.ChartColor(index, mediumsSection.length, Util.Chart.saleType, medium.data)}">{{Percent(medium.total_money, Util.ArraySum(mediumsSection, 'total_money'))}}%</div>
                                    <div class="flex column">
                                        <span class="bold">{{mediums[medium.data]}}</span>
                                        <span class="secondary-text"><span class="bold">Transacciones: </span>{{Util.Number(medium.count)}}</span>
                                    </div>
                                    <div class="flex-fill"></div>
                                    <span class="bold">{{Util.Price(medium.total_money)}}</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div v-if="daysSection.length > 0 && statsConfig.days !== false" class="mb20 well">
                        <div class="f18 mb20">Días</div>
                        <chart
                            v-if="Util.ArraySum(daysSection, 'total_money') != 0"
                            :data="[daysSection]"
                            dataset="total_money"
                            type="pie"
                            class="mb20"
                            :label="(data) => {return days[data.data]}"
                            :singleDataset="false"
                            :showLegend="false"
                            :xLabelFormat="null"
                            :yLabelFormat="null"
                            height="200px"
                            :tooltip="(value, label, index) => {return `${label} ${Percent(value, Util.ArraySum(daysSection, 'total_money'))}%`}"
                            :customColor="[null, null]"
                            :keyColors="Util.Chart.days">
                        </chart>
                        <div class="scroll-modal-container">
                            <div class="entry" v-for="(day, index) in daysSection" :key="index">
                                <div class="flex align-center fill">
                                    <div class="percentage-icon cw" :style="{'background-color': Util.ChartColor(index, daysSection.length, Util.Chart.days, day.data)}">{{Percent(day.total_money, Util.ArraySum(daysSection, 'total_money'))}}%</div>
                                    <div class="flex column">
                                        <span class="bold">{{days[day.data]}}</span>
                                        <span class="secondary-text"><span class="bold">Transacciones: </span>{{Util.Number(day.count)}}</span>
                                    </div>
                                    <div class="flex-fill"></div>
                                    <span class="bold">{{Util.Price(day.total_money)}}</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div v-if="hours.length > 0 && statsConfig.hours !== false" class="mb20 well">
                        <div class="f18 mb20">Horas</div>
                        <chart
                        v-if="Util.ArraySum(hours, 'total_money') != 0"
                        :data="[hours]"
                        dataset="total_money"
                        type="pie"
                        class="mb20"
                        :label="(data) => {return data.data + ' hrs'}"
                        :singleDataset="false"
                        :showLegend="false"
                        :xLabelFormat="null"
                        :yLabelFormat="null"
                        :tooltip="(value, label, index) => {return `${label} ${Percent(value, Util.ArraySum(hours, 'total_money'))}%`}"
                        height="200px"
                        :customColor="[null, null]"
                        :keyColors="Util.Chart.hours">
                        </chart>

                        <div class="scroll-modal-container">
                            <div class="entry" v-for="(hour, index) in hours" :key="index">
                                <div class="flex align-center fill">
                                    <div class="percentage-icon cw" :style="{'background-color': Util.ChartColor(index, hours.length, Util.Chart.hours, hour.data)}">{{Percent(hour.total_money, Util.ArraySum(hours, 'total_money'))}}%</div>
                                    <div class="flex column">
                                        <span class="bold">{{hour.data}} hrs</span>
                                        <span class="secondary-text"><span class="bold">Transacciones: </span>{{Util.Number(hour.count)}}</span>
                                    </div>
                                    <div class="flex-fill"></div>
                                    <span class="bold">{{Util.Price(hour.total_money)}}</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div v-if="dynamicContextModal && dynamicContextModal['sections'] && statsConfig.sections !== false" class="mb20 well">
                        <div class="f18 mb20">
                            <i v-tooltip="'Atrás'" 
                            v-if="dynamicIndex && dynamicIndex['ind_sections'] > scale.indexOf('sections')" 
                            class="selectable icon-arrow-left mr5" 
                            @click="OpenDynamicContextModal(null, -1, 'sections')"></i>  
                            {{ dynamicContextModal['sections'].title }}
                        </div>
                        <div v-if="!dynamicContextModal['sections'].elements.length" class="f18 secondary-text ta-center mb20">No hay Datos</div>
                        <div v-else>
                            <chart
                            v-if="dynamicContextModal['sections'].elements.total_money"
                            :data="[dynamicContextModal['sections'].elements]"
                            dataset="total_money"
                            type="pie"
                            class="mb20"
                            :label="(data) => {return data.data}"
                            :maxDatasets="7"
                            :singleDataset="false"
                            :showLegend="false"
                            :xLabelFormat="null"
                            :yLabelFormat="null"
                            :tooltip="(value, label, index) => {return `${label} ${Percent(value, dynamicContextModal['sections'].total)}%`}"
                            height="200px">
                            </chart>

                            <div class="scroll-modal-container">
                                <div :class="`entry ${Local.type == 'LOCAL' && !dynamicContextModal['sections'].stop?'selectable':''}`" 
                                v-for="(element, index) in dynamicContextModal['sections'].elements" 
                                :key="index"
                                @click="Local.type == 'LOCAL' && !dynamicContextModal['sections'].stop? OpenDynamicContextModal(element, 1, 'sections') : null">
                                    <div class="flex align-center fill">
                                        <div class="percentage-icon cw" :style="{'background-color': Util.ChartColor(index, dynamicContextModal['sections'].elements.length, Util.Chart.element, element.data)}">{{Percent(element.total_money, dynamicContextModal['sections'].total) || 0}}%</div>
                                        <div class="flex column">
                                            <span class="bold">{{element.data}}</span>
                                            <span class="secondary-text"><span class="bold">Cantidad: </span>{{Util.Number(element.quantity)}}</span>
                                        </div>
                                        <div class="flex-fill"></div>
                                        <span class="bold">{{Util.Price(element.total_money)}}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div v-if="dynamicContextModal && dynamicContextModal['items'] && statsConfig.items !== false" class="mb20 well">
                        <div class="f18 mb20">
                            <i v-tooltip="'Atrás'" 
                            v-if="dynamicIndex && dynamicIndex['ind_items'] > scale.indexOf('items')" 
                            class="selectable icon-arrow-left mr5" 
                            @click="OpenDynamicContextModal(null, -1, 'items')"></i>  
                            {{ dynamicContextModal['items'].title }}
                        </div>
                        <div v-if="!dynamicContextModal['items'].elements.length" class="f18 secondary-text ta-center mb20">No hay Datos</div>
                        <div v-else>
                            <chart
                            v-if="dynamicContextModal['items'].elements.total_money"
                            :data="[dynamicContextModal['items'].elements]"
                            dataset="total_money"
                            type="pie"
                            class="mb20"
                            :label="(data) => {return data.data}"
                            :maxDatasets="7"
                            :singleDataset="false"
                            :showLegend="false"
                            :xLabelFormat="null"
                            :yLabelFormat="null"
                            :tooltip="(value, label, index) => {return `${label} ${Percent(value, dynamicContextModal['items'].total)}%`}"
                            height="200px">
                            </chart>

                            <div class="scroll-modal-container">
                                <div :class="`entry ${Local.type == 'LOCAL' && !dynamicContextModal['items'].stop?'selectable':''}`" 
                                v-for="(element, index) in dynamicContextModal['items'].elements" 
                                :key="index"
                                @click="Local.type == 'LOCAL' && !dynamicContextModal['items'].stop? OpenDynamicContextModal(element, 1, 'items') : null">
                                    <div class="flex align-center fill">
                                        <div class="percentage-icon cw" :style="{'background-color': Util.ChartColor(index, dynamicContextModal['items'].elements.length, Util.Chart.element, element.data)}">{{Percent(element.total_money, dynamicContextModal['items'].total) || 0}}%</div>
                                        <div class="flex column">
                                            <span class="bold">{{element.data}}</span>
                                            <span class="secondary-text"><span class="bold">Cantidad: </span>{{Util.Number(element.quantity)}}</span>
                                        </div>
                                        <div class="flex-fill"></div>
                                        <span class="bold">{{Util.Price(element.total_money)}}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div v-if="dynamicContextModal && dynamicContextModal['group_modifiers'] && statsConfig.group_modifiers !== false" class="mb20 well">
                        <div class="f18 mb20">
                            <i v-tooltip="'Atrás'" 
                            v-if="dynamicIndex && dynamicIndex['ind_group_modifiers'] > scale.indexOf('group_modifiers')" 
                            class="selectable icon-arrow-left mr5" 
                            @click="OpenDynamicContextModal(null, -1, 'group_modifiers')"></i>  
                            {{ dynamicContextModal['group_modifiers'].title }}
                        </div>

                        <div v-if="!dynamicContextModal['group_modifiers'].elements.length" class="f18 secondary-text ta-center mb20">No hay Datos</div>
                        <div v-else>
                            <chart
                            v-if="dynamicContextModal['group_modifiers'].elements.total_money"
                            :data="[dynamicContextModal['group_modifiers'].elements]"
                            dataset="total_money"
                            type="pie"
                            class="mb20"
                            :label="(data) => {return data.data}"
                            :maxDatasets="7"
                            :singleDataset="false"
                            :showLegend="false"
                            :xLabelFormat="null"
                            :yLabelFormat="null"
                            :tooltip="(value, label, index) => {return `${label} ${Percent(value, dynamicContextModal['group_modifiers'].total)}%`}"
                            height="200px">
                            </chart>

                            <div class="scroll-modal-container">
                                <div :class="`entry ${Local.type == 'LOCAL' && !dynamicContextModal['group_modifiers'].stop?'selectable':''}`" 
                                v-for="(element, index) in dynamicContextModal['group_modifiers'].elements" 
                                :key="index"
                                @click="Local.type == 'LOCAL' && !dynamicContextModal['group_modifiers'].stop? OpenDynamicContextModal(element, 1, 'group_modifiers') : null">
                                    <div class="flex align-center fill">
                                        <div class="percentage-icon cw" :style="{'background-color': Util.ChartColor(index, dynamicContextModal['group_modifiers'].elements.length, Util.Chart.element, element.data)}">{{Percent(element.total_money, dynamicContextModal['group_modifiers'].total) || 0}}%</div>
                                        <div class="flex column">
                                            <span class="bold">{{element.data}}</span>
                                            <span class="secondary-text"><span class="bold">Cantidad: </span>{{Util.Number(element.quantity)}}</span>
                                        </div>
                                        <div class="flex-fill"></div>
                                        <span class="bold">{{Util.Price(element.total_money)}}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div v-if="dynamicContextModal && dynamicContextModal['modifiers'] && statsConfig.modifiers !== false" class="mb20 well">
                        <div class="f18 mb20">
                            <i v-tooltip="'Atrás'" 
                            v-if="dynamicIndex && dynamicIndex['ind_modifiers'] > scale.indexOf('modifiers')" 
                            class="selectable icon-arrow-left mr5" 
                            @click="OpenDynamicContextModal(null, -1, 'modifiers')"></i>  
                            {{ dynamicContextModal['modifiers'].title }}
                        </div>

                        <div v-if="!dynamicContextModal['modifiers'].elements.length" class="f18 secondary-text ta-center mb20">No hay Datos</div>
                        <div v-else>
                            <chart
                            v-if="dynamicContextModal['modifiers'].elements.total_money"
                            :data="[dynamicContextModal['modifiers'].elements]"
                            dataset="total_money"
                            type="pie"
                            class="mb20"
                            :label="(data) => {return data.data}"
                            :maxDatasets="7"
                            :singleDataset="false"
                            :showLegend="false"
                            :xLabelFormat="null"
                            :yLabelFormat="null"
                            :tooltip="(value, label, index) => {return `${label} ${Percent(value, dynamicContextModal['modifiers'].total)}%`}"
                            height="200px">
                            </chart>

                            <div class="scroll-modal-container">
                                <div :class="`entry ${Local.type == 'LOCAL' && !dynamicContextModal['modifiers'].stop?'selectable':''}`" 
                                v-for="(element, index) in dynamicContextModal['modifiers'].elements" 
                                :key="index"
                                @click="Local.type == 'LOCAL' && !dynamicContextModal['modifiers'].stop? OpenDynamicContextModal(element, 1, 'modifiers') : null">
                                    <div class="flex align-center fill">
                                        <div class="percentage-icon cw" :style="{'background-color': Util.ChartColor(index, dynamicContextModal['modifiers'].elements.length, Util.Chart.element, element.data)}">{{Percent(element.total_money, dynamicContextModal['modifiers'].total) || 0}}%</div>
                                        <div class="flex column">
                                            <span class="bold">{{element.data}}</span>
                                            <span class="secondary-text"><span class="bold">Cantidad: </span>{{Util.Number(element.quantity)}}</span>
                                        </div>
                                        <div class="flex-fill"></div>
                                        <span class="bold">{{Util.Price(element.total_money)}}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </tabs>
    </modal>

    <transaction-details ref="details" 
    @OnTransactionDeleted="TransactionDeleted($event)" 
    @OnTransactionEdit="TransactionEdit($event)">
    </transaction-details>
</div>
</template>

<script>
export default {
    props: {
        chuckSize: {
            type: Number,
            default: 20,
        },
    },
    data() {
        return {
            list: [], //ids arrays
            transactions: [], //transactions data
            title: 'Transacciones',
            modal: false,
            statsDetails: null,
            transactionsId: [],
            tab: null,
            tabs: null,
            saleType: {
                TAKEAWAY: 'Llevar',
                SERVE: 'Servir',
                DELIVERY: 'Delivery'
            },
            mediums: {
                INFLUYE_ADMIN: 'Punto de Venta',
                INFLUYE_APP: 'Influye App',
                INFLUYE_WEB: 'Ecommerce'
            },
            dict: {
                sections: 'Secciones',
                items: 'Items',
                group_modifiers: 'Grupo Modificadores',
                modifiers: 'Modificadores',
            },
            days: {
                0: 'Domingo',
                1: 'Lunes',
                2: 'Martes',
                3: 'Miércoles',
                4: 'Jueves',
                5: 'Viernes',
                6: 'Sábado'
            },
            statsConfig: null,
            dynamicContextModal: {},
            dynamicIndex: {},
            initPrevData: null,
            scale: [],
            fromTitle: null,
        }
    },
    methods: {
        Open(transactionList, newTitle, params, fromTitle) {
            this.tabs = ['Transacciones'];
            this.scale = ['sections', 'items', 'group_modifiers', 'modifiers'];
            this.dynamicContextModal = { sections: null, items: null, group_modifiers: null, modifiers: null };
            this.statsDetails = null;
            this.dynamicIndex = {
                ind_sections: 0,
                ind_items: 0,
                ind_group_modifiers: 0,
                ind_modifiers: 0
            };
            this.initPrevData = null;
            
            if(params && params.stats){
                this.scale.forEach(element => {
                    if (params[element] === false) {
                        this.scale = this.scale.filter(item => item !== element);
                    }

                    this.fromTitle = fromTitle;
                    if(params[`prev_${element}`]) this.initPrevData = {level: element, data: params[`prev_${element}`]};
                });

                Object.keys(this.dynamicContextModal).forEach(element => {
                    if (params[element] === false) {
                        delete this.dynamicContextModal[element];
                    }
                });

                this.tabs.push('Estadísticas');
                this.statsConfig = params;
                if(transactionList.length > 0) this.transactionsId = transactionList;
            }

            this.title = newTitle ? newTitle : 'Transacciones';

            this.transactions = [];
            this.list = [];

            if(transactionList.length > 0)
            {
                if(typeof transactionList[0] == 'number')
                {
                    //Lista de IDs
                    this.list = JSON.parse(JSON.stringify(transactionList));
                    this.LoadTransactionsChunk();
                }
                else
                {
                    //Lista de Transacciones (data)
                    this.transactions = transactionList;
                    //TODO: extract transactions ids?
                    this.modal = true;
                }
            }

            //if(newTabs && newTabs.includes("Estadísticas")) this.GetDeliveryStatisticsDetails();
        },
        Close() {
            this.modal = false;
            this.statsDetails = null;
            this.list = [];
            this.tabs = null;
            this.transactions = [];
            this.$refs.details.Close();
        },
        LoadTransactionsChunk()
        {
            if(this.list.length == 0) return;
            this.$root.$emit('Loading');
            axios.post(`/transactions/${this.Local.id}`, {ids: this.list.splice(0, this.chuckSize)}).then(res => {
                this.transactions =  this.transactions.concat(res.data);
                this.$root.$emit('Response', res);
                this.modal = true;
            }).catch(err => {
                this.$root.$emit('Response', err);
            });
        },
        GetPayments(t)
        {
            if(t.pay_method == 'MIXED')
            {
                var ret = '';
                var methods = t.ticket.payment.methods;
                for(var i = 0; i < methods.length; i++)
                    ret += ', ' + (methods[i].method == 'CASH' ? 'Efectivo' :methods[i].method);
                return ret.substr(2);
            }
            else
            {
                return t.pay_method == 'CASH' ? 'Efectivo' : t.pay_method;
            }
        },
        TransactionDeleted(id) {
            for(var i = 0; i < this.transactions.length; i++)
            {
                if(this.transactions[i].id == id)
                {
                    this.transactions.splice(i, 1);
                    break;
                }
            }
            this.$emit('OnTransactionDeleted', id);
        },
        TransactionEdit(transaction) {
            for(var i = 0; i < this.transactions.length; i++)
            {
                if(this.transactions[i].id == transaction.id)
                {
                    this.$set(this.transactions, i, transaction)
                    return;
                }
            }
        },
        DeliveryCost(transaction)
        {
            for(var i = transaction.orders.length-1; i >= 0; i--)
            {
                var order = transaction.orders[i];
                if(order.type == 'DELIVERY')
                {
                    return `<i class="icon icon-${order.external ? 'cargo' : 'delivery'} mr5"></i>` + this.Util.Price(order.total_money);
                }
            }
            return '';
        },
        GetDeliveryStatisticsDetails(){
            if(this.statsDetails) return;
            if(!this.transactionsId.length) return this.Info('No hay transacciones');
            this.Loading();
            axios.post(`/transactions/${this.Local.id}/stats/details`, {ids: this.transactionsId}).then(({data}) => {
                data.sales = Object.values(data.sales).sort((a, b) => {
                    return b.total_money - a.total_money;
                });

                data.pay_methods = Object.values(data.pay_methods).sort((a, b) => {
                    return b.total_money - a.total_money;
                });

                data.mediums = Object.values(data.mediums).sort((a, b) => {
                    return b.total_money - a.total_money;
                });

                data.days = Object.values(data.days).sort((a, b) => {
                    return b.total_money - a.total_money;
                });

                data.hours = Object.values(data.hours).sort((a, b) => {
                    return b.total_money - a.total_money;
                });

                data.sections = Object.values(data.sections).sort((a, b) => {
                    return b.quantity - a.quantity;
                });

                data.items = Object.values(data.items);

                data.group_modifiers = Object.values(data.group_modifiers);

                data.modifiers = Object.values(data.modifiers);

                this.statsDetails = data;
                this.scale.forEach(level => {
                    this.OpenDynamicContextModal(null, this.scale.indexOf(level), level);
                });

                return this.Response(data);
            }).catch(err => {
                return this.Response(err);
            });
        },
        Percent(amount, total){
            if(total <= 0) return '';
            return Math.round(100*amount/total);
        },
        OpenDynamicContextModal(currentData = null, direction = 1, level = 'modifiers'){
            if (!this.statsDetails) return;

            const currentIndex = this.dynamicIndex[`ind_${level}`];

            const newIndex = currentIndex + direction;

            if (newIndex < 0 || newIndex >= this.scale.length) return;
            
            let start = newIndex <= this.scale.indexOf(level);

            const from = this.scale[currentIndex];
            const to = this.scale[newIndex];

            const data = this.Util.Copy(this.statsDetails[to]);
            
            let title = this.dict[to];

            if(currentData) title = `${currentData.data} > ${this.dict[to]}`;
            if(!start && currentData) this.$set(this.dynamicContextModal[level], `prev_${from}` , currentData.data);

            let ret = [];

            if(!start) this.initPrevData = null; 
            if(this.initPrevData){
                const prevKey = `prev_${this.initPrevData.level}`;
                this.dynamicContextModal[level] = {
                    [prevKey] : this.initPrevData.data,
                }
            }

            switch (to) {
                case 'sections':
                    if (this.fromTitle) {
                        const filteredData = data.filter(item => {
                            return item[this.fromTitle] && Object.values(item[this.fromTitle]).includes(this.statsConfig.label);
                        });
                        ret.push(...filteredData);
                    }else{
                        ret.push(...data);
                    }
                    break;

                case 'items':
                    data.forEach(section => {
                        Object.values(section).forEach(item => {
                            let isValid = false;

                            if(this.fromTitle) {
                                const fieldValue = item[this.fromTitle];
                                if (Array.isArray(fieldValue) || typeof fieldValue === 'object') {
                                    isValid = Object.values(fieldValue).includes(this.statsConfig.label);
                                } else {
                                    isValid = fieldValue == this.statsConfig.label;
                                }
                            }
                            
                            if (isValid || !this.fromTitle) {
                                if(start || (this.dynamicContextModal[level].prev_sections && this.dynamicContextModal[level].prev_sections == item['sections'])) return ret.push(item);
                            }
                        });
                    });
                    break;

                case 'group_modifiers':
                    const uniqueGroupModifiers = {};

                    data.forEach(section => { //[sections], items, ...
                        Object.values(section).forEach(item => {
                            Object.values(item).forEach(groupModifier => {
                                const groupModifierName = groupModifier.data;
                                let isValid = false;

                                if(this.fromTitle) {
                                    const fieldValue = groupModifier[this.fromTitle];
                                    if (Array.isArray(fieldValue) || typeof fieldValue === 'object') {
                                        isValid = Object.values(fieldValue).includes(this.statsConfig.label);
                                    } else {
                                        isValid = fieldValue == this.statsConfig.label;
                                    }
                                }

                                if(isValid || !this.fromTitle){
                                    if(start){
                                        if (!uniqueGroupModifiers[groupModifierName]) {
                                            uniqueGroupModifiers[groupModifierName] = groupModifier; //sino, lo agregamos
                                        } else {
                                            // Si ya lo tenemos, sumamos las cantidades y los totales
                                            uniqueGroupModifiers[groupModifierName].quantity += groupModifier.quantity;
                                            uniqueGroupModifiers[groupModifierName].total_money += groupModifier.total_money;
                                        }
                                    }else{
                                        if ((!this.dynamicContextModal[level].prev_sections || 
                                            this.dynamicContextModal[level].prev_sections === groupModifier['sections']) &&
                                            (!this.dynamicContextModal[level].prev_items ||
                                            this.dynamicContextModal[level].prev_items === groupModifier['items'])) {
                                            uniqueGroupModifiers[groupModifierName] = groupModifier;
                                        }
                                    }
                                }
                            });
                        });
                    });

                    ret = Object.values(uniqueGroupModifiers);
                    break;

                case 'modifiers':
                    const uniqueModifiers = {};

                    data.forEach(section => { //[sections], items, ...
                        Object.values(section).forEach(item => {
                            Object.values(item).forEach(groupModifier => {
                                Object.values(groupModifier).forEach(modifier => {
                                    const modifierName = modifier.data;

                                    let isValid = false;

                                    if(this.fromTitle) {
                                        const fieldValue = modifier[this.fromTitle];
                                        if (Array.isArray(fieldValue) || typeof fieldValue === 'object') {
                                            isValid = Object.values(fieldValue).includes(this.statsConfig.label);
                                        } else {
                                            isValid = fieldValue == this.statsConfig.label;
                                        }
                                    }

                                    if(isValid || !this.fromTitle) {
                                        if(start){
                                            if (!uniqueModifiers[modifierName]) {
                                                uniqueModifiers[modifierName] = modifier;
                                            } else {
                                                uniqueModifiers[modifierName].quantity += modifier.quantity;
                                                uniqueModifiers[modifierName].total_money += modifier.total_money;
                                            }
                                        }else{
                                            if ((!this.dynamicContextModal[level].prev_sections || 
                                                this.dynamicContextModal[level].prev_sections == modifier['sections']) &&
                                                (!this.dynamicContextModal[level].prev_items ||
                                                this.dynamicContextModal[level].prev_items == modifier['items']) &&
                                                (!this.dynamicContextModal[level].prev_group_modifiers ||
                                                this.dynamicContextModal[level].prev_group_modifiers == modifier['group_modifiers'])) {
                                                if (!uniqueModifiers[modifierName]) {
                                                    uniqueModifiers[modifierName] = modifier;
                                                } else {
                                                    uniqueModifiers[modifierName].quantity += modifier.quantity;
                                                    uniqueModifiers[modifierName].total_money += modifier.total_money;
                                                }
                                            }
                                        }
                                    }
                                });
                            });
                        });
                    });

                    ret = Object.values(uniqueModifiers);
                    break;
            
                default:
                    break;
            }

            ret.sort(function(a, b) {
                return b.quantity - a.quantity;
            });

            ret.sort(function(a, b) {
                return b.total_money - a.total_money;
            });

            this.dynamicIndex[`ind_${level}`] = newIndex;
            const stop = this.dynamicIndex[`ind_${level}`] == this.scale.length-1;

            if(start) this.dynamicContextModal[level] = {};
            
            this.$set(this.dynamicContextModal[level], 'title' , title);
            this.$set(this.dynamicContextModal[level], 'elements' , ret);
            this.$set(this.dynamicContextModal[level], 'total' , start && !this.fromTitle? this.statsDetails[`total_${to}`] : this.Util.ArraySum(ret, 'total_money'));
            this.$set(this.dynamicContextModal[level], 'stop' , stop);
            this.$set(this.dynamicContextModal[level], 'to' , to);
        },
        filterAndCalculate(data, fromTitle, label) {
            if (!data || !data.length) return [];

            const filteredSales = data.filter(sale => {
                if (fromTitle == 'categories') {
                    let categoryExists = false;
                    let totalMoney = 0;

                    for (const section of Object.values(sale.sections)) {
                        if (section[fromTitle] && Object.values(section[fromTitle]).includes(label)) {
                            categoryExists = true;
                            totalMoney += section.total_money;
                        }
                    }

                    if (!categoryExists) return false;

                    sale.total_money = totalMoney;
                    return true;
                } else {
                    if (!sale[fromTitle]) return false;

                    const filteredSections = Object.values(sale[fromTitle]).filter(element => element.data === label);

                    if (!filteredSections.length) return false;

                    sale.total_money = filteredSections.reduce((sum, section) => sum + section.total_money, 0);
                    return true;
                }
            });

            return filteredSales;
        }
    },
    computed: {
        sales() {
            return this.filterAndCalculate(this.statsDetails.sales, this.fromTitle, this.statsConfig.label);
        },
        mediumsSection() {
            return this.filterAndCalculate(this.statsDetails.mediums, this.fromTitle, this.statsConfig.label);
        },
        payMethods() {
            return this.filterAndCalculate(this.statsDetails.pay_methods, this.fromTitle, this.statsConfig.label);
        },
        daysSection() {
            return this.filterAndCalculate(this.statsDetails.days, this.fromTitle, this.statsConfig.label);
        },
        hours() {
            return this.filterAndCalculate(this.statsDetails.hours, this.fromTitle, this.statsConfig.label);
        }
    }
}
</script>
<style lang="scss">
.scroll-modal-container {
    max-height: 250px;
    overflow-y: auto;
    padding: 10px;
    margin: -10px !important;
}

@media (max-width: 820px) {
    .scroll-modal-container {
        padding: 0px;
        margin: 0px;
        height: auto;
    }
}
</style>