<template>
<div class="fill flex column flex-fill">

<div class="opt r">

    <input-field  v-show="tab == 'Tipos'"
    v-model="search"
    inputClass="secondary"
    pre="search"
    cleareable
    placeholder="Buscar..."
    :icon="mobile ? 'search' : ''"
    useHeader
    :timeout="300">
    </input-field>

    <input-range ref="range" class="flex-fill" v-show="tab=='Resumen'"
    inputClass="secondary"
    v-model="range"
    @OnValueChanged="Search()"
    :icon="mobile ? 'datetime' : ''">
    </input-range>

    <input-date :icon="mobile ? 'calendar' : null" 
        slot="options"
        v-show="tab=='Historial'"
        inputClass="secondary" 
        type="DATE"
        format="timestamp"
        v-model="dateFilter"
        modalOnly
        :toEndDay="true"
        @OnAcceptDate="LoadHistory()">
    </input-date>

    <i v-show="isAdmin && tab=='Resumen'" class="options icon-dots-vertical" @click="Options([
        {text: 'Exportar', class: 'selected-color', function: () => { Export() }}
    ])"></i>

    <i v-show="isAdmin && tab=='Tipos'" class="options icon-dots-vertical" @click="Options([
        {text: 'Configurar Tipo de Gasto', class: 'selected-color', function: () => {modalNew={expense: {}}}}
    ])"></i>
</div>

<tabs v-model="tab"  v-if="tabs"
:tabs="tabs" :keyByTab="true" 
@OnTabResumen="stats == null ? Search() : null"
@OnTabHistorial="history.length == 0 ? LoadHistory() : null" class="fill"
@OnTabTipos="LoadExpenses">

    <div slot="Resumen" class="pa-body">
        <div v-if="stats && stats.history.length > 0">

            <div class="section mb20" v-if="stats.history.length > 1">
                <chart
                type="line"
                :data="[stats.history]"
                dataset="total_money"
                label="data"
                height="200px"
                :yLabelFormat="(value) => {return Util.Price(value, true)}"
                :tooltip="(value, label, index) => {return `${Util.Price(value)}`}"
                :customColor="[Util.Chart.purpleGradient]">
                </chart>
            </div>

            <!--
            <div class="hs mb20">
                <div>
                    <div class="section flex align-center">
                        <avatar class="mr10"
                        icon="receipt"
                        :color="Util.baseColors.yellow">
                        </avatar>
                        <div class="bold flex-fill">Transacciones</div>
                        <div class="f18 cyellow">{{Util.Number(stats.count)}}</div>
                    </div>
                </div>

                <div>
                    <div class="section flex align-center">
                        <avatar class="mr10"
                        icon="money"
                        :color="Util.baseColors.red">
                        </avatar>
                        <div class="flex-fill">
                            <div class="bold">Total</div>
                        </div>
                        <div class="f18 cred">{{Util.Price(stats.total_money)}}</div>
                    </div>
                </div>
            </div>
            -->

            
            <div class="section flex align-center mb20">
                <avatar class="mr10"
                icon="money"
                :color="Util.baseColors.red">
                </avatar>
                <div class="flex-fill">
                    <div class="bold">Total</div>
                    <div class="secondary-text">{{Util.Number(stats.count)}} transacciones</div>
                </div>
                <div class="f18 cred">{{Util.Price(stats.total_money)}}</div>
            </div>
            

            
            <div class="" v-if="stats.history.length > 1">
                <!--<div class="f18 mb20">Total</div>-->
                <div class="section mb10"
                v-for="(history, index) in stats.history"
                :key="index">
                    <div class="flex fill align-center">
                        <div class="flex column flex-fill">
                            <div class="bold">{{history.data}}</div>
                            <div class="secondary-text"><span class="bold">Transacciones: </span>{{Util.Number(history.count)}}</div>
                        </div>
                        <div class="bold">{{Util.Price(history.total_money)}}</div>
                    </div>
                </div>
            </div>
            

            <div class="mt20" v-if="stats.types.length">
                <div class="f18 mb20">Total por Tipo</div>
                <div class="section mb10"
                v-for="(type, index) in stats.types"
                :key="index">
                    <div class="flex fill align-center">
                        <avatar class="mr10"
                        defaultImageIcon="1727"
                        :imageIcon="type.data">
                        </avatar>
                        <div class="flex column flex-fill">
                            <div class="bold">{{type.data}}</div>
                            <div class="secondary-text"><span class="bold">Transacciones: </span>{{Util.Number(type.count)}}</div>
                        </div>
                        <div class="bold">{{Util.Price(type.total_money)}}</div>
                    </div>
                </div>
            </div>
            
        </div>
        <div v-else class="secondary-text mt10 ta-center">No hay datos.</div>
    </div>

    <vertical-content ref="verticalHistory" slot="Historial" @OnBottom="LoadHistory(true)" class="pa-body">
        <div class="" v-if="history.length > 0">
            <div class="section mb10 selectable" 
            v-for="(transaction, index) in history"
            :key="index"
            @click="$refs.expenseDetails.Open(transaction)">
                <div class="flex fill align-center">
                    <avatar class="mr10"
                    defaultImageIcon="1727"
                    :imageIcon="transaction.type">
                    </avatar>
                    <div class="flex-fill flex column">
                        <div class="bold">{{transaction.type}}</div>
                        <div class="secondary-text" v-if="transaction.comment"><span class="bold">Comentario:</span> {{transaction.comment}}</div>
                        <div class="secondary-text" v-if="transaction.custom_data && transaction.custom_data.created_by"><span class="bold">Ingresado por:</span> {{transaction.custom_data.created_by}}</div>
                        <div class="secondary-text"><span class="bold">Fecha:</span> {{Util.Date(transaction.date, 'DD/MM/YYYY HH:mm')}}</div>
                    </div>
                    <div class="flex column ta-right">
                        <div class="bold">{{Util.Price(transaction.money)}}</div>
                        <div class="secondary-text">{{Util.PayMethod(transaction.pay_method)}}</div>
                    </div>
                </div>
            </div>
        </div>
        <div v-else class="secondary-text mt10 ta-center">No hay datos.</div>
    </vertical-content>

    <div slot="Tipos" class="pa-body">
        <div class="secondary-text ta-center mt10" v-if="!filtered.length">No hay datos</div>
        <div class="" v-if="filtered.length">
            <div class="section mb10 selectable"
            v-for="(expense, index) in filtered"
            :key="index"
            @click="isAdmin ? Summary(expense) : modalAdd={type: expense.title}">
                <div class="fill flex align-center">

                    <avatar class="mr10"
                    defaultImageIcon="1727"
                    :imageIcon="expense.title">
                    </avatar>

                    <div class="flex column">
                        <div class="bold">{{expense.title}}</div>
                        <div v-if="expense.description" class="secondary-text">{{expense.description}}</div>
                        <div v-if="expense.auto" class="secondary-text">Gasto automático de {{Util.Price(expense.auto_money)}} a {{expenseEvent[expense.auto]}}</div>
                    </div>
                    <div class="flex-fill"></div>
                    <div v-if="isAdmin" class="options icon-dots-vertical" @click.stop="Options([
                        {text: 'Eliminar', class: 'error-color', function: () => {DeleteExpense(expense.title)}, confirm: '¿Eliminar gasto definitivamente?'},
                        {text: 'Editar', function: () => {modalNew = {edit: true, oldTitle: expense.title, expense: Util.Copy(expense, {auto_event: expense.auto})}}}, //TODO
                        {text: 'Ingresar Gasto', class: 'selected-color', function: () => {modalAdd={type: expense.title}}}
                    ])"></div>
                </div>
            </div>
        </div>

    </div>

</tabs>

<modal v-model="modalNew" :dir="['right', 'center']" :modalClass="['fill', 'sm']" :title="modalNew ? (modalNew.oldTitle ? `Editar ${modalNew.oldTitle}` : 'Nuevo Tipo de Gasto') : ''">
    <div class="section" v-if="modalNew">
        <input-field class="mb15"
        v-model="modalNew.expense.title"
        label="Título">
        </input-field>

        <input-field class="mb15"
        v-model="modalNew.expense.description"
        label="Descripción">
        </input-field>

        <label class="">
            <input type="checkbox" v-model="modalNew.expense.auto"> Ingresar gasto automáticamente <div class="checkmark"></div>
        </label>

        <input-select class="mt15"
        v-if="modalNew.expense.auto"
        v-model="modalNew.expense.auto_event"
        label="Periodo"        
        :items="[{text: 'Fin de Mes', value: 'END_OF_MONTH'}, 
                {text: 'Inicio de Mes', value: 'START_OF_MONTH'},
                {text: 'Fin de Semana', value: 'END_OF_WEEK'},
                {text: 'Inicio de Semana', value: 'START_OF_WEEK'}]">
        </input-select>

        <input-field class="mt15"
        v-if="modalNew.expense.auto"
        v-model="modalNew.expense.auto_money"
        label="Cantidad"
        prefix="$"
        type="int+">
        </input-field>

    </div>
    
    <button slot="actions" class="primary purple" @click="SaveExpense()">Aceptar</button>
    
</modal>

<modal v-model="modalAdd" :dir="['right', 'center']" :modalClass="['fill', 'sm']" :title="modalAdd ? `Ingresar Gasto de ${modalAdd.type}` : ''">
    <div class="section" v-if="modalAdd">
        <input-field class="mb15"
        v-model="modalAdd.money"
        label="Total"
        type="number">
        </input-field>

        <input-select class="mb15"
        v-model="modalAdd.pay_method"
        :items="methods"
        label="Método de Pago">
        </input-select>

        <input-field class="mb15"
        v-model="modalAdd.comment"
        label="Comentario"
        placeholder="(Opcional)">
        </input-field>

        <!--<input-image v-model="modalAdd.image" label="Documento (Opcional)"></input-image>-->
        <input-files v-model="modalAdd.files" label="Documento (Opcional)"></input-files>

    </div>
    
    <button slot="actions" class="primary purple" @click="Expense()">Aceptar</button>
    
</modal>

<modal v-model="modalTypeSummary" dir="right" :title="modalTypeSummary?modalTypeSummary.title:''" :modalClass="['fill', 'sm right h100']" hideActions>
    <input-date :icon="mobile ? 'calendar' : null"
        v-show="tabModal == 'Historial'"
        slot="options"
        inputClass="secondary" 
        type="DATE"
        format="timestamp"
        v-model="dateFilterOnModal"
        modalOnly
        :toEndDay="true"
        @OnAcceptDate="LoadTypeHistory()">
    </input-date>
    <tabs v-if="modalTypeSummary" :tabs="itemTabs" :keyByTab="true" @OnTabHistorial="modalTypeSummary.history.length == 0 ? LoadTypeHistory() : null" v-model="tabModal">
        <div slot="Resumen">
            <div class="secondary-text mt10 ta-center" v-if="!modalTypeSummary.stats">No hay datos.</div>
            <div class="section" v-if="modalTypeSummary.stats">
                
                <div class="f18 mb20">Promedio <span class="secondary-text">(Últimos 30 días)</span></div>

                <div class="mb15">
                    <div class="bold">Transacciones</div>
                    <div>{{Util.Number(modalTypeSummary.stats.count)}}</div>
                </div>
                <div class="mb15">
                    <div class="bold">Total gastado</div>
                    <div>{{Util.Price(modalTypeSummary.stats.total_money)}}</div>
                </div>
                <div class="mb15">
                    <div class="bold">Promedio gastado por semana</div>
                    <div>{{Util.Price(Math.round(modalTypeSummary.stats.total_money/4))}}</div>
                </div>
                <div class="">
                    <div class="bold">Promedio gastado por día</div>
                    <div>{{Util.Price(Math.round(modalTypeSummary.stats.total_money/30))}}</div>
                </div>
            </div>
        </div>
        <vertical-content ref="verticalTypeHistory" slot="Historial" @OnBottom="LoadTypeHistory(true)" class="pa20" >
            <div class="" v-if="modalTypeSummary.history.length > 0">
                <div class="entry selectable" 
                v-for="(transaction, index) in modalTypeSummary.history"
                :key="index"
                @click="$refs.expenseDetails.Open(transaction)">
                    <div class="flex fill align-center">
                        <div class="flex-fill flex column">
                            <div class="bold">{{transaction.type}}</div>
                            <div class="secondary-text" v-if="transaction.comment"><span class="bold">Comentario:</span> {{transaction.comment}}</div>
                            <div class="secondary-text" v-if="transaction.custom_data && transaction.custom_data.created_by"><span class="bold">Ingresado por:</span> {{transaction.custom_data.created_by}}</div>
                            <div class="secondary-text"><span class="bold">Fecha:</span> {{Util.Date(transaction.date, 'DD/MM/YYYY HH:mm')}}</div>
                        </div>
                        <div class="bold">{{Util.Price(transaction.money)}}</div>
                        <!--<i class="icon icon-arrow-right-o c4 ml10"></i>-->
                    </div>
                </div>
            </div>
            <div v-else class="secondary-text mt10 ta-center">No hay datos.</div>
        </vertical-content>
    </tabs>
</modal>

<expense-details ref="expenseDetails" @OnTransactionDeleted="OnTransactionDeleted"></expense-details>

</div>
</template>

<script>
export default {
    data() {
        return {
            tab: null,
            tabModal : null,
            modalNew: null,
            modalAdd: null,
            modalTypeSummary: null,
            expenses: [],
            dateFilter : null,
            dateFilterOnModal: null,
            history: [],
            stats: null,
            range: {
                option: 'MONTH',
                index: 0
            },
            methods: [{text: 'Sin definir', value: 'UNDEFINED'}],
            search: '',
            expenseEvent: {
                START_OF_MONTH: 'inicio de mes',
                END_OF_MONTH: 'fin de mes',
                START_OF_WEEK: 'inicio de la semana (lunes)',
                END_OF_WEEK: 'fin de la semana (domingos)',
            },
            tabs: null,
            itemTabs: null
        }
    },
    mounted() {

        this.tabs = [];
        if(this.Local.modules.module_expenses && this.isAdmin) this.tabs.push('Resumen');
        if(this.Local.modules.module_expenses && this.isAdmin) this.tabs.push('Historial');
        if(this.Local.modules.module_expenses) this.tabs.push('Tipos');

        this.itemTabs = [];
        if(this.Local.modules.module_expenses) this.itemTabs.push('Resumen');
        if(this.Local.modules.module_expenses) this.itemTabs.push('Historial');

        if(this.tabs.length == 1 && this.tabs[0] == 'Tipos') this.LoadExpenses();
        
    },
    methods: {
        Search()
        {
            if(!this.isAdmin) return;

            this.Loading();
            axios.post(`/expenses/${this.Local.id}/stats`, this.range).then(res => {
                this.Response(res);

                var count = 0;
                res.data.types.forEach(type => {
                    count += type.count;
                });
                res.data.count = count;

                this.stats = res.data;
            }).catch(err => {
                this.Response(err);
            });
        },
        SaveExpense() {
            var expense = this.modalNew.expense;
            if(!expense.title) return this.$root.$emit('Response', {info: 'Título inválido.'});
            if(expense.auto && !expense.auto_money) return this.$root.$emit('Response', {info: 'Datos inválidos.'});
            
            expense.title = expense.title.toUpperCase();
            if(this.modalNew.edit)
            {
                var func = () => {
                    this.Loading();
                    axios.patch(`/expense/${this.Local.id}`, {expense: expense, oldTitle: this.modalNew.oldTitle}).then(res => {
                        this.Response(res);
                        this.expenses = res.data;
                        this.modalNew = false;
                    }).catch(err => {
                        this.Response(err);
                    });
                }

                if(expense.title !== this.modalNew.oldTitle)
                {
                    this.Confirm({
                        text: 'Cambiar',
                        title: '¿Cambiar el nombre del gasto? No se cambiarán los gastos previamente ingresados',
                        function: () => {func()}
                    });
                }
                else
                {
                    func();
                }

                
            }
            else
            {
                this.Loading();
                axios.put(`/expense/${this.Local.id}`, {expense: expense}).then(res => {
                    this.Response(res);
                    this.expenses = res.data;
                    this.modalNew = false;
                }).catch(err => {
                    this.Response(err);
                });
            }
        },
        DeleteExpense(type) {
            this.Loading();
            axios.post(`/expense/${this.Local.id}/type`, {title: type}).then(res => {
                this.Response(res);
                this.expenses = res.data;
            }).catch(err => {
                this.Response(err);
            });
        },
        Expense() {
            if(!this.modalAdd.money) return this.Info('Datos inválidos.');
            this.Loading();
            axios.post(`/expense/${this.Local.id}`, this.modalAdd).then(res => {
                this.Info('Gasto ingresado exitósamente');
                this.modalAdd = null;
                this.history = [];
                this.stats = null;
            }).catch(err => {
                this.Response(err);
            });
        },
        Summary(expense)
        {
            this.Loading();
            axios.get(`/expense/${this.Local.id}/${expense.title}/summary`).then(res => {
                this.Response(res);
                this.modalTypeSummary = {title: expense.title};
                this.modalTypeSummary.stats = res.data;
                this.modalTypeSummary.history = [];
            }).catch(err => {
                this.Response(err);
            });
        },
        LoadTypeHistory(bottom=false)
        {
            //url antigua
            // `/expense/${this.Local.id}/history/type/${this.modalTypeSummary.title}${history.length>0?'/'+(history[history.length-1].date-1):''}`
            this.Loading();
            this.$refs.verticalTypeHistory.Reset()
            if(!bottom) this.modalTypeSummary.history = []
            this.$nextTick(() => {
                // let history = this.modalTypeSummary.history;
                axios.get(`/expense/${this.Local.id}/history/type/${this.modalTypeSummary.title}/${!bottom ? this.dateFilterOnModal : this.modalTypeSummary.history.length > 0 ?this.modalTypeSummary.history[this.modalTypeSummary.history.length-1].date-1 : ''}`).then(res => {
                    this.Response(res);
                    this.modalTypeSummary.history = this.modalTypeSummary.history.concat(res.data);
                    this.$forceUpdate();
                }).catch(err => {
                    this.Response(err);
                });
            })
        },
        LoadHistory(bottom=false)
        {
            //url anterior
            //`/expenses/${this.Local.id}/history${this.history.length>0?'/'+(this.history[this.history.length-1].date-1):''}`

            this.$refs.verticalHistory.Reset()
            if(!bottom) this.history = []
            this.Loading();
            this.$nextTick(() => {
                axios.get(`/expenses/${this.Local.id}/history/${!bottom ? this.dateFilter : this.history.length > 0 ? this.history[this.history.length-1].date-1 : ''}`).then(res => {
                    this.Response(res);
                    this.history = this.history.concat(res.data);
                }).catch(err => {
                    this.Response(err);
                }); 
            })
        },
        LoadExpenses()
        {
            if(!this.expenses.length)
            {
                this.Loading();
                axios.get(`/expenses/${this.Local.id}`).then(res => {
                    this.Response(res);
                    this.expenses = res.data.types;
                    for(var i = 0; i < res.data.pay_methods.length; i++)
                        this.methods.push({text: res.data.pay_methods[i] == 'CASH' ? 'Efectivo' : res.data.pay_methods[i], value: res.data.pay_methods[i]});
                }).catch(err => {
                    this.Response(err);
                });
            }
        },
        Export(){
            this.Loading();
            axios.post(`/expenses/${this.Local.id}/export`, this.range, {responseType: 'blob'})
            .then(res => {
                    this.Response(res);
                    this.Util.DownloadFile(res, `gastos_${Util.Today().date.replace(/\//g, '-')}.xls`);
            }).catch(err => {
                this.Response(err);
            });
        },
        OnTransactionDeleted(data) {
            this.history = this.history.filter(entry => data.id !== entry.id);
            this.stats = null;
        },
    },
    computed: {
        filtered() {
            if(!this.expenses) return [];
            if(!this.search) return this.expenses;
            var ret = [];
            for(var i = 0; i < this.expenses.length; i++)
                if(this.expenses[i].title.toUpperCase().indexOf(this.search.toUpperCase()) > -1) ret.push(this.expenses[i]);
            
            return ret;
        }
    }
}
</script>