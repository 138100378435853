<template>
<modal v-model="modalTurn" dir="right" :modalClass="['fill', 'lg right h100']" :title="modalTurn ? `Turno de ${modalTurn.name}` : ''" hideActions>
    <i v-if="modalTurn && (Auth.role.indexOf('admin') > -1 || modalTurn.ended_at)" class="options icon-dots-vertical" slot="options" @click="Options([
        {text: 'Imprimir Inventario', function: () => {PrintInventory()}, if: !!Local.modules.module_service && modalTurn.module_inventory && (showInventory || modalTurn.ended_at) && modalTurn.role !== 'delivery'},
        {text: 'Imprimir Resumen', function: () => {Print()}, if: !!Local.modules.module_service},
        {text: 'Descargar Reporte', function: () => {Export()}}
    ])"></i>
    <tabs :tabs="tabs" v-if="Local && tabs && modalTurn" :keyByTab="true" @OnTabHistorial="!modalTurn.history.length ? LoadTurnHistory() : null">
        
        <!-- vista nueva -->
        <div slot="Resumen" v-if="modalTurn && !oldView" class="pa20 copyable">
            <div  v-if="modalTurn.ended_at || showSummary">
                
                <div class="hs">
                    <div class="well flex icon-small-mobile">
                        <avatar class="mr10-desktop"
                        :icon="'circle-exclamation'"
                        :color="Util.baseColors.pink">
                        </avatar>
                        <div>
                            <div class="secondary-text pl20-mobile">Inicio</div>
                            <div>{{Util.Date(modalTurn.started_at, 'HH:mm DD/MM/YYYY')}}</div>
                        </div>
                    </div>

                    <div class="well flex icon-small-mobile">
                        <avatar class="mr10-desktop"
                        :icon="'circle-exclamation'"
                        :color="Util.baseColors.pink">
                        </avatar>
                        <div>
                            <div class="secondary-text pl20-mobile">Fin</div>
                            <div>{{modalTurn.ended_at ? Util.Date(modalTurn.ended_at, 'HH:mm DD/MM/YYYY') : 'Activo'}}</div>
                        </div>
                    </div>
                </div>

                <div v-if="modalTurn.role !== 'delivery' && cashierTurn" class="well flex icon-small-mobile mt10">
                    <avatar class="mr10-desktop"
                    :icon="'bill'"
                    :color="Util.baseColors.green">
                    </avatar>
                    <div>
                        <div class="secondary-text pl20-mobile">Efectivo Inicial</div>
                        <div>{{Util.Price(modalTurn.initial_cash)}}</div>
                    </div>
                </div>

                <div class="hs mt10" v-if="modalTurn.role !== 'delivery'">
                    <div class="well flex icon-small-mobile">
                        <avatar class="mr10-desktop"
                        :icon="'arrow-se'"
                        :color="Util.baseColors.green">
                        </avatar>
                        <div>
                            <div class="secondary-text pl20-mobile">Ingresos</div>
                            <div>{{Util.Price(modalTurn.total_income)}}</div>
                        </div>
                    </div>
                    <div class="well flex icon-small-mobile" v-if="cashierTurn">
                        <avatar class="mr10-desktop"
                        :icon="'arrow-ne'"
                        :color="Util.baseColors.red">
                        </avatar>
                        <div>
                            <div class="secondary-text pl20-mobile">Egresos</div>
                            <div>{{Util.Price(modalTurn.total_expense)}}</div>
                        </div>
                    </div>
                    <div class="well flex icon-small-mobile" v-if="cashierTurn">
                        <avatar class="mr10-desktop"
                        :icon="'balance'"
                        :color="Util.baseColors.green">
                        </avatar>
                        <div>
                            <div class="secondary-text pl20-mobile">Balance</div>
                            <div>{{Util.Price(modalTurn.total_income - modalTurn.total_expense)}}</div>
                        </div>
                    </div>
                </div>

            </div>

            <div v-if="modalTurn.comments.length && (showComments || modalTurn.ended_at)">

                <div class="f18 mb20 mt20">Notas y comentarios adicionales</div>

                <div class="well flex icon-small-mobile mb5" v-for="(comment, index) in modalTurn.comments" :key="`comment${index}`">
                    <avatar class="mr10-desktop"
                    :icon="'comment'">
                    </avatar>
                    <div>
                        <div class="secondary-text pl20-mobile">{{Util.Date(comment.timestamp, 'HH:mm DD/MM/YYYY')}}</div>
                        <div>{{comment.message}}</div>
                    </div>
                </div>

            </div>

            <div class="" v-if="showMethods || modalTurn.ended_at">

                <div class="f18 mb20 mt20">Métodos de Pago</div>

                <div class="well flex icon-small-mobile mb5" v-for="method in modalTurn.balance" :key="method.data">

                    <div class="flex-fill">
                        <div class="flex">
                            <div class="flex-fill">{{Util.PayMethod(method.data)}}</div>
                            <div>{{Util.Price(method.total)}}</div>
                        </div>
                        <div class="hs">
                            <div>
                                <div class="secondary-text">Transacciones</div>
                                <div>{{Util.Price(method.transactions_income)}}</div>
                            </div>
                            <div>
                                <div class="secondary-text">Liquidados</div>
                                <div>{{Util.Price(method.transactions_income)}}</div>
                            </div>
                            <div>
                                <div class="secondary-text">Gastos</div>
                                <div>{{Util.Price(method.transactions_income)}}</div>
                            </div>
                        </div>
                    </div>
                </div>

                <table class="summary">
                    <tr>
                        <th>Método de Pago</th>
                        <th v-if="cashierTurn">Transacciones</th>
                        <th v-if="cashierTurn">Pagos Pendientes</th>
                        <th v-if="cashierTurn">Gastos</th>
                        <th v-if="modalTurn.ghost_close">Total Declarado</th>
                        <th style="text-align: right">Total</th>
                    </tr>
                    <tr v-for="method in modalTurn.balance"
                    :key="method.data">
                    <td v-html="method.data === 'CASH' && cashierTurn 
                        ? `Efectivo <span class='secondary-text'>${!modalTurn.not_add_initial_cash ? '(' + Util.Price(modalTurn.initial_cash) + ')' : ''}</span>` 
                        : Util.PayMethod(method.data)">
                        </td>
                        <td v-if="cashierTurn">{{Util.Price(method.transactions_income)}}</td>
                        <td v-if="cashierTurn">{{Util.Price(method.pending_payments_income)}}</td>
                        <td v-if="cashierTurn">{{Util.Price(method.total_expense)}}</td>
                        <td v-if="modalTurn.ghost_close" :class="method.ghost_total < method.total ? 'cred' : (method.ghost_total !== method.total ? 'cyellow' : '')">{{Util.Price(method.ghost_total)}} <i class="icon icon-circle-exclamation"></i></td>
                        <td style="text-align: right">{{Util.Price(method.total)}}</td>
                    </tr>
                </table>
            </div>

            <div class="" v-if="(showTransactions || modalTurn.ended_at)">

                <div class="f18 mb20 mt20">Transacciones</div>
                <table class="summary">
                    <tr>
                        <th>Número</th>
                        <th>Ingresada</th>
                        <th>Completada</th>
                        <th>Cancelada</th>
                        <th style="text-align: right">Total</th>
                    </tr>
                    <tr v-for="transaction in modalTurn.transactions"
                    :key="transaction.id"
                    class="selectable"
                    @click="$refs.transactionDetails.Open(transaction.id)">
                        <td>{{transaction.number}}</td>
                        <td><span v-if="transaction.created_at" :class="transaction.created_at == transaction.paid_at ? `cblue` : ''">{{Util.Date(transaction.created_at, 'HH:mm DD/MM/YYYY')}}</span></td>
                        <td><span v-if="transaction.completed_at" :class="transaction.completed_at == transaction.paid_at ? `cblue` : ''">{{Util.Date(transaction.completed_at, 'HH:mm DD/MM/YYYY')}}</span></td>
                        <td><span v-if="transaction.cancelled_at">{{Util.Date(transaction.cancelled_at, 'HH:mm DD/MM/YYYY')}}</span></td>
                        <td style="text-align: right">{{Util.Price(transaction.total_money)}} <i class="icon icon-arrow-right-o c4 ml5"></i></td>
                    </tr>
                    <tr>
                        <td class="bold">Total</td>
                        <td>{{Util.Number(modalTurn.transactions_count.created)}}</td>
                        <td>{{Util.Number(modalTurn.transactions_count.completed)}}</td>
                        <td>{{Util.Number(modalTurn.transactions_count.cancelled)}}</td>
                        <td></td>
                    </tr>
                </table>
            </div>

            <div class="" v-if="(showEdit || modalTurn.ended_at) && modalTurn.role !== 'delivery'">
                
                <div class="separator mb20 mt20"></div>

                <div class="f18 mb20">Transacciones Editadas</div>
                 <table class="summary">
                    <tr>
                        <th>Número</th>
                        <th>Editada</th>
                        <th>Comentario</th>
                        <th></th>
                    </tr>
                    <tr v-for="(revision, index) in modalTurn.edit"
                    :key="index"
                    class="selectable"
                    @click="$refs.transactionDetails.Open(revision.id)">
                        <td>{{revision.id_ticket}}</td>
                        <td>{{Util.Date(revision.created_at, 'HH:mm DD/MM/YYYY')}}</td>
                        <td>{{revision.comment}}</td>
                        <td style="text-align: right"><i class="icon icon-arrow-right-o c4 ml5"></i></td>
                    </tr>
                </table>
            </div>

            <div class="" v-if="modalTurn.delivery && (showDelivery || modalTurn.ended_at)">
                
                <div class="separator mb20 mt20"></div>

                <div class="f18 mb20">Reparto</div>
                <table class="summary">
                    <tr>
                        <th>Cuenta</th>
                        <th>Total</th>
                        <th></th>
                    </tr>
                    <tr v-for="(entry, index) in StaffTable(modalTurn.delivery.internal.staff)"
                    :key="index+'delivery internal'"
                    class="selectable"
                    @click="$refs.transactionsList.Open(entry.transactions)">
                        <td :style="!entry.transactions.length ? {'padding-left': '15px'} : {}">{{entry.data}}</td>
                        <td>{{Util.Price(entry.total)}}</td>
                        <td style="text-align: right"><i v-if="entry.transactions.length" class="icon icon-arrow-right-o c4 ml5"></i></td>
                    </tr>
                    <tr>
                        <td></td>
                        <td>{{Util.Price(modalTurn.delivery.internal.total)}}</td>
                        <td></td>
                    </tr>
                </table>
            </div>

            <div class="" v-if="modalTurn.delivery && (showDelivery || modalTurn.ended_at)">
                
                <div class="separator mb20 mt20"></div>

                <div class="f18 mb20">Reparto Externo</div>
                <table class="summary">
                    <tr>
                        <th>Cuenta</th>
                        <th>Total</th>
                        <th></th>
                    </tr>
                    <tr v-for="(entry, index) in StaffTable(modalTurn.delivery.external.staff)"
                    :key="index+'delivery external'"
                    class="selectable"
                    @click="$refs.transactionsList.Open(entry.transactions)">
                        <td :style="!entry.transactions.length ? {'padding-left': '15px'} : {}">{{entry.data}}</td>
                        <td>{{Util.Price(entry.total)}}</td>
                        <td style="text-align: right"><i v-if="entry.transactions.length" class="icon icon-arrow-right-o c4 ml5"></i></td>
                    </tr>
                    <tr>
                        <td></td>
                        <td>{{Util.Price(modalTurn.delivery.external.total)}}</td>
                        <td></td>
                    </tr>
                </table>
            </div>

            <div class="" v-if="showTips || modalTurn.ended_at">
                
                <div class="separator mb20 mt20"></div>

                <div class="f18 mb20">Propinas</div>
                <table class="summary">
                    <tr>
                        <th>Cuenta</th>
                        <th>Total</th>
                        <th></th>
                    </tr>
                    <tr v-for="(tip, index) in StaffTable(modalTurn.tips.staff)"
                    :key="index+'tip_staff'"
                    class="selectable"
                    @click="$refs.transactionsList.Open(tip.transactions)">
                        <td :style="!tip.transactions.length ? {'padding-left': '15px'} : {}">{{tip.data}}</td>
                        <td>{{Util.Price(tip.total)}}</td>
                        <td style="text-align: right"><i v-if="tip.transactions.length" class="icon icon-arrow-right-o c4 ml5"></i></td>
                    </tr>
                    <tr>
                        <td></td>
                        <td>{{Util.Price(modalTurn.tips.total)}}</td>
                        <td></td>
                    </tr>
                </table>
            </div>

            <div class="" v-if="(showPendingPayments || modalTurn.ended_at) && modalTurn.role !== 'delivery'">
                
                <div class="separator mb20 mt20"></div>

                <div class="f18 mb20">Pagos Pendientes Liquidados</div>
                <table class="summary">
                    <tr>
                        <th>Pago</th>
                        <th>Fecha</th>
                        <th>Método de Pago</th>
                        <th style="text-align: right">Total</th>
                    </tr>
                    <tr v-for="(pp, index) in modalTurn.pending_payments"
                    :key="index"
                    class="selectable"
                    @click="$refs.pendingPaymentDetails.Open(pp.id)">
                        <td>{{pp.data}}</td>
                        <td>{{Util.Date(pp.created_at, 'HH:mm DD/MM/YYYY')}}</td>
                        <td>{{Util.PayMethod(pp.method)}}</td>
                        <td style="text-align: right">{{Util.Price(pp.total_money)}} <i class="icon icon-arrow-right-o c4 ml5"></i></td>
                    </tr>
                </table>
            </div>
            <div class="" v-if="modalTurn.invoices && (showInvoices || modalTurn.ended_at) && modalTurn.role !== 'delivery'">
                
                <div class="separator mb20 mt20"></div>

                <div class="f18 mb20">Facturas de Inventario Liquidadas</div>
                <table class="summary">
                    <tr>
                        <th>Factura</th>
                        <th>Fecha</th>
                        <th>Método de Pago</th>
                        <th style="text-align: right">Total</th>
                    </tr>
                    <tr v-for="(invoice, index) in modalTurn.invoices"
                    :key="index"
                    class="selectable"
                    @click="$refs.invoiceDetails.Open(invoice.id)">
                        <td>{{invoice.data}}</td>
                        <td>{{Util.Date(invoice.created_at, 'HH:mm DD/MM/YYYY')}}</td>
                        <td>{{Util.PayMethod(invoice.method)}}</td>
                        <td style="text-align: right">{{Util.Price(invoice.total_money)}} <i class="icon icon-arrow-right-o c4 ml5"></i></td>
                    </tr>
                </table>
            </div>
            <div class="" v-if="modalTurn.expenses && (showExpenses || modalTurn.ended_at) && modalTurn.role !== 'delivery'">
                
                <div class="separator mb20 mt20"></div>

                <div class="f18 mb20">Gastos</div>
                <table class="summary">
                    <tr>
                        <th>Gasto</th>
                        <th>Fecha</th>
                        <th>Método de Pago</th>
                        <th style="text-align: right">Total</th>
                    </tr>
                    <tr v-for="(expense, index) in modalTurn.expenses"
                    :key="index"
                    class="selectable"
                    @click="expense.type == 'expense' ? $refs.expenseDetails.Open(expense.id) : $refs.inventoryDetails.Open(expense.id)">
                        <td>{{expense.data}}</td>
                        <td>{{Util.Date(expense.created_at, 'HH:mm DD/MM/YYYY')}}</td>
                        <td>{{Util.PayMethod(expense.method)}}</td>
                        <td style="text-align: right">{{Util.Price(expense.total_money)}} <i class="icon icon-arrow-right-o c4 ml5"></i></td>
                    </tr>
                </table>
            </div>
            <div class="" v-if="modalTurn.items && (showItems || modalTurn.ended_at) && modalTurn.role !== 'delivery'">
                
                <div class="separator mb20 mt20"></div>

                <div class="f18 mb20">Items</div>
                <table class="summary">
                    <tr>
                        <th>Item</th>
                        <th>Cantidad</th>
                        <th style="text-align: right">Total</th>
                    </tr>
                    <tr v-for="(item, index) in modalTurn.items"
                    :class="item.transactions ? 'selectable' : ''"
                    @click="item.transactions ? $refs.transactionsList.Open(item.transactions) : null"
                    :key="index">
                        <td :style="{'padding-left': item.total === null ? '15px' : ''}">{{item.title}}</td>
                        <td>{{Util.Number(item.quantity)}}</td>
                        <td style="text-align: right">{{item.total !== null ? Util.Price(item.total) : ''}} <i v-if="item.transactions" class="icon icon-arrow-right-o c4 ml5"></i></td>
                    </tr>
                </table>
            </div>
            <div class="" v-if="modalTurn.inventory && (showInventory || modalTurn.ended_at) && modalTurn.role !== 'delivery'">
                
                <div class="separator mb20 mt20"></div>
                
                <div class="f18 mb20">Inventario</div>
                <table class="summary">
                    <tr>
                        <th>Item</th>
                        <th>Agregado</th>
                        <th>Utilizado</th>
                        <th style="text-align: right">Final</th>
                    </tr>
                    <tr v-for="(item, index) in modalTurn.inventory.used"
                    :key="index">
                        <td>{{item.title}}</td>
                        <td>{{Util.Number(item.added)}} <span v-if="item.added_turn">({{Util.Number(item.added_turn)}})</span> <span v-if="item.unit" class="secondary-text">{{item.unit}}</span></td>
                        <td>{{Util.Number(item.used)}} <span v-if="item.used_turn">({{Util.Number(item.used_turn)}})</span> <span v-if="item.unit" class="secondary-text">{{item.unit}}</span></td>
                        <td style="text-align: right">{{Util.Number(item.from)}} <span v-if="item.unit" class="secondary-text">{{item.unit}}</span> <i class="icon icon-arrow-right"></i> {{Util.Number(item.to)}} <span v-if="item.unit" class="secondary-text">{{item.unit}}</span></td>
                    </tr>
                </table>

                <div class="separator mb20 mt20"></div>

                <div class="f18 mb20">Movimientos de Inventario</div>

                <table class="summary">
                    <tr style="text-align: right">
                        <th>Item</th>
                        <th>Cantidad</th>
                        <th>Total</th>
                        <th>Método de Pago</th>
                        <th>Fecha</th>
                    </tr>
                    <tr v-for="(item, index) in modalTurn.inventory.transactions"
                    :key="index"
                    class="selectable"
                    @click="$refs.inventoryDetails.Open(item.id)">
                        <td>{{item.title}}</td>
                        <td>{{Util.Number(item.quantity)}}</td>
                        <td>{{Util.Price(item.total_money)}}</td>
                        <td>{{Util.PayMethod(item.method)}}</td>
                        <td style="text-align: right">{{Util.Date(item.created_at, 'HH:mm DD/MM/YYYY')}} <i class="icon icon-arrow-right-o c4 ml5"></i></td>
                    </tr>
                </table>

            </div>
        </div>

        <!-- vista antigua -->
        <div slot="Resumen" v-if="modalTurn && oldView" class="pa20 copyable">
            <div  v-if="modalTurn.ended_at || showSummary">
                
                <table class="summary">
                    <tr><th>Inicio</th><th>Fin</th></tr>
                    <tr><td>{{Util.Date(modalTurn.started_at, 'HH:mm DD/MM/YYYY')}}</td><td>{{modalTurn.ended_at ? Util.Date(modalTurn.ended_at, 'HH:mm DD/MM/YYYY') : 'Activo'}}</td></tr>
                </table>

                <table class="summary mt10" v-if="modalTurn.role !== 'delivery'">
                    <tr>
                        <th v-if="cashierTurn">Efectivo Inicial</th>
                        <th>Ingresos</th>
                        <th v-if="cashierTurn">Egresos</th>
                        <th v-if="cashierTurn">Balance</th>
                    </tr>
                    <tr>
                        <td v-if="cashierTurn">{{Util.Price(modalTurn.initial_cash)}}</td>
                        <td>{{Util.Price(modalTurn.total_income)}}</td>
                        <td v-if="cashierTurn">{{Util.Price(modalTurn.total_expense)}}</td>
                        <td v-if="cashierTurn">{{Util.Price(modalTurn.total_income - modalTurn.total_expense)}}</td>
                    </tr>
                </table>
            </div>

            <div v-if="modalTurn.comments.length && (showComments || modalTurn.ended_at)">

                <div class="f18 mb20 mt20">Notas y comentarios adicionales</div>

                <table class="summary">
                    <tr><th>Fecha</th><th>Mensaje</th></tr>
                    <tr v-for="(comment, index) in modalTurn.comments" :key="`comment${index}`"><td style="white-space: pre; padding-right: 5px">{{Util.Date(comment.timestamp, 'HH:mm DD/MM/YY')}}</td><td style="white-space: break-spaces; width: 82%;">{{comment.message}}</td></tr>
                </table>
            </div>

            <div class="" v-if="showMethods || modalTurn.ended_at">

                <div class="f18 mb20 mt20">Métodos de Pago</div>
                <table class="summary">
                    <tr>
                        <th>Método</th>
                        <th v-if="cashierTurn" class="pr5">{{mobile ? 'Pedidos' : 'Transacciones'}}</th>
                        <th v-if="cashierTurn" class="pr5">{{mobile ? 'Pagos' : 'Pagos Liquidados'}}</th>
                        <th v-if="cashierTurn" class="pr5">Gastos</th>
                        <th v-if="modalTurn.ghost_close" class="pr5">{{mobile ? 'Declar.' : 'Total Declarado'}}</th>
                        <th style="text-align: right">Total</th>
                    </tr>
                    <tr v-for="method in modalTurn.balance"
                    :key="method.data">

                        <td v-html="method.data == 'CASH' && cashierTurn ? 
                        `Efectivo <span class='secondary-text' style='font-size: inherit'>${!modalTurn.not_add_initial_cash ? '(' + Util.Price(modalTurn.initial_cash) + ')' : ''}</span>` : Util.PayMethod(method.data)" class="pr5"></td>
                        <td v-if="cashierTurn" class="pr5">{{Util.Price(method.transactions_income)}}</td>
                        <td v-if="cashierTurn" class="pr5">{{Util.Price(method.pending_payments_income)}}</td>
                        <td v-if="cashierTurn" class="pr5">{{Util.Price(method.total_expense)}}</td>
                        <td v-if="modalTurn.ghost_close" :class="'pr5 ' + (method.ghost_total < method.total ? 'cred' : (method.ghost_total !== method.total ? 'cyellow' : ''))">{{Util.Price(method.ghost_total)}} <i v-if="desktop" class="icon icon-circle-exclamation"></i></td>
                        <td style="text-align: right">{{Util.Price(method.total)}}</td>
                    </tr>
                </table>
            </div>
            <div class="" v-if="(showTransactions || modalTurn.ended_at)">

                <div class="f18 mb20 mt20">Transacciones</div>
                <table class="summary">
                    <tr>
                        <th>Número</th>
                        <th>Ingresada</th>
                        <th>Completada</th>
                        <th>Cancelada</th>
                        <th style="text-align: right">Total</th>
                    </tr>
                    <tr v-for="transaction in modalTurn.transactions"
                    :key="transaction.id"
                    class="selectable"
                    @click="$refs.transactionDetails.Open(transaction.id)">
                        <td>{{transaction.number.replace(/#0*/g, '#')}}</td>
                        <td><span v-if="transaction.created_at" :class="transaction.created_at == transaction.paid_at ? `cblue` : ''">{{Util.Date(transaction.created_at, 'HH:mm DD/MM/YY')}}</span></td>
                        <td><span v-if="transaction.completed_at" :class="transaction.completed_at == transaction.paid_at ? `cblue` : ''">{{Util.Date(transaction.completed_at, 'HH:mm DD/MM/YY')}}</span></td>
                        <td><span v-if="transaction.cancelled_at">{{Util.Date(transaction.cancelled_at, 'HH:mm DD/MM/YY')}}</span></td>
                        <td style="text-align: right">{{Util.Price(transaction.total_money)}}</td>
                    </tr>
                    <tr>
                        <td class="bold">Total</td>
                        <td>{{Util.Number(modalTurn.transactions_count.created)}}</td>
                        <td>{{Util.Number(modalTurn.transactions_count.completed)}}</td>
                        <td>{{Util.Number(modalTurn.transactions_count.cancelled)}}</td>
                        <td></td>
                    </tr>
                </table>
            </div>

            <div class="" v-if="(showEdit || modalTurn.ended_at) && modalTurn.role !== 'delivery'">

                <div class="f18 mb20 mt20">Transacciones Editadas</div>
                 <table class="summary">
                    <tr>
                        <th>Número</th>
                        <th>Editada</th>
                        <th>Comentario</th>
                    </tr>
                    <tr v-for="(revision, index) in modalTurn.edit"
                    :key="index"
                    class="selectable"
                    @click="$refs.transactionDetails.Open(revision.id)">
                        <td>{{revision.id_ticket.replace(/#0*/g, '#')}}</td>
                        <td>{{Util.Date(revision.created_at, 'HH:mm DD/MM/YYYY')}}</td>
                        <td>{{revision.comment}}</td>
                    </tr>
                </table>
            </div>

            <div class="" v-if="modalTurn.delivery && (showDelivery || modalTurn.ended_at)">
                
                <div class="f18 mb20 mt20">Reparto</div>
                <table class="summary">
                    <tr>
                        <th>Cuenta</th>
                        <th style="text-align: right">Total</th>
                    </tr>
                    <tr v-for="(entry, index) in StaffTable(modalTurn.delivery.internal.staff)"
                    :key="index+'delivery internal'"
                    class="selectable"
                    @click="$refs.transactionsList.Open(entry.transactions)">
                        <td :style="!entry.transactions.length ? {'padding-left': '15px'} : {}">{{entry.data}}</td>
                        <td style="text-align: right">{{Util.Price(entry.total)}}</td>
                    </tr>
                    <tr>
                        <td class="bold">Total Reparto</td>
                        <td style="text-align: right">{{Util.Price(modalTurn.delivery.internal.total)}}</td>
                    </tr>
                </table>
            </div>

            <div class="" v-if="modalTurn.delivery && (showDelivery || modalTurn.ended_at)">

                <div class="f18 mb20 mt20">Reparto Externo</div>
                <table class="summary">
                    <tr>
                        <th>Cuenta</th>
                        <th style="text-align: right">Total</th>
                    </tr>
                    <tr v-for="(entry, index) in StaffTable(modalTurn.delivery.external.staff)"
                    :key="index+'delivery external'"
                    class="selectable"
                    @click="$refs.transactionsList.Open(entry.transactions)">
                        <td :style="!entry.transactions.length ? {'padding-left': '15px'} : {}">{{entry.data}}</td>
                        <td style="text-align: right">{{Util.Price(entry.total)}}</td>
                    </tr>
                    <tr>
                        <td class="bold">Total Reparto Externo</td>
                        <td style="text-align: right">{{Util.Price(modalTurn.delivery.external.total)}}</td>
                    </tr>
                </table>
            </div>

            <div class="" v-if="showTips || modalTurn.ended_at">

                <div class="f18 mb20 mt20">Propinas</div>
                <table class="summary">
                    <tr>
                        <th>Cuenta</th>
                        <th style="text-align: right">Total</th>
                    </tr>
                    <tr v-for="(tip, index) in StaffTable(modalTurn.tips.staff)"
                    :key="index+'tip_staff'"
                    class="selectable"
                    @click="$refs.transactionsList.Open(tip.transactions)">
                        <td :style="!tip.transactions.length ? {'padding-left': '15px'} : {}">{{tip.data}}</td>
                        <td style="text-align: right">{{Util.Price(tip.total)}}</td>
                    </tr>
                    <tr>
                        <td class="bold">Total Propinas</td>
                        <td style="text-align: right">{{Util.Price(modalTurn.tips.total)}}</td>
                    </tr>
                </table>
            </div>

            <div class="" v-if="(showPendingPayments || modalTurn.ended_at) && modalTurn.role !== 'delivery'">

                <div class="f18 mb20 mt20">Pagos Pendientes Liquidados</div>
                <table class="summary">
                    <tr>
                        <th>Pago</th>
                        <th>Fecha</th>
                        <th>Método</th>
                        <th style="text-align: right">Total</th>
                    </tr>
                    <tr v-for="(pp, index) in modalTurn.pending_payments"
                    :key="index"
                    class="selectable"
                    @click="$refs.pendingPaymentDetails.Open(pp.id)">
                        <td>{{pp.data}}</td>
                        <td>{{Util.Date(pp.created_at, 'HH:mm DD/MM/YY')}}</td>
                        <td>{{Util.PayMethod(pp.method)}}</td>
                        <td style="text-align: right">{{Util.Price(pp.total_money)}}</td>
                    </tr>
                </table>
            </div>
            <div class="" v-if="modalTurn.invoices && (showInvoices || modalTurn.ended_at) && modalTurn.role !== 'delivery'">

                <div class="f18 mb20 mt20">Facturas de Inventario Liquidadas</div>
                <table class="summary">
                    <tr>
                        <th>Factura</th>
                        <th>Fecha</th>
                        <th>Método</th>
                        <th style="text-align: right">Total</th>
                    </tr>
                    <tr v-for="(invoice, index) in modalTurn.invoices"
                    :key="index"
                    class="selectable"
                    @click="$refs.invoiceDetails.Open(invoice.id)">
                        <td>{{invoice.data}}</td>
                        <td>{{Util.Date(invoice.created_at, 'HH:mm DD/MM/YY')}}</td>
                        <td>{{Util.PayMethod(invoice.method)}}</td>
                        <td style="text-align: right">{{Util.Price(invoice.total_money)}}</td>
                    </tr>
                </table>
            </div>
            <div class="" v-if="modalTurn.expenses && (showExpenses || modalTurn.ended_at) && modalTurn.role !== 'delivery'">

                <div class="f18 mb20 mt20">Gastos</div>
                <table class="summary">
                    <tr>
                        <th>Gasto</th>
                        <th>Fecha</th>
                        <th>Método</th>
                        <th style="text-align: right">Total</th>
                    </tr>
                    <tr v-for="(expense, index) in modalTurn.expenses"
                    :key="index"
                    class="selectable"
                    @click="expense.type == 'expense' ? $refs.expenseDetails.Open(expense.id) : $refs.inventoryDetails.Open(expense.id)">
                        <td>{{expense.data}}</td>
                        <td>{{Util.Date(expense.created_at, 'HH:mm DD/MM/YY')}}</td>
                        <td>{{Util.PayMethod(expense.method)}}</td>
                        <td style="text-align: right">{{Util.Price(expense.total_money)}}</td>
                    </tr>
                </table>
            </div>
            <div class="" v-if="modalTurn.items && (showItems || modalTurn.ended_at) && modalTurn.role !== 'delivery'">

                <div class="f18 mb20 mt20">Items</div>
                <table class="summary">
                    <tr>
                        <th>Item</th>
                        <th>Cantidad</th>
                        <th style="text-align: right">Total</th>
                    </tr>
                    <tr v-for="(item, index) in modalTurn.items"
                    :class="item.transactions ? 'selectable' : ''"
                    @click="item.transactions ? $refs.transactionsList.Open(item.transactions) : null"
                    :key="index">
                        <td :style="{'padding-left': item.total === null ? '15px' : ''}">{{item.title}}</td>
                        <td>{{Util.Number(item.quantity)}}</td>
                        <td style="text-align: right">{{item.total !== null ? Util.Price(item.total) : ''}}</td>
                    </tr>
                </table>
            </div>
            <div class="" v-if="modalTurn.inventory && (showInventory || modalTurn.ended_at) && modalTurn.role !== 'delivery'">
                
                <div class="f18 mb20 mt20">Inventario</div>
                <table class="summary">
                    <tr>
                        <th>Item</th>
                        <th>Agregado</th>
                        <th>Utilizado</th>
                        <th style="text-align: right">Final</th>
                    </tr>
                    <tr v-for="(item, index) in modalTurn.inventory.used"
                    :key="index">
                        <td>{{item.title}}</td>
                        <td>{{Util.Number(item.added)}} <span v-if="item.added_turn">({{Util.Number(item.added_turn)}})</span> <span v-if="item.unit" class="secondary-text">{{item.unit}}</span></td>
                        <td>{{Util.Number(item.used)}} <span v-if="item.used_turn && item.used_turn !== item.used">({{Util.Number(item.used_turn)}})</span> <span v-if="item.unit" class="secondary-text">{{item.unit}}</span><span class="secondary-text" v-if="!(item.used === 0 || item.unit_cost === 0)" v-tooltip="`Valorizado con costo unitario ${Util.Price(item.unit_cost)}`">{{` (${Util.Price(item.used * item.unit_cost)})` }}</span></td>
                        <td style="text-align: right">{{Util.Number(item.from)}} <span v-if="item.unit" class="secondary-text">{{item.unit}}</span> <i class="icon icon-arrow-right"></i> {{Util.Number(item.to)}} <span v-if="item.unit" class="secondary-text">{{item.unit}}</span></td>
                    </tr>
                </table>

                <div class="f18 mb20 mt20">Movimientos de Inventario</div>

                <table class="summary">
                    <tr style="text-align: right">
                        <th>Item</th>
                        <th>Cantidad</th>
                        <th>Total</th>
                        <th>Método</th>
                        <th style="text-align: right">Fecha</th>
                    </tr>
                    <tr v-for="(item, index) in modalTurn.inventory.transactions"
                    :key="index"
                    class="selectable"
                    @click="$refs.inventoryDetails.Open(item.id)">
                        <td>{{item.title}}</td>
                        <td>{{`${Util.Number(item.quantity)}`}} <span v-if="!(item.used === 0 || item.unit_cost === 0)" class="secondary-text" v-tooltip="`Valorizado con costo unitario ${Util.Price(item.unit_cost)}`">{{  (`${Util.Price(item.quantity < 0 ? (item.quantity * -1) * item.unit_cost : item.quantity * item.unit_cost)}`) }}</span></td>
                        <td>{{Util.Price(item.total_money)}}</td>
                        <td>{{Util.PayMethod(item.method)}}</td>
                        <td style="text-align: right">{{Util.Date(item.created_at, 'HH:mm DD/MM/YY')}}</td>
                    </tr>
                </table>

            </div>
        </div>
        
        <vertical-content slot="Historial" @OnBottom="LoadTurnHistory()" class="pa20">
            <div class="secondary-text ta-center mt10" v-if="!modalTurn.history.length">No hay datos</div>
            <div class="">
                <div :class="`flex entry ${ActiveHistory(entry) ? 'selectable' : ''}`"
                @click="OpenHistory(entry)"
                v-for="(entry, index) in modalTurn.history"
                :key="index">
                    <avatar class="mr10"
                    :icon="Util.LogTypes[entry.type] ? Util.LogTypes[entry.type].icon : 'circle-exclamation'"
                    :secondaryIcon="Util.LogTypes[entry.type] ? Util.LogTypes[entry.type].secondary : ''"
                    :color="Util.LogTypes[entry.type] ? Util.LogTypes[entry.type].color : ''">
                    </avatar>
                    <div>
                        <div class="bold">{{Util.Date(entry.date, 'HH:mm DD/MM/YYYY')}}</div>
                        <div class="flex-fill secondary-text">{{entry.title}}</div>    
                    </div>
                    <!--
                    <div class="flex-fill flex align-center">
                        
                        <i v-if="ActiveHistory(entry)" class="icon icon-arrow-right-o c4"></i>
                    </div>
                    -->
                </div>
            </div>
        </vertical-content>
    </tabs>

    <transaction-details ref="transactionDetails" @OnTransactionDeleted="Open(modalTurn.id)"></transaction-details>
    <transactions-list ref="transactionsList" @OnTransactionDeleted="Open(modalTurn.id)"></transactions-list>
    <expense-details ref="expenseDetails" @OnTransactionDeleted="Open(modalTurn.id)"></expense-details>
    <inventory-details ref="inventoryDetails" @OnTransactionDeleted="Open(modalTurn.id)"></inventory-details>
    <pending-payment-details ref="pendingPaymentDetails"></pending-payment-details>
    <invoice-details ref="invoiceDetails"></invoice-details>

</modal>
</template>
<script>
export default {
    props: {
        showSummary: {
            type: Boolean,
            default: true
        },
        showComments: {
            type: Boolean,
            default: true
        },
        showMethods: {
            type: Boolean,
            default: true
        },
        showTransactions: {
            type: Boolean,
            default: true
        },
        showEdit: {
            type: Boolean,
            default: true
        },
        showDelivery: {
            type: Boolean,
            default: true
        },
        showTips: {
            type: Boolean,
            default: true
        },
        showPendingPayments: {
            type: Boolean,
            default: true
        },
        showInvoices: {
            type: Boolean,
            default: true
        },
        showExpenses: {
            type: Boolean,
            default: true
        },
        showItems: {
            type: Boolean,
            default: true
        },
        showInventory: {
            type: Boolean,
            default: true
        },
    },
    data() {
        return {
            modalTurn: null,
            tabs: null,
            oldView: true
        }
    },
    mounted() {
        this.tabs = [];
        this.tabs.push('Resumen');
        if(this.Auth.role.indexOf('admin') > -1) this.tabs.push('Historial');
    },
    methods: {
        Open(id) {
            if(typeof id == 'number')
            {
                this.Loading();
                axios.get(`/turn/${id}`).then(res => {
                    this.Response(res);
                    res.data.history = [];
                    this.modalTurn = res.data;
                }).catch(err => {
                    this.Response(err);
                });
            }
            else
            {
                id.history = [];
                this.modalTurn = id;
            }
        },
        LoadTurnHistory() {
            this.Loading();
            axios.get(`/turn/${this.modalTurn.id}/history${this.modalTurn.history.length?'/'+this.modalTurn.history[this.modalTurn.history.length-1].id:''}`).then(res => {
                this.Response(res);
                this.modalTurn.history = this.modalTurn.history.concat(res.data);
            }).catch(err => {
                this.Response(err);
            });
        },
        Export()
        {
            this.Loading();
            axios.get(`/turn/${this.modalTurn.id}/export`, {
                responseType: 'blob',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/pdf'
                }
            }).then(res => {
                    this.Response(res);
                    this.Util.DownloadFile(res, `turno_${this.modalTurn.name.toLowerCase()}_${this.Util.Date(this.modalTurn.started_at, 'DD-MM-YYYY')}.pdf`);
            }).catch(err => {
                this.Response(err);
            });
        },
        ActiveHistory(log) {
            if(log.type.indexOf('TRANSACTION') >= 0)
                return true;
            else if(log.type.indexOf('EXPENSE') >= 0)
                return true;
            else if(log.type.indexOf('INVENTORY') >= 0)
                return true;
            else if(log.type.indexOf('PENDING_PAYMENT') >= 0)
                return true;
            return false;
        },
        OpenHistory(log) {
            if(log.type.indexOf('TRANSACTION') >= 0)
                this.$refs.transactionDetails.Open(log.id_reference);
            else if(log.type.indexOf('EXPENSE') >= 0)
                this.$refs.expenseDetails.Open(log.id_reference);
            else if(log.type.indexOf('INVENTORY') >= 0)
                this.$refs.inventoryDetails.Open(log.id_reference);
            else if(log.type.indexOf('PENDING_PAYMENT') >= 0)
                this.$refs.pendingPaymentDetails.Open(log.id_reference);
        },
        Print()
        {
            var moment = require('moment');
            var arr = [];
            arr.push({text: this.Local.name, align: 'CT', width: 2, height: 2});
            arr.push({text: `Turno de ${this.modalTurn.name}`});
            arr.push({text: `Inicio: ${moment.unix(this.modalTurn.started_at).format('HH:mm DD/MM/YYYY')}`});
            arr.push({text: `Fin: ${this.modalTurn.ended_at ? moment.unix(this.modalTurn.ended_at).format('HH:mm DD/MM/YYYY') : 'Activo'}`});
            if(this.cashierTurn) 
            {
                arr.push({text: ['Efectivo inicial', this.Util.Number(this.modalTurn.initial_cash)]});
                arr.push({line: true});
                arr.push({text: ['Total ingresos', this.Util.Number(this.modalTurn.total_income)]});
                arr.push({text: ['Total egresos', this.Util.Number(this.modalTurn.total_expense)]});
            }
            arr.push({line: true});
            arr.push({text: 'Transacciones'});
            arr.push({text: ['> Ingresadas', this.modalTurn.transactions_count.created]});
            arr.push({text: ['> Completadas', this.modalTurn.transactions_count.completed]});
            arr.push({text: ['> Canceladas', this.modalTurn.transactions_count.cancelled]});

            arr.push({line: true});
            arr.push({text: 'Total por Metodos de Pago'});
            for(var i = 0; i < this.modalTurn.balance.length; i++)
            {
                var method = this.modalTurn.balance[i];
                var total = (method.data == 'CASH' ? (!this.modalTurn.not_add_initial_cash ? this.modalTurn.initial_cash : 0 ): 0) + method.pending_payments_income + method.transactions_income - method.total_expense;
                arr.push({text: [`> ${this.Util.PayMethod(method.data)}`, this.Util.Number(total)]});
            }

            if(this.modalTurn.delivery)
            {
                if(this.modalTurn.delivery.internal.total)
                {
                    arr.push({line: true});
                    arr.push({text: ['Total reparto interno', this.Util.Number(this.modalTurn.delivery.internal.total)]});
                    this.modalTurn.delivery.internal.staff.forEach(delivery => {
                        arr.push({text: [`> ${delivery.account}`, this.Util.Number(delivery.total)]})
                    });
                }
                if(this.modalTurn.delivery.external.total)
                {
                    arr.push({line: true});
                    arr.push({text: ['Total reparto externo', this.Util.Number(this.modalTurn.delivery.external.total)]});
                    this.modalTurn.delivery.external.staff.forEach(delivery => {
                        arr.push({text: [`> ${delivery.account}`, this.Util.Number(delivery.total)]})
                    });
                }
            }

            if(this.modalTurn.tips)
            {
                if(this.cashierTurn)
                {
                    arr.push({line: true});
                    arr.push({text: ['Total propinas', this.Util.Number(this.modalTurn.tips.total)]});
                    this.modalTurn.tips.staff.forEach(tip => {
                        arr.push({text: [`> ${tip.account}`, this.Util.Number(tip.total)]})
                    });
                }
                else
                {
                    this.modalTurn.tips.staff.forEach(tip => {
                        if(tip.account == this.modalTurn.name)
                        {
                            arr.push({line: true});
                            arr.push({text: ['Total propinas', this.Util.Number(tip.total)]});
                            for(var key in tip.methods)
                            {
                                arr.push({text: [`> ${this.Util.PayMethod(key)}`, this.Util.Number(tip.methods[key].total)]})
                            }
                        }
                    });
                }
            }

            

            this.Util.NotifyService('print', {data: arr, id: Math.random().toString()});

        },
        PrintInventory()
        {
            var moment = require('moment');
            var arr = [];
            arr.push({text: this.Local.name, align: 'CT', width: 2, height: 2});
            arr.push({text: `Turno de ${this.modalTurn.name}`});
            arr.push({text: `Inicio: ${moment.unix(this.modalTurn.started_at).format('HH:mm DD/MM/YYYY')}`});
            arr.push({text: `Fin: ${this.modalTurn.ended_at ? moment.unix(this.modalTurn.ended_at).format('HH:mm DD/MM/YYYY') : 'Activo'}`});
            arr.push({line: true});
            arr.push({text: 'Inventario'});
            
            this.modalTurn.inventory.used.forEach(inventory => {
                var unit = inventory.unit ? ` ${inventory.unit}` : ''; 
                arr.push({text: [inventory.title, `${this.Util.Number(inventory.from) + unit} > ${this.Util.Number(inventory.to) + unit}`]});
                arr.push({text: '- Agregado: ' + this.Util.Number(inventory.added) + unit});
                arr.push({text: '- Utilizado: ' + this.Util.Number(inventory.used) + unit});
            });

            this.Util.NotifyService('print', {data: arr, id: Math.random().toString()});

        },
        StaffTable(table)
        {
            var ret = [];

            table.forEach(entry => {
                ret.push({data: entry.account, total: entry.total, transactions: entry.id_transactions});
                for(var key in entry.methods)
                {
                    ret.push({data: ` > ${this.Util.PayMethod(entry.methods[key].method)}`, total: entry.methods[key].total, transactions: []});
                }
            });

            return ret;
        }
    },
    computed: {
        cashierTurn() {
            if(!this.modalTurn) return false;
            return ['waiter', 'delivery'].indexOf(this.modalTurn.role) == -1;
        }
    }
}
</script>
<style lang="scss">
//Mobile
@media (max-width: 820px) {
    .icon-small-mobile {
        position: relative;
        .avatar {
            background: transparent !important;
            position: absolute;
            left: 2px;
            top: 3px;
            transform: scale(0.7);
        }
    }
}
</style>