<template>
<div class="flex-fill fill flex column ofy">

<div class="opt r">

    <input-field v-if="tab == 'Actual'" inputClass="secondary"
    v-model="search"
    placeholder="Buscar..."
    pre="search"
    cleareable
    :icon="mobile ? 'search' : ''"
    :timeout="200"
    useHeader>
    </input-field>

    <input-select v-model="orderBy" ref="orderBy" v-if="tab == 'Actual'" 
    class="ml5" inputClass="secondary"
    :items="orderByOptions"
    :icon="mobile ? 'sort' : ''">
    </input-select>

    <input-select v-model="orderSpace" v-if="tab == 'Actual'" 
    class="ml5" inputClass="secondary"
    :items="spaces"
    :icon="mobile ? 'filter' : ''">
    </input-select>

    <input-range inputClass="secondary"
    v-show="tab == 'Merma'"
    class="ml5"
    v-model="rangeWaste"
    :icon="mobile ? 'calendar' : ''"
    @OnValueChanged="tab == 'Merma' ? SearchItemsWaste() : null"
    >
    </input-range>
    
    <input-range inputClass="secondary"
    v-show="tab == 'Resumen'"
    class="ml5"
    v-model="range"
    :icon="mobile ? 'calendar' : ''"
    @OnValueChanged="tab == 'Resumen' ? Search() : null">
    </input-range>

    <input-field  v-if="tab == 'Proveedores'" class="ml5"
    v-model="searchProvider"
    placeholder="Buscar..."
    pre="search"
    clearable
    inputClass="secondary"
    :icon="mobile ? 'search' : ''"
    useHeader>
    </input-field>

    <input-date :icon="mobile ? 'calendar' : null" 
        inputClass="secondary" 
        v-show="tab == 'Historial'"
        type="DATE"
        format="timestamp"
        v-model="dateFilter"
        :toEndDay="true"
        modalOnly
        @OnAcceptDate="LoadHistory()">
    </input-date>

    <i class="options icon-dots-vertical ml5" @click="Options([
        //{text: 'Reset Inventario', class: 'error-color', if: Auth.role.indexOf('admin') > -1, confirm: `¿Desea resetear el stock de inventario?`, function: () => {ResetStock()} },
        {text: 'Reset Inventario', class: 'error-color', if: Auth.role.indexOf('admin') > -1, function: () => {Options([
            {text: 'Reset Items', class: 'error-color', confirm: `¿Desea borrar TODOS los items de inventario?`, function: () => {ResetStock(true)}},
            {text: 'Reset Stock', class: 'error-color', confirm: `¿Desea resetear el stock de inventario?`, function: () => {ResetStock()}},
        ])} },
        {text: 'Transferir Inventario', function: () => {ClickModalAddStockTransfer('TF')}, if: Local.type === 'FRANCHISE'},
        {text: 'Agregar Inventario', function: () => {ClickModalAddStockTransfer('AD')}},
        {text: 'Ver Valor Disponible', class: 'selected-color', function: () => {ShowModalInventoryValueAvailable()}},
        {text: 'Imprimir', function: () => {Print()}, if: !!Local.modules.module_service},
        {text: 'Exportar', function: () => {Export()}, if: Auth.role.indexOf('admin') > -1},
        {text: 'Importar', function: () => {ModalImportInventory = {}}, if: Auth.role.indexOf('admin') > -1},
        //{text: 'Generar Inventario', if: Auth.role.indexOf('admin') > -1, function: () => {GenerateInventory()}},
        {text: 'Crear Item', class: 'selected-color', if: Auth.role.indexOf('admin') > -1, function: () => {modalNew={performance: 100, section: 'Otros', space: 'General'}; subItems = []}},
    ])"
    v-show="tab=='Actual'"></i>

    <i class="options icon-dots-vertical ml5" v-if="Auth.role.indexOf('admin') > -1" @click="Options([
        {text: 'Exportar', class: 'selected-color', function: () => { ExportWaste() } },
    ])"
    v-show="tab=='Merma'"></i>

    <i class="options icon-dots-vertical ml5" v-if="canCreateProvider" @click="Options([
        {text: 'Crear Proveedor', class: 'selected-color', function: () => {modalProvider={}}, if: canCreateProvider},
    ])"
    v-show="tab=='Proveedores'"></i>

    <i class="options icon-dots-vertical ml5" @click="Options([
        {text: 'Exportar', class: 'selected-color', function: () => { ExportResumen() }, if: Auth.role.indexOf('admin') > -1 },
    ])"
    v-show="tab=='Resumen'"></i>
</div>


<tabs :tabs="tabs" :keyByTab="true"  v-if="tabs"
v-model="tab"
@OnTabHistorial="LoadHistory()"
@OnTabResumen="Search()"
@OnTabProveedores="CheckPendingInvoices"
@OnTabMerma="SearchItemsWaste()">

<!-- <i class="icon icon-datetime show-mobile" slot="options Resumen" @click="$refs.menuSearch.Open()"></i> -->

<div slot="Actual" class="pa-body">
    <div class="secondary-text ta-center mt10" v-if="!currentInventory.length">No hay items</div>
    <div class="mb20" v-for="section in currentInventory" :key="section.title">
        <div class="bgbg mb10 mt10 f18 mb10 flex">
            <div class="flex-fill">{{section.title}}</div>
            <div class="options icon-dots-vertical" v-if="Auth.role.indexOf('admin') > -1" @click="Options([
                {text: 'Crear Item', class: 'selected-color', function: () => {{modalNew={performance: 100, section: section.title, space: orderSpace}; subItems = []}}},
            ])"></div>
        </div>
        <div class="secondary-text ta-center mt10" v-if="!section.items.length">No hay items</div>
        
        <div :class="`${item.quantity <= 0 ? 'cred' : ''} section selectable mb10`" 
        v-for="item in section.items"
        :key="item.title"
        @click="isAdmin ? ItemStats(item) : Options([
            {text: `Enviar ${item.title} a merma `, class: 'error-color'/* , if: (!item.subitems || item.subitems.length == 0) */, function: () => {modalAdd={item: item, unit_cost: String(item.unit_cost), waste: true, comment: 'Sin definir'}}, if: !!Auth.restrictions.reduce_inventory},
            {text: `Agregar ${item.title}`, class: 'selected-color'/* ,  if: !item.subitems || item.subitems.length == 0 */, function: () => { modalAdd={item: item, unit_cost: item.unit_cost? String(item.unit_cost) : null} }, if: !!Auth.restrictions.create_inventory},
            {text: `Transferir ${item.title}`, if: (item.quantity > 0 && (Local.type == 'FRANCHISE' || spaces.length > 1) && !!Auth.restrictions.reduce_inventory && !!Auth.restrictions.create_inventory) , function: () => { OpenModalTransferItem(item) }},
        ])">
            <div class="flex fill align-center">

                <avatar class="mr10"
                :imageIcon="item.title"
                defaultImageIcon="799"
                :color="ItemColor(item)"
                :secondaryIcon="item.subitems && item.subitems.length > 0? 'list' : ''">
                </avatar>

                <div class="flex-fill flex column">
                    <span class="bold">{{item.title}}</span>
                    <span class="secondary-text" v-if="(item.subitems && item.subitems.length > 0)"> 
                        Componentes:
                        <span v-for="(subitem, index) in item.subitems" :key="index">
                            {{
                            (index != item.subitems.length-1)
                                ? `${subitem.quantity} ${subitem.title}, ` 
                                : `${subitem.quantity} ${subitem.title}.`
                            }}
                        </span> 
                    </span>
                </div>
                <div class="flex column" style="text-align: right">
                    <div v-tooltip="`Cantidad disponible con ${item.performance}% rendimiento aplicado`">
                        <span class="bold">{{Util.Number(item.quantity * item.performance / 100)}}</span>
                        <span v-if="item.unit" class="secondary-text ml5">{{item.unit}}</span>
                    </div>
                    <div v-if="item.performance !== 100" v-tooltip="`Cantidad real disponible (sin rendimiento)`">
                         <span class="secondary-text">{{Util.Number(item.quantity)}}<span v-if="item.unit" class="secondary-text ml5">{{item.unit}}</span></span>
                    </div>
                </div>
                <!-- <div class="icon icon-arrow-right-o ml10 c4"></div> -->
                <i v-if="isAdmin" class="options icon-dots-vertical ml10" @click.stop="Options([
                    {text: 'Eliminar', class: 'error-color', function: () => {DeleteItem(item)}, confirm: `¿Eliminar ${item.title} del inventario?`},
                    {text: `Enviar ${item.title} a merma `/* , if: (!item.subitems || item.subitems.length == 0) */ ,class: 'error-color', function: () => {modalAdd={item: item, unit_cost: String(item.unit_cost), waste: true, comment: 'Sin definir'}}},
                    {text: `Transferir ${item.title}`, if: (item.quantity > 0 && (Local.type == 'FRANCHISE' || spaces.length > 1) ) , function: () => { OpenModalTransferItem(item) }},
                    {text: 'Editar', function: () => { modalEdit={ title: item.title, unit: item.unit, performance: item.performance ? item.performance : 100, unit_cost: item.unit_cost, section: item.section ? item.section : 'Otros', space: item.space ? item.space : 'General'}; subItems= item.subitems? Util.Copy(item.subitems) : [] }},
                    {text: `Agregar ${item.title}`, class: 'selected-color'/* , if: !item.subitems || item.subitems.length == 0 */ ,function: () => { modalAdd={item: item, unit_cost: item.unit_cost? String(item.unit_cost) : null} } },
                ])"></i>
            </div>
        </div>

    </div>


</div>

<div slot="Merma" class="pa-body">
    <div class="secondary-text ta-center mt10" v-if="!itemsWaste">No hay items en merma</div>
    <div class="mb20">

        <!--<div class="bgbg mb10 mt10 f18 mb10">Productos en merma</div>-->
        <div class="secondary-text ta-center mt10" v-if="itemsWaste && itemsWaste.count <= 0">No hay items</div>
        
        <div v-if="itemsWaste && itemsWaste.count > 0">
            <div class="hdvm mb20">
                <div>
                    <div class="section flex align-center h100">
                        <avatar icon="money" class="mr10" :color="Util.baseColors.red"></avatar>
                        <div class="bold flex-fill">Total</div>
                        <div class="f18">{{Util.Price(itemsWaste.total_money)}}</div>
                    </div>
                </div>

                <div>
                    <div class="section h100">
                        <div class="scroll-container" style="max-height: 150px">
                            <div class="secondary-text ta-center" v-if="!itemsWaste || itemsWaste.reasons.length == 0">No hay motivos</div>
                            <div v-for="(reason, index) in itemsWaste.reasons" :key="index" class="flex mb5">
                                <badge :count="reason.count" color="blue" class="mr10" :round="false"></badge>
                                <span>{{index}}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!--<div class="hdvm mb20">
                <div class="section h100">
                    <div class="secondary-text ta-center" v-if="!this.itemsWaste && this.itemsWaste.total_total_reasons <= 0">No hay razones o motivos</div>
                    <div v-for="(val, tag) in tags" :key="tag" class="flex mb5">
                        <badge :count="val" color="blue" class="mr10" :round="false"></badge>
                        <span>{{tag}}</span>
                    </div>
                </div>
            </div>-->


            <div class="section selectable mb10" 
            @click="modalItemWaste=item"
            v-for="item in itemsWaste.items"
            :key="item.data">
                <div class="flex fill align-center">

                    <avatar class="mr10"
                    :imageIcon="item.title"
                    defaultImageIcon="799"
                    color="#ff495b">
                    </avatar>

                    <div class="flex-fill">
                        <span class="bold">{{item.data}}</span>
                    </div>
                    <div class="flex column" style="text-align: right">
                        <div class="bold">{{Util.Price(item.total_money ? item.total_money : 0)}}</div>
                        <div class="cred">
                            <span class="bold">{{Util.Number(item.count * item.performance / 100)}}</span>
                            <span v-if="item.unit" class="secondary-text ml5">{{item.unit}}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div slot="Resumen" v-if="stats" class="pa-body">

    <div class="hdvm mb20">
        
        <div>
            <div class="section flex align-center">
                <avatar class="mr10"
                icon="money"
                :color="Util.baseColors.red">
                </avatar>
                <div class="flex-fill">
                    <div class="bold">Total</div>
                    <div class="secondary-text">Gastado</div>
                </div>
                <div class="f18 cred">{{Util.Price(stats.total_money)}}</div>
            </div>
        </div>

        <div>
            <div class="section flex align-center">
                <avatar class="mr10"
                icon="box"
                secondaryIcon="plus"
                :color="Util.baseColors.green">
                </avatar>
                <div class="flex-fill">
                    <div class="bold">Igresado</div>
                    <div class="secondary-text">Transacciones</div>
                </div>
                <div class="f18 cgreen">{{Util.Number(stats.transactions_added.length)}}</div>
            </div>
        </div>

        <div>
            <div class="section flex align-center">
                <avatar class="mr10"
                icon="box"
                secondaryIcon="minus"
                :color="Util.baseColors.red">
                </avatar>
                <div class="flex-fill">
                    <div class="bold">Utilizado</div>
                    <div class="secondary-text">Transacciones</div>
                </div>
                <div class="">
                    <div class="bold cred ta-right">{{Util.Number(stats.transactions_used.length)}} </div> 
                    <span class="secondary-text" v-tooltip="'Total valorizado'">({{ Util.Price(CalculateSumUsed(stats.items)) }})</span>
                </div>
            </div>
        </div>
    </div>

    
    
    <div class="">
        <div class="flex align-center mb20">
            <div class="f18 flex-fill">Items</div>
            <input-field
            inputClass="secondary"
            v-model="searchResumen"
            placeholder="Buscar..."
            pre="search"
            cleareable
            :icon="mobile ? 'search' : ''"
            :timeout="200">
            </input-field>
        </div>

        <div class="section selectable mb10"
        v-for="(item, index) in filteredItemResumen"
        :key="index"
        @click="ItemResumenHistory(item)">
            <div class="fill flex align-center">
                <avatar class="mr10"
                :imageIcon="item.data"
                defaultImageIcon="799"
                :color="item.from < item.to ? Util.baseColors.red : Util.baseColors.green">
                </avatar>
                <div :class="`flex flex-fill ${mobile ? 'column' : ''}`">
                    <div class="flex-fill flex column">
                        <div class="bold">{{item.data}}</div>
                        <div class="secondary-text"><span class="bold">Total gastado: </span>{{Util.Price(item.total_money)}}</div>
                        <div class="secondary-text"><span class="bold">Total ingresado: </span>{{Util.Number(parseFloat(item.total_added))}} <span class="secondary-text" v-if="inventory[item.data] && inventory[item.data].unit">{{inventory[item.data].unit}}</span></div>
                        <div class="secondary-text"><span class="bold">Total utilizado: </span>{{Util.Number(parseFloat(item.total_used))}} <span class="secondary-text" v-if="inventory[item.data] && inventory[item.data].unit">{{inventory[item.data].unit}} </span> <span v-tooltip="`Valorizado con costo unitario ${Util.Price(inventory[item.data].unit_cost)}`" class="secondary-text" v-if="inventory[item.data] && inventory[item.data].unit_cost && !(item.total_used === 0 || inventory[item.data].unit_cost === 0)">({{ Util.Price(item.total_used * inventory[item.data].unit_cost) }}) </span></div>
                    </div>
                    <div class="flex align-center" v-if="item.from != item.to">
                        <div><span class="bold">{{Util.Number(parseFloat(item.from))}}</span> <span class="secondary-text ml5" v-if="inventory[item.data] && inventory[item.data].unit">{{inventory[item.data].unit}}</span></div>
                        <i class="icon icon-arrow-right ml10 mr10 c4"></i>
                        <div><span class="bold">{{Util.Number(parseFloat(item.to))}}</span> <span class="secondary-text ml5" v-if="inventory[item.data] && inventory[item.data].unit">{{inventory[item.data].unit}}</span></div>
                    </div>
                </div>
            </div>
        </div>
        <div class="secondary-text ta-center mt10" v-if="!stats.items.length">No hay datos.</div>
    </div>
</div>

<vertical-content ref="verticalHistory" slot="Historial" @OnBottom="LoadHistory(true)" class="pa-body">
    <div class="" v-if="history.length > 0">
        <div class="section mb10 selectable"
        v-for="(transaction, index) in history"
        :key="index"
        @click="$refs.inventoryDetails.Open(transaction)">
            <div class="fill flex align-center">
                <avatar class="mr10"
                :imageIcon="transaction.item"
                defaultImageIcon="799"
                :color="transaction.quantity < 0 ? Util.baseColors.red : Util.baseColors.green">
                </avatar>
                <div class="flex-fill flex column">
                    <div :class="`bold ${transaction.quantity < 0 ? 'cred' : ''}`"><span v-if="transaction.quantity > 0">+</span>{{Util.Number(parseFloat(transaction.quantity))}} <span class="secondary-text thin" v-if="inventory[transaction.item] && inventory[transaction.item].unit">{{inventory[transaction.item].unit}}</span> {{transaction.item}}</div>
                    <div class="secondary-text" v-if="transaction.comment"><span class="bold">Comentario:</span> {{transaction.comment}}</div>
                    <!-- <div class="secondary-text">{{transaction.quantity_from}} <i class="icon icon-arrow-right"></i> {{transaction.quantity_to}}</div> -->
                    <!-- <div class="secondary-text" v-if="transaction.id_transaction">Utilizado en el pedido X</div> -->
                    <div class="secondary-text" v-if="transaction.custom_data.created_by"><span class="bold">Ingresado por:</span> {{transaction.custom_data.created_by}}</div>
                    <div class="secondary-text"><span class="bold">Fecha:</span> {{Util.Date(transaction.date, 'DD/MM/YYYY HH:mm')}}</div>
                </div>
                <div class="flex column" style="text-align: right" v-if="transaction.money">
                    <div class="bold">{{Util.Price(transaction.money)}}</div>
                    <div class="secondary-text">{{Util.PayMethod(transaction.pay_method)}}</div>
                </div>
                <!--<i class="icon icon-arrow-right-o c4 ml10"></i>-->
                
            </div>
        </div>
    </div>
    <div v-else class="secondary-text mt10 ta-center">No hay datos.</div>
</vertical-content>

<div slot="Proveedores">
    <div class="pa-body">
        <div class="secondary-text ta-center mt10" v-if="!visibleProviders.length">No hay proveedores</div>

        <div class="section mb10 selectable" 
        v-for="provider in visibleProviders"
        :key="provider.rut"
        @click="OpenProviderStats(provider.rut)">
            <div class="flex fill align-center">
                <avatar class="mr10"
                :label="provider.name"
                :color="pendingInvoices[provider.rut] ? Util.baseColors.red : Util.baseColors.blue">
                </avatar>
                <div class="flex-fill flex column">
                    <div class="bold">{{provider.name}}</div>
                    <div class="secondary-text" v-if="provider.company">{{provider.company}}</div>
                    <div class="secondary-text">{{Util.Rut(provider.rut)}}</div>
                </div>

                <div class="cred" v-if="pendingInvoices[provider.rut]">{{pendingInvoices[provider.rut]}} pendientes</div>
                
                <i class="options icon-dots-vertical ml10" @click.stop="Options([
                    {text: 'Eliminar Proveedor', class: 'error-color', function: () => {DeleteProvider(provider.rut)}, confirm: `¿Eliminar proveedor?`, if: canDeleteProvider},
                    {text: 'Editar proveedor', function: () => {modalProvider = Util.Copy(provider, {edit: true})}, if: canEditProvider},
                    {text: 'Registrar factura', class: 'selected-color', function: () => {modalInvoice = {inventory: [], provider: provider}}, if: canCreateInvoices},
                ])"></i>
            </div>
        </div>

    </div>
</div>

</tabs>

<!--
<side-menu ref="menuSearch" position="bottom" size="auto" @OnOpen="Util.PlaceElement($refs.range.$el, $refs.menuSearchParent)">
    <div class="side-menu" ref="menuSearchParent">
        <h4>Rango de tiempo</h4>
    </div>
</side-menu>
-->

<modal v-model="ModalImportInventory" :dir="['right', 'center']" :modalClass="['fill borderless', 'sm']" title="Importar inventario">
    <div class="section" v-if="ModalImportInventory">
        <div class="secondary-text ta-center">Seleccionar un archivo Excel con el formato de inventario.</div>
    </div> 
    
    <button-file v-if="ModalImportInventory" accept=".xlsx, .xls, .csv"
    :type="null" slot="actions"
    @FileSelected="$set(ModalImportInventory, 'file', $event)">
        <button class="secondary">{{ModalImportInventory.file ? ModalImportInventory.file.name : 'Seleccionar Archivo'}}</button>
    </button-file>
    <button slot="actions" class="primary purple" @click="ImportInventory()">Importar</button>
</modal>

<!-- AQUI -->
<modal v-model="modalAdd" dir="right" :modalClass="['fill', 'md right h100']" :title="modalAdd? TitleProduct(modalAdd) : ''">
    <div class="section" v-if="modalAdd">

        <input-select v-if="providersItems && !modalAdd.waste" class="mb15"
        v-model="modalAdd.provider" 
        :items="providersItems" 
        label="Proveedor"
        canType="search">
        </input-select>

        <input-field class="mb15"
        v-model="modalAdd.add"
        label="Cantidad"
        :suffix="` ${modalAdd.item.unit? modalAdd.item.unit : ''}`"
        type="float+"
        @OnChangeFocused="QuantityChanged($event, modalAdd)">
        </input-field>

        <label class="mb15" v-if="modalAdd.waste">
            <input type="checkbox" v-model="modalAdd.addCost"> Agregar costo (será ingresado como egreso) <div class="checkmark"></div>
        </label>

        <div class="hs mb15" v-show="!modalAdd.waste || modalAdd.addCost">
            <input-field 
            v-model="modalAdd.unit_cost"
            label="Costo Unitario"
            placeholder="(Opcional)"
            type="float"
            prefix="$"
            :readonly="Auth.role == 'cashier' && !Auth.restrictions.can_edit_cost_unit? true : false"
            @OnChangeFocused="UnitCostChanged($event, modalAdd)">
                <div class="append unshrink" slot="after" v-if="modalAdd.item.unit">pesos por {{modalAdd.item.unit}}</div>
            </input-field>

            <input-field
            v-model="modalAdd.cost"
            label="Costo Total"
            placeholder="(Opcional)"
            type="float"
            prefix="$"
            after=" "
            :readonly="Auth.role == 'cashier' && !Auth.restrictions.can_edit_cost_unit && !modalAdd.unit_cost? true : false"
            @OnChangeFocused="$event => CostChanged($event, modalAdd)">
                <i class="icon icon-cross selectable" v-tooltip="'Quitar'" 
                v-show="modalAdd.cost" slot="inner-after"
                @click="$set(modalAdd, 'cost', null);$set(modalAdd, Auth.role == 'cashier' && !Auth.restrictions.can_edit_cost_unit? 'add' : 'unit_cost', null)"></i>
            </input-field>
        </div>

        <input-select v-if="!modalAdd.waste" class="mb15"
        v-show="modalAdd.cost > 0 || modalAdd.unit_cost > 0"
        label="Método de Pago"
        v-model="modalAdd.pay_method"
        :items="payMethods"
        canType="search">
        </input-select>

        <input-field v-if="!modalAdd.waste" class="mb15"
        v-model="modalAdd.comment"
        label="Comentario"
        placeholder="(Opcional)">
        </input-field>

        <input-select v-if="modalAdd.waste" class="mb15"
        label="Motivo"
        v-model="modalAdd.comment"
        :items="reasonsWaste"
        :canType="true">
        </input-select>

        <!--<input-image v-model="modalAdd.image" label="Documento (Opcional)"></input-image>-->
        <input-files v-model="modalAdd.files" label="Documento (Opcional)"></input-files>

    </div>
    
    <div class="fill flex align-center" slot="actions" v-if="modalAdd">
        <div>{{Util.Number(parseFloat(modalAdd.item.quantity))}} <span v-if="modalAdd.item.unit" class="secondary-text">{{modalAdd.item.unit}}</span></div>
        <i v-if="modalAdd.add" class="icon icon-arrow-right ml10 mr10 c4"></i>
        <div v-if="modalAdd.add">{{Util.Number(parseFloat(modalAdd.item.quantity + (modalAdd.waste ? -modalAdd.add : modalAdd.add)))}} <span v-if="modalAdd.item.unit" class="secondary-text">{{modalAdd.item.unit}}</span></div>

    </div>

    <button slot="actions" v-if="modalAdd && modalAdd.add < 0 && !modalAdd.waste" @click="AddStock()" class="primary error-color">Quitar</button>
    <button slot="actions" v-else v-show="modalAdd && !modalAdd.waste" :disabled="modalAdd && !modalAdd.add"  @click="AddStock()" class="primary purple">Agregar</button>

    <button slot="actions" v-if="modalAdd && modalAdd.waste" @click="AddStock(true)" class="primary error-color">Enviar a Merma</button>
</modal>

<modal v-model="modalNew" :dir="['right', 'right']" :modalClass="['fill borderless', 'md right h100']" :title="'Nuevo Item de Inventario'">
    <div class="section" v-if="modalNew">
        
        <input-field class="mb15"
        v-model="modalNew.title"
        label="Nombre Item">
        </input-field>

        <input-field class="mb15"
        v-model="modalNew.unit"
        label="Unidad de medida"
        placeholder="(Opcional)">
        </input-field>

        <input-field 
        class="mb15"
        v-model="modalNew.unit_cost"
        label="Costo Unitario"
        type="float+"
        prefix="$"
        placeholder="0">
        </input-field>

        <input-field 
        class="mb15" v-if="(subItems.length == 0)"
        v-model="modalNew.performance"
        label="Rendimiento %"
        type="float+"
        suffix="%"
        placeholder="Porcentaje rendimiento">
        </input-field>

        <input-select class="mb15" v-model="modalNew.space" canType :items="spaces" label="Espacio"></input-select>

        <input-select class="mb15" v-model="modalNew.section" canType :items="sections" label="Sección"></input-select>

        <div class="flex align-center mb5">
            <div class="flex-fill">
                <div class="bold">Sub Items</div>
            </div>
            <div class="f18">
                <input-select class="ml5"
                inputClass="icon primary"
                v-model="modalNew.subitem"
                :triggerFirstValueChanged="false"
                @OnValueChanged="addSubItem($event, false)"
                :items="itemsMenuSelect"
                :canType="true"
                v-tooltip="'Nuevo subítem'"
                icon="plus">
                </input-select>
            </div>
        </div>

        <div class="flex align-center mb10 well" v-for="(subItem, index) in subItems" :key="index">
            <input-field
            v-model="subItem.title"
            class="flex-fill mr5">
            </input-field>

            <input-field
            v-model="subItem.quantity"
            :placeholder="inventory[subItem.title]? `Cantidad (${inventory[subItem.title].unit})` : 'Cantidad'"
            type="float+"
            :suffix="` ${inventory[subItem.title]? inventory[subItem.title].unit : ''}`"
            class="flex-fill ml5">
            </input-field>

            <i class="options icon-trash ml10" @click.stop="subItems.splice(index, 1)"></i>
        </div>
        <div class="secondary-text ta-center" v-if="!subItems.length">No hay sub ítems</div>

    </div> 
    <button class="primary purple" slot="actions" @click="CreateInventoryItem()">Crear item</button>
</modal>

<modal v-model="modalEdit" :dir="['right', 'right']" :modalClass="['fill borderless', 'md right h100']" :title="modalEdit ? modalEdit.title : ''">
    <div class="section" v-if="modalEdit">
        <input-field class="mb15"
        v-model="modalEdit.unit"
        label="Unidad de medida"
        placeholder="(Opcional)">
        </input-field>

        <input-field 
        class="mb15"
        v-model="modalEdit.unit_cost"
        label="Costo Unitario"
        type="float+"
        prefix="$"
        placeholder="0">
        </input-field>

        <input-field class="mb15"
        v-model="modalEdit.performance"
        v-if="(subItems.length == 0)"
        label="Rendimiento %"
        type="float+"
        suffix="%"
        placeholder="Porcentaje rendimiento">
        </input-field>

        <input-select class="mb15" v-model="modalEdit.space" canType :items="spaces" label="Espacio"></input-select>

        <input-select class="mb15" v-model="modalEdit.section" canType :items="sections" label="Sección"></input-select>

        <!--
        <div class="input-style" v-if="modalEdit.unit_cost">
            <div class="input-label">Precio Unitario</div>
            <div>${{Util.Number(modalEdit.unit_cost)}}</div>
        </div>
        -->
        <div class="flex align-center mb5">
            <div class="flex-fill">
                <div class="bold">Sub Items</div>
            </div>
            <div class="f18">
                <input-select class="ml5"
                inputClass="icon primary"
                v-model="modalEdit.subitem"
                :triggerFirstValueChanged="false"
                @OnValueChanged="addSubItem($event, true)"
                :items="itemsMenuSelect"
                :canType="true"
                v-tooltip="'Nuevo subítem'"
                icon="plus">
                </input-select>
            </div>
        </div>

        <div class="flex align-center mb15 well" v-for="(subitem, index) in subItems" :key="index">
            <input-field
            v-model="subitem.title"
            class="flex-fill mr5">
            </input-field>

            <input-field
            v-model="subitem.quantity"
            :placeholder="inventory[subitem.title]? `Cantidad ${inventory[subitem.title].unit}` : 'Cantidad'"
            type="float+"
            :suffix="` ${inventory[subitem.title]? inventory[subitem.title].unit : ''}`"
            class="flex-fill ml5">
            </input-field>

            <i class="options icon-trash ml10" @click.stop="subItems.splice(index, 1)"></i>
        </div>
        <div class="secondary-text ta-center" v-if="!subItems.length">No hay sub ítems</div>
    </div> 
    
    <button slot="actions" class="primary purple" @click="EditItem()">Editar</button>
</modal>

<modal v-model="modalItem" :dir="['right', 'right']" :modalClass="['fill', 'md right h100']" :title="modalItem? modalItem.item.title : ''" hideActions>

    <input-range slot="options"
    v-if="modalItem"
    v-model="modalItem.range"
    v-show="modalItem.tab == 'Resumen' && (!modalItem.item.subitems || !modalItem.item.subitems.length)"
    @OnValueChanged="handleValueChanged" 
    inputClass="secondary"
    :icon="mobile ? 'datetime' : ''"
    :triggerFirstValueChanged="false">
    </input-range>

    <input-date :icon="mobile ? 'calendar' : null" 
        slot="options"
        v-show="modalItem && modalItem.tab == 'Historial'"
        inputClass="secondary" 
        type="DATE"
        format="timestamp"
        v-model="dateFilterModal"
        modalOnly
        :toEndDay="true"
        @OnAcceptDate="LoadItemHistory()">
    </input-date>

    <tabs v-if="modalItem" v-model="modalItem.tab" :tabs="itemTabs" :keyByTab="true" 
    @OnTabResumen="modalItem.stats == null ? LoadItemStats(modalItem.range) : null"
    @OnTabProveedores="modalItem.providers == null ? ItemStats(modalItem.item, 'providers') : null"
    @OnTabHistorialdeCostos="modalItem.history_costs.length == 0 ? LoadItemCostHistory() : null"
    @OnTabHistorial="modalItem.history.length == 0 ? LoadItemHistory() : null">
        <div slot="Resumen">
            <div class="section">
                <div>
                    <div class="flex column" v-if="modalItem.stats">
                        <div class="flex column">
                            <div class="mb15">
                                <div class="bold">Total Utilizado</div>
                                <div>{{Util.Number(modalItem.stats.total_used)}} <span v-if="modalItem.item.unit" class="secondary-text">{{modalItem.item.unit}}</span></div>
                            </div>
                            <div class="mb15">
                                <div class="bold">Total Ingresado</div>
                                <div>{{Util.Number(modalItem.stats.total_added)}} <span v-if="modalItem.item.unit" class="secondary-text">{{modalItem.item.unit}}</span></div>
                            </div>
    
                            <div class="">
                                <div class="bold">Total Gastado</div>
                                <div>{{Util.Price(modalItem.stats.total_money)}}</div>
                            </div>
                        </div>
                        <div class="separator mb10 mt10"></div>        
                    </div>
                
                    <div class="flex align-center mb15">
                        <div class="">
                            <div class="bold">Total Disponible</div>
                            <div>{{Util.Number(modalItem.item.quantity)}} <span v-if="modalItem.item.unit" class="secondary-text">{{modalItem.item.unit}}</span></div>
                        </div>
                        <div class="i icon icon-arrow-right mr20 ml20" v-if="modalItem.item.performance && modalItem.item.performance != 100"></div>
                        <div class="" v-if="modalItem.item.performance && modalItem.item.performance != 100">
                            <div class="bold">Total Transformado</div>
                            <div>{{Util.Number(modalItem.item.quantity*modalItem.item.performance/100)}} <span v-if="modalItem.item.unit" class="secondary-text">{{modalItem.item.unit}}</span></div>
                        </div>
                    </div>
    
                    <div class="mb15">
                        <div class="bold">Costo unitario</div>
                        <div>${{Util.Number(modalItem.last_unit_cost)}} <span v-if="modalItem.item.unit" class="secondary-text">pesos/{{modalItem.item.unit}}</span></div>
                    </div>
                    <div class="flex align-center">
                        <div class="">
                            <div class="bold">Valor Disponible</div>
                            <div>{{Util.Price(modalItem.item.quantity * modalItem.last_unit_cost)}}</div>
                        </div>
                        <div class="i icon icon-arrow-right mr20 ml20" v-if="modalItem.item.performance && modalItem.item.performance != 100"></div>
                        <div class="" v-if="modalItem.item.performance && modalItem.item.performance != 100">
                            <div class="bold">Valor Transformado</div>
                            <div>{{Util.Price((modalItem.item.quantity*modalItem.item.performance/100) * modalItem.last_unit_cost)}}</div>
                        </div>
                    </div>
                </div>
                
            </div>
        </div>

        <vertical-content slot="Historial de Costos" @OnBottom="LoadItemCostHistory()">
            <div class="section" v-if="modalItem.history_costs.length > 0">

                <chart v-if="modalItem.history_costs.length > 1" class="mb20"
                type="line"
                :data="[Util.ReverseArr(modalItem.history_costs.slice(0, 20))]"
                dataset="unit_cost"
                :label="(data) => {return Util.Date(data.date)}"
                height="200px"
                :yLabelFormat="(value) => {return '$'+Util.Number(value)}"
                :tooltip="(value, label, index) => {return `$${Util.Number(value)} ${modalItem.history_costs[index].custom_data.provider ? ('(' + modalItem.history_costs[index].custom_data.provider.name + ')') : ''}`}"
                :customColor="[Util.Chart.purpleGradient]">
                </chart>

                <div class="entry selectable"
                v-for="(transaction, index) in modalItem.history_costs"
                :key="index"
                @click="$refs.inventoryDetails.Open(transaction)">
                    <div class="fill flex align-center">
                        <div class="flex-fill flex column">
                            <div class="bold">{{transaction.item}}</div>
                            <div class="secondary-text"><span class="bold">Fecha:</span> {{Util.Date(transaction.date, 'DD/MM/YYYY HH:mm')}}</div>
                            <div class="secondary-text" v-if="transaction.custom_data.provider"><span class="bold">Proveedor:</span> {{transaction.custom_data.provider.name}} - {{Util.Rut(transaction.custom_data.provider.rut)}}</div>
                        </div>
                        <div>
                            <span class="bold">${{Util.Number(transaction.unit_cost)}} </span>
                            <span class="secondary-text" v-if="inventory[transaction.item] && inventory[transaction.item].unit">pesos/{{inventory[transaction.item].unit}}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div v-else class="secondary-text mt20 ta-center">No hay datos.</div>
        </vertical-content>

        <div slot="Proveedores" class="pa20">
            <div class="flex column" v-if="modalItem.providers && modalItem.providers.length">

                <div :class="`entry ${provider.history.length > 1 ? 'selectable' : ''}`" 
                v-for="(provider, index) in modalItem.providers" :key="'provider'+index" @click="$set(modalItem, 'selectedProvider', provider.provider.name)"
                v-tooltip="'Ver historial de costos'">
                    <div class="flex align-center">
                        <div class="flex-fill">
                            <div class="bold">{{provider.provider.name}}</div>
                            <div class="secondary-text">{{Util.Rut(provider.provider.rut)}}</div>
                        </div>
                        <div>
                            <div class="bold ta-right">${{Util.Number(provider.history[provider.history.length-1].unit_cost)}}</div>
                            <div class="secondary-text ta-right" v-if="modalItem.item.unit">pesos / {{modalItem.item.unit}}</div>
                        </div>
                        <!--<i v-if="provider.history.length > 1" class="options icon-arrow-down ml5" @click="$set(modalItem, 'selectedProvider', provider.provider.name)"></i>-->
                    </div>
                    <chart v-if="provider.history.length > 1 && modalItem.selectedProvider == provider.provider.name" class="well mt10"
                    type="line"
                    :data="[provider.history]"
                    dataset="unit_cost"
                    :label="(data) => {return Util.Date(data.date)}"
                    height="200px"
                    :yLabelFormat="(value) => {return '$'+Util.Number(value)}"
                    :tooltip="(value, label, index) => {return `$${Util.Number(value)}`}"
                    :customColor="[Util.Chart.purpleGradient]">
                    </chart>
                </div>
            </div>
            <div v-else class="secondary-text ta-center mt10">No hay datos disponibles</div>
        </div>

        <vertical-content ref="verticalItemHistory" slot="Historial" @OnBottom="LoadItemHistory(true)" class="pa20">
            <div class="" v-if="modalItem.history.length > 0">
                <div class="entry selectable"
                v-for="(transaction, index) in modalItem.history"
                :key="index"
                @click="$refs.inventoryDetails.Open(transaction)">
                    <div class="fill flex align-center">
                        <div class="flex-fill flex column">
                            <div class="">
                                <span :class="`bold ${transaction.quantity < 0 ? 'cred' : ''}`"><span v-if="transaction.quantity > 0">+</span>{{Util.Number(transaction.quantity)}}</span> <span class="secondary-text ml5" v-if="inventory[transaction.item] && inventory[transaction.item].unit">{{inventory[transaction.item].unit}}</span>
                                <span class="bold ml10">{{transaction.item}}</span>
                            </div>
                            <div class="secondary-text" v-if="transaction.comment"><span class="bold">Comentario:</span> {{transaction.comment}}</div>
                            <!-- <div class="secondary-text">{{transaction.quantity_from}} <i class="icon icon-arrow-right"></i> {{transaction.quantity_to}}</div> -->
                            <!-- <div class="secondary-text" v-if="transaction.id_transaction">Utilizado en el pedido X</div> -->
                            <div class="secondary-text" v-if="transaction.custom_data.created_by"><span class="bold">Ingresado por:</span> {{transaction.custom_data.created_by}}</div>
                            <div class="secondary-text"><span class="bold">Fecha:</span> {{Util.Date(transaction.date, 'DD/MM/YYYY HH:mm')}}</div>
                        </div>
                        <div class="bold" v-if="transaction.money">{{Util.Price(transaction.money)}}</div>
                        <!--<i class="icon icon-arrow-right-o c4 ml10"></i>-->
                    </div>
                </div>
            </div>
            <div v-else class="secondary-text mt10 ta-center">No hay datos.</div>
        </vertical-content>
    </tabs>
</modal>

<modal v-model="modalItemComposite" :dir="['right', 'right']" :modalClass="['fill', 'md right h100']" :title="modalItemComposite? modalItemComposite.title : ''" hideActions>

    <div v-if="modalItemComposite" class="section">
        <div class="mb15">
            <div class="bold">Total Disponible</div>
            <div>{{Util.Number(modalItemComposite.quantity)}} <span v-if="modalItemComposite.unit" class="secondary-text">{{modalItemComposite.unit}}</span></div>
        </div>

        <div class="mb15">
            <div class="bold">Costo unitario</div>
            <div>${{Util.Number(modalItemComposite.unit_cost)}} <span v-if="modalItemComposite.unit" class="secondary-text">pesos/{{modalItemComposite.unit}}</span></div>
        </div>
        
        <div class="">
            <div class="bold">Valor Disponible</div>
            <div>{{Util.Price(modalItemComposite.quantity * modalItemComposite.unit_cost)}}</div>
        </div>
        
        <div class="separator mt20 mb20"></div>
        
        <div class="mb15">
            <div class="f18">Sub Items</div>
        </div>
        
        <div>
            <div v-for="(subitem, index) in modalItemComposite.subitems" :key="index" 
            class="well mb10 flex fill selectable align-center"
            @click="ItemStats(inventory[subitem.title])">
                <avatar class="mr10"
                :imageIcon="subitem.title"
                defaultImageIcon="799"
                :color="ItemColor(inventory[subitem.title])">
                </avatar>

                <div class="flex-fill flex column">
                    <span class="bold">{{subitem.title}}</span>
                    <div class="secondary-text" v-if="inventory[subitem.title] && inventory[subitem.title].unit_cost">Costo unitario: ${{Util.Number(inventory[subitem.title].unit_cost)}}<span v-if="inventory[subitem.title]" class="secondary-text ml5">{{inventory[subitem.title].unit}}</span></div>
                    <div class="secondary-text" v-if="inventory[subitem.title]">Cantidad disponible: {{Util.Number(inventory[subitem.title].quantity)}}<span v-if="inventory[subitem.title]" class="secondary-text ml5">{{inventory[subitem.title].unit}}</span></div>
                </div>
                <div class="flex column" style="text-align: right">
                    <div>
                        <span class="bold">{{Util.Number(subitem.quantity)}}</span>
                        <span v-if="inventory[subitem.title]" class="secondary-text ml5">{{inventory[subitem.title].unit}}</span>
                    </div>
                </div>
                <i v-if="inventory[subitem.title]" @click.stop="modalAdd={item: Util.Copy(inventory[subitem.title]), unit_cost: inventory[subitem.title].unit_cost? String(inventory[subitem.title].unit_cost) : null}" class="options icon-plus ml10" v-tooltip="'Agregar '+subitem.title"></i>
            </div>
        </div> 
            
        
    </div>

</modal>

<modal v-model="modalProvider" dir="right" :modalClass="['fill', 'sm right h100']" :title="modalProvider && modalProvider.edit ? 'Editar Proveedor' : 'Nuevo Proveedor'">

    <div class="section" v-if="modalProvider">
        <input-field class="mb15"
        v-model="modalProvider.name"
        label="Nombre">
        </input-field>

        <input-field :readonly="modalProvider.edit" class="mb15"
        v-model="modalProvider.rut"
        label="RUT">
        </input-field>

        <input-field class="mb15"
        v-model="modalProvider.email"
        label="Email"
        placeholder="(Opcional)">
        </input-field>
        
        <input-field class="mb15"
        v-model="modalProvider.phone"
        label="Teléfono"
        placeholder="(Opcional)">
        </input-field>

        <input-field class="mb15"
        v-model="modalProvider.company"
        label="Razon Social"
        placeholder="(Opcional)">
        </input-field>

        <input-field
        v-model="modalProvider.address"
        label="Dirección"
        placeholder="(Opcional)">
        </input-field>

    </div> 
    
    <button slot="actions" class="primary purple" @click="SaveProvider()">Guardar</button>
    
</modal>

<modal v-model="modalInvoice" dir="right" :modalClass="['fill', 'lg right h100']" :title="modalInvoice && modalInvoice.title? modalInvoice.title : 'Nueva Factura de Proveedor'">
    <div class="section" v-if="modalInvoice">

        <div class="bold">Proveedor</div>
        <div class="mb15">{{`${modalInvoice.provider.name} ${Util.Rut(modalInvoice.provider.rut)}`}}</div>
        
        <div class="hs mb15">
            <input-field v-model="modalInvoice.number" label="Número" type="int+"></input-field>
            <input-date v-model="modalInvoice.date" label="Fecha" type="DATE" format="timestamp"></input-date>
        </div>
    
        <div class="separator mb20 mt20"></div>

        <div class="flex align-center mb20">
            <div class="f18 flex-fill flex">Items <span class="counter">{{modalInvoice.inventory.length}}</span></div>
            <i class="options icon-search" title="Buscar factura anterior" @click="OpenLastInvoices"></i>
            <i class="options icon-plus" @click="modalProviderItem = {item: '', quantity: 0, price: 0, total_price: 0, discount: 0, tax: 0, delivery_fee: 0, iva: true}"></i>
        </div>
        <div class="secondary-text mt10 ta-center" v-if="!modalInvoice.inventory.length">No hay items</div>
        <div class="flex well selectable mb10" v-for="(entry, index) in modalInvoice.inventory" :key="index" @click="modalProviderItem=Util.Copy(entry, {index: index})">
            <div class="flex-fill">
                <div class="bold">{{entry.item}}</div>
                <div class="flex">
                    <div class="w20">
                        <div class="secondary-text">Cantidad</div>
                        <div>{{Util.Number(entry.quantity)}}</div>
                    </div>
                    <div class="w20">
                        <div class="secondary-text">Costo Unitario</div>
                        <div>${{Util.Number(entry.price)}}</div>
                    </div>
                    <div class="w10">
                        <div class="secondary-text">IVA</div>
                        <i v-if="entry.iva" class="icon icon-check cpurple"></i>
                    </div>
                    <div class="w20">
                        <div class="secondary-text">Impuesto</div>
                        <div v-if="!entry.tax">-</div>
                        <div v-else-if="entry.tax > 0">{{Util.Number(entry.tax)}}%</div>
                        <div v-else>{{Util.Price(entry.tax)}}</div>
                    </div>
                    <div class="w20">
                        <div class="secondary-text">Flete</div>
                        <div v-if="!entry.delivery_fee">-</div>
                        <div v-else-if="entry.delivery_fee > 0">{{Util.Number(entry.delivery_fee)}}%</div>
                        <div v-else>{{Util.Price(entry.delivery_fee)}}</div>
                    </div>
                    <div class="w20">
                        <div class="secondary-text">Descuento</div>
                        <div v-if="!entry.discount">-</div>
                        <div v-else-if="entry.discount > 0">{{Util.Number(entry.discount)}}%</div>
                        <div v-else>{{Util.Price(entry.discount)}}</div>
                    </div>
                </div>
            </div>
            <i class="options icon-trash" @click.stop="modalInvoice.inventory.splice(index, 1)"></i>
        </div>
    
        <div class="separator mb20 mt20"></div>

        <div class="f18 mb20">Total</div>

        <div class="flex mb15">
            <div class="mr20">
                <div class="bold">Monto neto</div>
                <div>{{Util.Price(invoice ? invoice.total.subtotal : 0)}}</div>
            </div>

            <div class="ml20">
                <div class="bold">I.V.A. 19%</div>
                <div>{{Util.Price(invoice ? invoice.total.iva : 0)}}</div>
            </div>
        </div>

        <div class="flex mb15">
            <div class="mr20">
                <div class="bold">Impuesto Adicional</div>
                <div>{{Util.Price(invoice ? invoice.total.taxes : 0)}}</div>
            </div>

            <div class="ml20 mr20">
                <div class="bold">Flete</div>
                <div>{{Util.Price(invoice ? invoice.total.deliveryFee : 0)}}</div>
            </div>

            <div class="ml20">
                <div class="bold">Descuentos</div>
                <div>{{Util.Price(invoice ? invoice.total.discount : 0)}}</div>
            </div>
        </div>

        <div class="mb15">
            <div class="bold">Total</div>
            <div>{{Util.Price(invoice ? invoice.total.total : 0)}}</div>
        </div>
        
        <label class="">
            <input type="checkbox" v-model="modalInvoice.pending"> Pago pendiente <div class="checkmark"></div>
        </label>

        <input-select class="mt15" label="Método de Pago" v-model="modalInvoice.pay_method" :items="payMethods" v-show="!modalInvoice.pending"></input-select>

        <input-field v-model="modalInvoice.comment" label="Comentario" :lines="3" column placeholder="(Opcional)" class="mt15"></input-field>
        <input-files v-model="modalInvoice.files" label="Documentos Adjuntos" class="mt15"></input-files>
        

    </div>

    <button v-if="modalInvoice && modalInvoice.title" slot="actions" class="primary purple" @click="EditInvoice()">Editar</button>
    
    <button v-else slot="actions" class="primary purple" @click="SaveInvoice()">Guardar</button>
</modal>

<modal v-model="modalItemResumenHistory" dir="right" :modalClass="['fill', 'sm right h100']" title="Historial de transacciones" hideActions>
    <vertical-content class="pa20" v-if="modalItemResumenHistory && modalItemResumenHistory.history && modalItemResumenHistory.history.length > 0" @OnBottom="LoadHistoryResumen(modalItemResumenHistory.item)">
        <div class="entry selectable"
        v-for="(record, index) in modalItemResumenHistory.history"
        :key="index"
        @click="$refs.inventoryDetails.Open(record)">
        
        <div class="fill flex align-center">
                <div class="flex-fill flex column">
                    <div>
                        <span :class="`bold ${record.quantityShow < 0 ? 'cred' : ''}`">
                            <span v-if="record.quantityShow > 0">+</span>
                            {{Util.Number(record.quantityShow)}}
                        </span> 
                        <span class="secondary-text ml5" v-if="inventory[record.item] && inventory[record.item].unit">{{inventory[record.item].unit}}</span>
                        <span class="bold ml10">{{record.item}}</span>
                    </div>
                    <div class="secondary-text" v-if="record.createdBy"><span class="bold">Ingresado por:</span> {{record.createdBy}}</div>
                    <div class="secondary-text"><span class="bold">Fecha:</span> {{Util.Date(record.date, 'DD/MM/YYYY HH:mm')}}</div>
                </div>
                <div class="bold" v-if="record.money">{{Util.Price(record.money)}}</div>
            </div>
        </div>
    </vertical-content>
    <div v-else class="secondary-text mt10 ta-center">No hay datos.</div>
</modal>

<modal v-model="modalProviderItem" dir="right" :modalClass="['fill', 'sm right h100']" :title="modalProviderItem && modalProviderItem.index >= 0 ? 'Editar Item' : 'Agregar Item'">

    <div class="pa20" v-if="modalProviderItem">

        <input-select class="mb15"
        v-model="modalProviderItem.item" 
        :items="inventorySelectItems"
        label="Item de Inventario"
        canType>
        </input-select>

        <input-field class="mb15"
        v-model="modalProviderItem.quantity"
        type="float+" 
        label="Cantidad"
        :suffix="inventory[modalProviderItem.item] && inventory[modalProviderItem.item].unit ? ' '+inventory[modalProviderItem.item].unit : ''"
        @OnChangeFocused="QuantityChanged($event, modalProviderItem, true)">
        
        </input-field>

        <div class="hdvm mb15">

            <input-field
            v-model="modalProviderItem.price" 
            type="float+" 
            label="Costo Unitario"
            prefix="$"
            @OnChangeFocused="UnitCostChanged($event, modalProviderItem, true)">
            </input-field>

            <input-field
            v-model="modalProviderItem.total_price" 
            type="float+" 
            label="Costo Total"
            prefix="$"
            @OnChangeFocused="CostChanged($event, modalProviderItem, true)">
            </input-field>

        </div>

        <div class="mb15">
            <label>
                <input class="mt10" type="checkbox" v-model="modalProviderItem.iva">
                <div class="bold">IVA</div>
                <div class="checkmark"></div>
            </label>
        </div>
                    
        <input-field class="mb15"
        v-model="modalProviderItem.tax" 
        label="Impuesto Adicional" 
        type="float" 
        placeholder="(Opcional)"
        :prefix="modalProviderItem.tax < 0 ? '$' : ''"
        :suffix="modalProviderItem.tax > 0 ? '%' : ''"
        info="Un número negativo representa un valor fijo en pesos y un número positivo un porcentaje sobre el costo total del item">
        </input-field>

        <input-field class="mb15"
        v-model="modalProviderItem.delivery_fee" 
        label="Flete" 
        type="float" 
        placeholder="(Opcional)"
        :prefix="modalProviderItem.delivery_fee < 0 ? '$' : ''"
        :suffix="modalProviderItem.delivery_fee > 0 ? '%' : ''"
        info="Un número negativo representa un valor fijo en pesos y un número positivo un porcentaje sobre el costo total del item">
        </input-field>

        <input-field 
        v-model="modalProviderItem.discount" 
        label="Descuento" 
        type="float" 
        placeholder="(Opcional)"
        :prefix="modalProviderItem.discount < 0 ? '$' : ''"
        :suffix="modalProviderItem.discount > 0 ? '%' : ''"
        info="Un número negativo representa un valor fijo en pesos y un número positivo un porcentaje sobre el costo total del item">
        </input-field>
            
        
        <!--Util.Price(modalProviderItem.quantity * modalProviderItem.price)-->
                
        <!--<i class="options icon-trash" title="Quitar ítem" @click="modalInvoice.inventory.splice(index, 1)"></i>-->

    </div>

    
    <button slot="actions" class="primary purple" @click="AddInventoryItemToInvoice">{{modalProviderItem && modalProviderItem.index >= 0 ? 'Editar' : 'Agregar'}}</button>

</modal>

<modal v-model="modalLastInvoices" title="Facturas anteriores" dir="right" :modalClass="['fill borderless', 'sm right h100']" hideActions>
    <div class="section" v-if="modalLastInvoices">
        <div class="entry selectable" v-for="invoice in modalLastInvoices.invoices" :key="invoice.id" @click="LoadPreviousInvoice(invoice)">
            <div class="fill flex column">
                <div class="flex">
                    <div class="bold flex-fill">Factura N°{{invoice.number}}</div>
                    <div>{{Util.Date(invoice.date)}}</div>
                </div>
                
                <div v-for="(item, index) in invoice.data" :key="index" class="flex secondary-text">
                    <div class="bold flex-fill">{{item.item}}</div>
                    <div>{{Util.Number(item.quantity)}} <span>{{item.unit}}</span></div>
                </div>
                
            </div>
        </div>
    </div>
</modal>

<modal v-model="modalProviderStats" :title="'Detalles de Proveedor'" :dir="['right', 'right']" :modalClass="['fill borderless', 'md right h100']" hideActions>

    <input-date v-if="modalProviderStats && modalProviderStats.tab == 'Facturas'" slot="options" 
    icon="search" type="DATE" @OnAcceptDate="LoadProviderInvoices($event)"></input-date>
    <i class="options icon-dots-vertical ml5" slot="options" @click="Options([
        {text: 'Eliminar Proveedor', class: 'error-color', function: () => {DeleteProvider(modalProviderStats.provider.rut)}, confirm: `¿Eliminar proveedor?`, if: canDeleteProvider},
        {text: 'Editar proveedor', function: () => {modalProvider = Util.Copy(modalProviderStats.provider, {edit: true})}, if: canEditProvider},
        {text: 'Registrar factura', class: 'selected-color', function: () => {modalInvoice = {inventory: [], provider: modalProviderStats.provider}}, if: canCreateInvoices},
    ])"></i>

    <tabs :tabs="isAdmin ? ['General', 'Items', 'Facturas'] : ['General']" v-if="modalProviderStats" keyByTab
     v-model="modalProviderStats.tab"
    @OnTabFacturas="!modalProviderStats.history.length? LoadProviderInvoices() : null">

        <div slot="General" class="pa20">
            
            <div class="mb15">
                <div class="bold">Nombre</div>
                <div>{{modalProviderStats.provider.name}}</div>
            </div>

            <div class="mb15">
                <div class="bold">RUT</div>
                <div>{{Util.Rut(modalProviderStats.provider.rut)}}</div>
            </div>

            <div class="mb15" v-if="modalProviderStats.provider.company">
                <div class="bold">Razón Social</div>
                <div>{{modalProviderStats.provider.company}}</div>
            </div>

            <div class="mb15" v-if="modalProviderStats.provider.email">
                <div class="bold">Email</div>
                <div>{{modalProviderStats.provider.email}}</div>
            </div>

            <div class="mb15" v-if="modalProviderStats.provider.phone">
                <div class="bold">Teléfono</div>
                <div>{{modalProviderStats.provider.phone}}</div>
            </div>

            <div class="mb15" v-if="modalProviderStats.provider.address">
                <div class="bold">Dirección</div>
                <div>{{modalProviderStats.provider.address}}</div>
            </div>
            
            <div v-if="modalProviderStats.pending.length && canPayInvoices">

                <div class="separator mb20 mt20"></div>

                <div class="f18 mb20">Facturas con Pago Pendiente</div>
                <div class="entry selectable"
                v-for="(invoice, index) in modalProviderStats.pending"
                :key="index"
                @click="$refs.invoiceDetails.Open(invoice)">
                    <div class="flex fill align-center">
                        <div class="flex column">
                            <div class="bold">Factura N°{{invoice.number}}</div>
                            <div class="secondary-text"><span class="bold">Ingresado por: </span>{{invoice.custom_data.created_by}}</div>
                            <div class="secondary-text"><span class="bold">Fecha: </span>{{Util.Date(invoice.created_at, 'DD/MM/YYYY')}}</div>
                        </div>
                        <div class="flex-fill"></div>
                        <div class="flex column mr10" style="text-align: right">
                            <div class="bold cred">{{Util.Price(invoice.total)}}</div>
                        </div>
                        <!--<i class="icon icon-arrow-right-o c4"></i>-->
                    </div>
                </div>
            </div>

        </div>

        <div v-if="isAdmin" slot="Items" class="pa20">

            <div class="f18 mb20">Items</div>

            <div :class="`entry ${item.history.length > 1 ? 'selectable' : ''}`" v-for="item in modalProviderStats.items" :key="item.item"
            @click="$set(modalProviderStats, 'selectedItem', item.item)">
                <div class="flex align-center">
                    <div class="flex-fill bold">{{item.item}}</div>
                    <div class="ta-right">
                        <div class="bold">${{Util.Number(item.history[item.history.length-1].unit_cost)}}</div>
                        <div class="secondary-text" v-if="inventory[item.item] && inventory[item.item].unit">pesos / {{inventory[item.item].unit}}</div>
                    </div>
                </div>
                <chart v-if="item.history.length > 1 && modalProviderStats.selectedItem == item.item" class="mt10 well"
                type="line"
                :data="[item.history]"
                dataset="unit_cost"
                :label="(data) => {return Util.Date(data.date)}"
                height="200px"
                :yLabelFormat="(value) => {return '$'+Util.Number(value)}"
                :tooltip="(value, label, index) => {return `$${Util.Number(value)}`}"
                :customColor="[Util.Chart.purpleGradient]">
                </chart>
            </div>
        </div>

        <vertical-content slot="Facturas" ref="verticalInvoicesHistory"  class="pa20" v-if="modalProviderStats" @OnBottom="LoadProviderInvoices()">

            <div class="">
                <div class="secondary-text ta-center mt10" v-if="!modalProviderStats.history.length">No hay facturas</div>
                <div class="entry selectable"
                v-for="(invoice, index) in modalProviderStats.history"
                :key="index"
                @click="$refs.invoiceDetails.Open(invoice)">
                    <div class="flex fill align-center">
                        <div class="flex column">
                            <div class="bold">Factura N°{{invoice.number}}</div>
                            <div class="secondary-text"><span class="bold">Ingresado por: </span>{{invoice.custom_data.created_by}}</div>
                            <div class="secondary-text"><span class="bold">Fecha: </span>{{Util.Date(invoice.created_at, 'DD/MM/YYYY')}}</div>
                            <div class="secondary-text" v-if="invoice.status !== 'PENDING'"><span class="bold">Método de Pago: </span><span>{{Util.PayMethod(invoice.pay_method)}}</span></div>
                        </div>
                        <div class="flex-fill"></div>
                        <div class="flex column mr10" style="text-align: right">
                            <div :class="`bold ${invoice.status == 'PENDING' ? 'cred' : ''}`">{{Util.Price(invoice.total)}}</div>
                            <div class="secondary-text" v-if="invoice.status == 'PENDING'">Pago Pendiente</div>
                        </div>
                        <!--<i class="icon icon-arrow-right-o c4"></i>-->
                    </div>
                </div>
            </div>

        </vertical-content>
    </tabs>
</modal>

<modal v-model="modalItemWaste" dir="right" :modalClass="['fill', 'md right h100']" :title="modalItemWaste? 'Detalle del ítem' : null" hideActions>
    <tabs v-if="modalItemWaste" 
    v-model="modalItemWaste.tab" 
    :tabs="itemWasteTabs" 
    :keyByTab="true"
    @OnTabHistorialdeTransacciones="LoadItemWasteHistory()">
        <div slot="Motivos">
            <div class="section">
                <div class="entry" v-for="(reason, index) in modalItemWaste.reasons" :key="index">
                    <div class="fill flex align-center">
                        <div class="flex-fill flex column">
                            <div class="bold">{{reason.data}}</div>
                            <div class="secondary-text"><span class="bold">Cantidad:</span> {{reason.count}} de {{modalItemWaste.total_reasons}}</div>
                        </div>
                        <div>
                            <span class="bold">{{Percent(reason.count, modalItemWaste.total_reasons)}} %</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div slot="Historial de Transacciones">
            <div class="section" v-if="modalItemWaste && modalItemWaste.history && modalItemWaste.history.length > 0">
                <div class="entry selectable"
                v-for="(transaction, index) in modalItemWaste.history"
                :key="index"
                @click="$refs.inventoryDetails.Open(transaction)">
                    <div class="fill flex align-center">
                        <div class="flex-fill flex column">
                            <div class="bold">{{transaction.item}}</div>
                            <div class="secondary-text"><span class="bold">Fecha:</span> {{Util.Date(transaction.date, 'DD/MM/YYYY HH:mm')}}</div>
                            <div class="secondary-text" v-if="transaction.comment"><span class="bold">Cantidad:</span> {{Math.abs(transaction.quantity)}}</div>
                            <div class="secondary-text" v-if="transaction.comment"><span class="bold">Motivo:</span> {{transaction.comment}}</div>
                        </div>
                        <div>
                            <span class="bold">${{Util.Number(transaction.money)}} </span>
                            <span class="secondary-text" v-if="inventory[transaction.item] && inventory[transaction.item].unit">pesos/{{inventory[transaction.item].unit}}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div v-else class="secondary-text mt10 ta-center">No hay datos.</div>
        </div>
    </tabs>
</modal>

<modal v-model="modalTransferItem" dir="right" :modalClass="['fill', 'sm right h100']" :title="modalTransferItem? `Transferir ${modalTransferItem.title}` : null">
    <div v-if="modalTransferItem" class="pa20">
        <div class="flex align-center mb15">
            <div class="">
                <div class="bold">Espacio</div>
                <div><span>{{modalTransferItem.space}}</span></div>
            </div>
            <div class="mr30 ml30"></div>
            <div class="">
                <div class="bold">Ítem</div>
                <div><span>{{modalTransferItem.title}}</span></div>
            </div>
        </div>

        <div class="flex align-center mb15">
            <div class="">
                <div class="bold">Cantidad</div>
                <div><span>{{Util.Number(modalTransferItem.quantity)}}</span></div>
            </div>
            <i class="icon icon-arrow-right ml20 mr20 c4"></i>
            <div class="">
                <div class="bold">Nueva cantidad</div>
                <div><span>{{modalTransferItem.quantityTransfer? Util.Number(modalTransferItem.quantity - modalTransferItem.quantityTransfer) : Util.Number(modalTransferItem.quantity)}}</span></div>
            </div>
        </div>

        Destino
        <div class="mt10 mb15">
            <input-select v-model="modalTransferItem.toSpace"
            :class="`${attachedSublocals ? 'input' : 'input-top'}`"
            :items="spacesTransfer">
            </input-select>
            
            <input-select v-model="modalTransferItem.toItem"
            v-if="!attachedSublocals"
            class="input-bottom"
            :items="inventoryTransfer"
            canType="search"
            :triggerFirstValueChanged="false"
            @OnValueChanged="AssignItemTransfer($event)">
            </input-select>
        </div>

        <input-field class="mb15"
        v-model="modalTransferItem.quantityTransfer"
        label="Cantidad a transferir"
        type="float+">
            <div class="append unshrink" slot="after" v-if="modalTransferItem.unit">{{modalTransferItem.unit}}</div>
        </input-field>

        <div class="flex align-center mb15" v-if="modalTransferItem.toItem && !attachedSublocals">
            <div class="">
                <div class="bold">Cantidad</div>
                <div><span>{{Util.Number(modalTransferItem.toItem.quantity)}}</span></div>
            </div>
            <i class="icon icon-arrow-right ml20 mr20 c4"></i>
            <div class="">
                <div class="bold">Nueva cantidad</div>
                <div><span>{{modalTransferItem.quantityTransfer? Util.Number(modalTransferItem.toItem.quantity + modalTransferItem.quantityTransfer) : Util.Number(modalTransferItem.toItem.quantity)}}</span></div>
            </div>
        </div>
    </div>

    <button slot="actions" class="primary purple" @click="TransferItem()">Transferir</button>
</modal>

<!-- MODAL DE TRANSFERENCIA DE INVENTARIO DESDE FRANQUICIA HACIA SUBLOCAL -->
<modal v-model="modalTransferInventory" dir="right" :modalClass="['fill', 'md right h100']" title="Transferir Inventario">
    
    <input-select
    slot="options"
    v-if="modalTransferInventory"
    ref="CurrentItem"
    inputClass="icon primary"
    :triggerFirstValueChanged="false"
    @OnValueChanged="AddToInventory(modalTransferInventory.inventory, $event, 'Item sin stock para transferir.')"
    :items="itemsToArray"
    :canType="'search'"
    icon="plus">
    </input-select>
    
    <div v-if="modalTransferInventory" class="pa20">
        <div class="flex align-center mb20">
            <div class="bold mr10">Destino</div>
            <input-select v-model="modalTransferInventory.toSpace"
            :class="`${attachedSublocals ? 'input' : 'input-top'}`"
            :items="spacesTransfer">
            </input-select>
        
            <!--
            <input-select v-model="modalTransferInventory.toItem"
                
                v-if="!attachedSublocals"
                class="input-bottom"
                :items="inventoryTransfer"
                canType="search"
                :triggerFirstValueChanged="false"
                @OnValueChanged="AssignItemTransfer($event)">
            </input-select>
            -->
        </div>
        <div class="separator mb10 mt10"></div>
        <div class="flex well mb10 column" v-for="(item, index) in modalTransferInventory.inventory" :key="index">
            <div>{{ item.item.title }}</div>
            <div class="flex align-center">
                <div class="mb20">
                    <div class="secondary-text">Cantidad</div>
                    {{ `${item.item.quantity} ${item.item.unit ? item.item.unit : ''}` }}
                </div>
                <i class="icon icon-arrow-right ml20 mr20 c4"></i>
                <div>
                    <div class="secondary-text">Cant. Transferir</div>
                    <input-field 
                    v-model="item.item.quantityTransfer"
                    type="number"
                    placeholder="0"
                    >
                    <div class="append unshrink" slot="after" v-if="item.unit">{{item.unit}}</div>
                    </input-field>

                </div>
                <div class="flex-fill"></div>
                <i class="options icon-trash ml10 c4 align-center" @click.stop="DeleteItemFromModal(index)"></i>
            </div>
        </div>
    </div>
    <button slot="actions" class="primary purple" @click="TransferItemFromFranchise()">Transferir</button>
</modal>
<!-- MODAL DE TRANSFERENCIA DE INVENTARIO DESDE FRANQUICIA HACIA SUBLOCAL -->

<!-- MODAL PARA AGREGAR MASIVAMENTE STOCK DE INVENTARIO -->
<modal v-model="modalAddMasiveStock" dir="right" :modalClass="['fill', 'lg right h100']" title="Agregar Inventario Masivo">
    <input-select class="ml5"
    v-if="modalAddMasiveStock"
    ref="CurrentItem"
    inputClass="icon primary"
    :triggerFirstValueChanged="false"
    @OnValueChanged="AddToInventory(modalAddMasiveStock.inventory, $event, null)"
    :items="itemsToArray"
    :canType="'search'"
    icon="plus"
    slot="options">
    </input-select>
    <div v-if="modalAddMasiveStock" class="pa20">
        <div class="flex align-center mb20">
            <div class="bold mr10">Proveedor</div>
            <input-select
            v-model="modalAddMasiveStock.provider" 
            :items="providersItems" 
            canType="search">
            </input-select>
        </div>
        <div class="separator mb10 mt10"></div>
        <div class="flex well mb10" v-for="(item, index) in modalAddMasiveStock.inventory" :key="index">
            <div class="flex-fill">
                <div class="flex column">
                    <div class="align-center flex mb10">
                        <div class="">{{ item.item.title }} </div>
                        <div class="ml10">( {{ `${item.item.quantity} ${item.item.unit}` }} )</div>    
                    </div>
                    <div class="flex">
                        <div>
                            <div class="secondary-text">Cantidad</div>
                            <input-field 
                            v-model="item.add"
                            type="number"
                            placeholder="0"
                            @OnChangeFocused="QuantityChanged($event, item)">
                                <div class="append unshrink" slot="after" v-if="item.unit">{{item.unit}}</div>
                            </input-field>
                        </div>

                        <div class="ml15">
                            <div class="secondary-text">{{mobile ? 'C.' : 'Costo'}} Unitario</div>
                            <input-field 
                            v-model="item.unit_cost"
                            placeholder="(Opcional)"
                            type="float"
                            prefix="$"
                            :readonly="Auth.role == 'cashier' && !Auth.restrictions.can_edit_cost_unit? true : false"
                            @OnChangeFocused="UnitCostChanged($event, item)">
                                <div class="append unshrink" slot="after" v-if="item.unit">$ x {{item.unit}}</div>
                            </input-field>
                        </div>
                        <div class="ml15">
                            <div class="secondary-text">{{mobile ? 'C.' : 'Costo'}} Total</div>
                            <input-field
                            v-model="item.cost"
                            placeholder="(Opcional)"
                            type="float"
                            prefix="$"
                            after=" "
                            :readonly="Auth.role == 'cashier' && !Auth.restrictions.can_edit_cost_unit && !item.unit_cost? true : false"
                            @OnChangeFocused="$event => CostChanged($event, item)">
                                <i class="icon icon-cross selectable" v-tooltip="'Quitar'" 
                                v-show="item.cost" slot="inner-after"
                                @click="$set(item, 'cost', null);$set(item, Auth.role == 'cashier' && !Auth.restrictions.can_edit_cost_unit? 'add' : 'unit_cost', null)"></i>
                            </input-field>
                        </div>
                        
                        <div class="flex-fill"></div>
                        <i class="options icon-trash ml10 c4 align-center" @click.stop="DeleteItemFromModal(index)"></i>

                    </div>

                </div>
            </div>
        </div>
    </div>
    <button slot="actions" class="primary purple" @click="AddMultiStock()">Agregar</button>
</modal>
<!-- MODAL PARA AGREGAR MASIVAMENTE STOCK DE INVENTARIO -->

<!-- ---- MODAL PARA CONFIRMACION DE IMPRESION ---- -->
<modal v-model="modalConfirm.show" modalClass="md">
    <div class="pa20">
        <div v-if="modalConfirm.show" class="ta-center mb10" v-html="modalConfirm.title"></div>
        <div class="flex justify-center">
            <label>
                <input type="checkbox" v-model="modalConfirm.showAgain">No volver a mostrar<div class="checkmark"></div>
            </label>
        </div> 
    </div>

    <button slot="actions"  @click="modalConfirm.show = false" class="secondary">Cancelar</button>
    <button slot="actions" class="primary" @click="PrintVoucher()">Imprimir</button>
</modal>
<!-- ---- MODAL PARA CONFIRMACION DE IMPRESION ---- -->

<!-- MODAL DISPONIBILIDAD TOTAL -->
<modal v-model="modalValueAvailable" dir="right" :modalClass="['fill', 'sm right h100']" title="Valor Disponible de Inventario">

    <div class="pa20">
        <div :class="`mb20 ${item.quantity < 0 ? 'cred' : ''}`"  v-for="(item, index) in modalValueAvailable" :key="index"> 
            <div><span class="bold">{{item.title}}</span> <span class="secondary-text"> {{`(${item.unit ? item.quantity+' '+item.unit : item.quantity})`}}</span> </div>
            <div>{{Util.Price(item.quantity * item.unit_cost)}}</div>
        </div>
    </div>

    <div slot="actions">
       Total Disponible {{Util.Price(CalculateSumValueAvailable(modalValueAvailable))}}
    </div>
</modal>
<!-- MODAL DISPONIBILIDAD TOTAL -->
<inventory-details ref="inventoryDetails" :units="inventory" @OnTransactionDeleted="OnInventoryTransactionDeleted"></inventory-details>
<invoice-details 
    @OnInvoicePaid="OpenProviderStats(); CheckPendingInvoices()" 
    @OnInvoiceDeleted="OpenProviderStats(); CheckPendingInvoices(); DeleteInvoice();" 
    @OnEditInvoice="ModalEditInvoice"
    ref="invoiceDetails">
</invoice-details>

</div>
</template>

<script>
export default {
    data() {
        return {
            inventory: {},
            payMethods: [{text: 'Sin definir', value: 'UNDEFINED'}],
            modalAdd: null,
            modalNew: null,
            modalItem: null,
            modalEdit: null,
            history: [],
            range: null,
            stats: null,
            tab: null,
            dateFilter : null,
            dateFilterModal : null,
            search: '',
            tabs: null,
            itemTabs: null,
            providers: null,
            modalProvider: null,
            modalInvoice: null,
            modalProviderStats: null,
            orderBy: 'QUANTITY',
            modalLastInvoices: null,
            searchProvider: '',
            pendingInvoices: {},
            orderByOptions: [{text: 'Cantidad', value: 'QUANTITY'}, {text: 'Nombre', value: 'NAME'}],
            modalProviderItem: null,
            reasonsWaste: [
                {text: 'Sin definir', value: 'Sin definir'},
                {text: 'Mercancía vencida', value: 'Mercancía vencida'},
                {text: 'Exceso de inventario', value: 'Exceso de inventario'},
                {text: 'Robos', value: 'Robos'},
                {text: 'Mercancía dañada', value: 'Mercancía dañada'}
            ],
            itemsWaste: null,
            modalItemWaste: null,
            rangeWaste: {
                option: 'MONTH',
                index: 0
            },
            subItems: [],
            searchResumen: null,
            modalItemResumenHistory: null,
            ModalImportInventory: null,
            orderSpace: 'General',
            modalItemComposite: null,
            modalTransferItem: null,
            modalTransferInventory : null,
            modalAddMasiveStock : null,
            modalConfirm : { show : false, showAgain : false },
            showConfirm : false,
            itemsToArray : [],
            attachedSublocals: null,
            attachedFranchises: null,
            modalValueAvailable : null,
        }
    },
    mounted() {

        this.tabs = [];
        if(this.Local.modules.module_inventory) this.tabs.push('Actual');
        if(this.Local.modules.module_inventory && this.isAdmin) this.tabs.push('Merma');
        if(this.canCreateProvider || this.canEditProvider || this.canCreateInvoices || this.canPayInvoices)
        if(this.Local.modules.module_inventory) this.tabs.push('Proveedores');
        if(this.Local.modules.module_inventory && this.isAdmin) this.tabs.push('Resumen');
        if(this.Local.modules.module_inventory && this.isAdmin) this.tabs.push('Historial');

        this.itemWasteTabs = [];
        if(this.Local.modules.module_inventory) this.itemWasteTabs.push('Motivos');
        if(this.Local.modules.module_inventory) this.itemWasteTabs.push('Historial de Transacciones');


        this.$root.$emit('Loading');
        this.Loading();
        axios.get(`/inventory/${this.Local.id}`).then(res => {
            this.inventory = this.Util.CalculateInventoryComposite(res.data.items);
            console.log(res.data.methods);
            for(var i = 0; i < res.data.methods.length; i++)
                this.payMethods.push({text: this.Util.PayMethod(res.data.methods[i]), value: res.data.methods[i]});
            
            this.Response(res);
        }).catch(err => {
            this.Response(err);
        });

        //TODO: deberia ser async
        axios.get(`/providers/${this.Local.id}`).then(res => {
            this.Response(res);
            this.providers = res.data;
        }).catch(err => {
            this.Response(err);
        });

        if(this.Local.type == "FRANCHISE"){
            axios.get(`/sublocals/${this.Local.id}`).then( ({data}) => {
                this.attachedSublocals = data.attached;
                this.attachedFranchises = data.attachedFranchises;
                this.Response(data);
            }).catch(err => {
                this.Response(err);
            });
        }

        var moment = require('moment');
        this.range = {
            option: 'MONTH',
            index: 0,
            label: 'Este mes',
            rangeStart: moment().startOf('month').unix(),
            rangeEnd: moment().endOf('month').unix(),
        };

        this.rangeWaste = {
            option: 'MONTH',
            index: 0,
            label: 'Este mes',
            rangeStart: moment().startOf('month').unix(),
            rangeEnd: moment().endOf('month').unix(),
        }
    },
    methods: {
        CalculateSumUsed(array) {
            let sumaTotal = 0;
            if(array){      
                array.forEach((element) => {
                    if(element.total_used < 0) element.total_used = element.total_used * -1;
                    if(this.inventory[element.data])
                    {
                        let total = element.total_used * this.inventory[element.data].unit_cost
                        sumaTotal += total
                    }
                });
            }
            return sumaTotal;
        },
        CalculateSumValueAvailable(array) {
            let sumaTotal = 0;
            for (const itemName in array) {
                const { quantity, unit_cost } = array[itemName];
                if(quantity > 0)
                    sumaTotal += quantity * unit_cost;
            }
            return sumaTotal;
        },
        ShowModalInventoryValueAvailable(){
            this.modalValueAvailable = []
            for (const itemName in this.inventory) {
                //Si subitems es NULL o tiene longitud 0, se agrega a la lista. No se permiten item que tengan subitems
                if (!this.inventory[itemName].subitems || this.inventory[itemName].subitems.length === 0) {
                    this.modalValueAvailable.push(this.inventory[itemName]);
                }
            }
            //Se ordeba alfabeticamente la lista
            this.modalValueAvailable = Util.NaturalSort(this.modalValueAvailable)
        },
        UpdateInventory()
        {
            axios.get(`/inventory/${this.Local.id}`).then(res => {
                this.inventory = this.Util.CalculateInventoryComposite(res.data.items);
                if(this.modalItemComposite && this.inventory[this.modalItemComposite.title]) this.modalItemComposite = this.inventory[this.modalItemComposite.title];
            });
        },
        CreateInventoryItem() {
            if(!this.modalNew.title || !this.modalNew.title.trim()) return this.Info('Datos inválidos');
            let validateSubitem = null;
            if(this.subItems.length > 0){
                this.subItems.forEach( (subitem, indexSubitem) => {
                    subitem.title = subitem.title.trim();
                    if(!subitem.title || subitem.title == '') return validateSubitem = `Hay un subítem sin nombre`;
                    if(!subitem.quantity || isNaN(subitem.quantity) || subitem.quantity <= 0) return validateSubitem = `Revise la cantidad de ${subitem.title}`;
                    console.log(this.modalNew.title.toUpperCase(), subitem.title);
                    if(this.modalNew.title.toUpperCase() == subitem.title) return validateSubitem = `Nombre de subítem ${subitem.title} inválido`;
                    this.$set(this.subItems[indexSubitem], 'title', subitem.title.toUpperCase());
                });
                this.$set(this.modalNew, 'performance', 100);
            }
            if(validateSubitem) return this.Info(validateSubitem);
            if(!this.modalNew.performance || isNaN(this.modalNew.performance) || parseFloat(this.modalNew.performance) <= 0) return this.Info('Rendimiento inválido');
            var item = this.modalNew.title.toUpperCase();
            this.$set(this.modalNew, 'subItems', this.subItems);
            if(this.inventory[item]) return this.Info('El item ingresado ya existe.');
            this.Loading();
            axios.put(`/inventory/${this.Local.id}`, this.modalNew).then( ({data}) => {
                data.forEach( item => {
                    this.$set(this.inventory, item.title, item);
                });
                this.inventory = this.Util.CalculateInventoryComposite(this.inventory);
                this.Response(data);
                this.subItems = [];
                this.modalNew = null;
            }).catch(err => {
                this.Response(err);
            });
        },
        EditItem() {
            if(!this.modalEdit.performance || isNaN(this.modalEdit.performance) || parseFloat(this.modalEdit.performance) <= 0) return this.Info('Rendimiento inválido');
            let validateSubitem = null;
            if(this.subItems.length > 0){
                this.subItems.forEach( (subitem, indexSubitem) => {
                    if(!subitem.title || subitem.title == '') return validateSubitem = `Hay un subítem sin nombre`;
                    if(!subitem.quantity || isNaN(subitem.quantity) || subitem.quantity <= 0) return validateSubitem = `Revise la cantidad de ${subitem.title}`; 
                    this.$set(this.subItems[indexSubitem], 'title', subitem.title.toUpperCase());
                });
                this.$set(this.modalEdit, 'performance', 100);
            }
            if(validateSubitem) return this.Info(validateSubitem);
            this.modalEdit.performance = parseFloat(this.modalEdit.performance);
            this.$set(this.modalEdit, 'subItems', this.subItems);
            this.Loading();
            axios.patch(`/inventory/${this.Local.id}`, this.modalEdit).then( ({data}) => {
                data.forEach( item => {
                    this.$set(this.inventory, item.title, item);
                });
                this.inventory = this.Util.CalculateInventoryComposite(this.inventory);
                this.Response(data);
                this.modalEdit = null;
            }).catch(err => {
                this.Response(err);
            });
        },
        checkConfirm(r){
            if(this.modalConfirm.showAgain === false){
                this.modalConfirm.show = true
                this.modalConfirm.title = '¿Imprimir voucher de movimiento de inventario?'
                this.modalConfirm.data_response = r.data.transaction
            }else{
                //Si es true, no será necesario confirmar en cada vez que se agregue stock
                this.modalConfirm.show = false
            }
        },
        AddStock(waste) {
            
            if(!this.modalAdd.add) return this.Info('La cantidad a agregar debe ser distinta de cero.');
            if(this.modalAdd.provider && this.modalAdd.add < 0) return this.Info('No se puede descontar inventario con un proveedor');

            //if(waste) this.modalAdd.add = -this.modalAdd.add;
            
            if(!this.modalAdd.waste || this.modalAdd.addCost)
            {
                if(this.modalAdd.cost) 
                {
                    if(isNaN(this.modalAdd.cost) || this.modalAdd.cost < 0) return this.Info('Costo inválido');
                    this.modalAdd.unit_cost = this.Util.RoundFloat(this.modalAdd.cost / this.modalAdd.add);
                }
                else if(this.modalAdd.unit_cost) 
                {
                    if(isNaN(this.modalAdd.unit_cost) || this.modalAdd.unit_cost < 0) return this.Info('Costo unitario inválido');
                    this.modalAdd.cost = this.modalAdd.unit_cost * this.modalAdd.add;
                }
            }

            if(this.modalAdd.waste && !this.modalAdd.addCost)
            {
                this.modalAdd.cost = null;
                this.modalAdd.unit_cost = null;
            }

            this.$root.$emit('Loading');
            axios.post(`/inventory/${this.Local.id}/add?v2=true`, this.modalAdd).then(res => {
                this.inventory = this.Util.CalculateInventoryComposite(res.data.inventory);
                this.modalAdd = false;
                this.$root.$emit('Response', res);
                this.history = [];

                this.checkConfirm(res)

                // this.Confirm({
                //         title: '¿Imprimir voucher de movimiento de inventario?',
                //         text: 'Imprimir',
                //         class: 'selected-color',
                //         function: () => {this.$refs.inventoryDetails.PrintInventoryVoucher(res.data.transaction)}
                // });

            }).catch(err => {
                this.$root.$emit('Response', err);
            });
        },
        AddMultiStock() {
            let validation = true
            if(this.modalAddMasiveStock.inventory.length === 0) return this.Info("Debe ingresar al menos 1 item")
            this.modalAddMasiveStock.inventory.forEach(element => {

                if(!element.hasOwnProperty('add')){
                    validation = false
                    return this.Info("Debe ingresar un valor de cantidad.")
                } 
                if(isNaN(element.add)){
                    validation = false
                    return this.Info("Debe ingresar un valor de cantidad.")
                } 
                if(element.add === 0){
                    validation = false
                    return this.Info("Valor de cantidad debe ser mayor a 0.")
                }
            });
            if(validation){
                this.$root.$emit('Loading');
                axios.post(`/inventory/${this.Local.id}/add?v2=true`, this.modalAddMasiveStock).then(res => {
                    this.inventory = this.Util.CalculateInventoryComposite(res.data.inventory);
                    this.modalAddMasiveStock = null;
                    this.$root.$emit('Response', res);
                    this.history = [];

                    this.checkConfirm(res)


                    // this.Confirm({
                    //         title: '¿Imprimir voucher de movimiento de inventario?',
                    //         text: 'Imprimir',
                    //         class: 'selected-color',
                    //         function: () => {this.$refs.inventoryDetails.PrintInventoryVoucher(res.data.transaction)}
                    // });

                }).catch(err => {
                    this.$root.$emit('Response', err);
                });
            }

        },
        PrintVoucher(){
            this.$refs.inventoryDetails.PrintInventoryVoucher(this.modalConfirm.data_response)
            this.modalConfirm.show = false
        },
        DeleteItem(item)
        {
            this.$root.$emit('Loading');
            axios.post(`/inventory/${this.Local.id}`, {item: item.title}).then(res =>{
                this.inventory = this.inventory = this.Util.CalculateInventoryComposite(res.data);
                this.$root.$emit('Response', res);
            }).catch(err => {
                this.$root.$emit('Response', err);
            });
        },
        LoadItemStats(range)
        {
            this.Loading();
            axios.post(`/inventory/${this.Local.id}/item/stats`, {item: this.modalItem.item.title, range: range}).then(res => {
                this.Response(res);
                this.$set(this.modalItem, 'stats', res.data);
            }).catch(err => {
                this.Response(err);
            });
        },
        LoadItemHistory(bottom=false)
        {
            //url anterior
            // `/inventory/${this.Local.id}/history/item${history.length>0?'/'+(history[history.length-1].date-1):''}`
            this.$refs.verticalItemHistory.Reset();
            if(!bottom) this.modalItem.history=[];
            this.$root.$emit('Loading');
            this.$nextTick(()=> {
                axios.post(`/inventory/${this.Local.id}/history/item/${!bottom ? this.dateFilterModal : this.modalItem.history.length >0 ? this.modalItem.history[this.modalItem.history.length-1].date-1 : '' }`, {item: this.modalItem.item.title}).then(res => {
                    this.$root.$emit('Response', res);
                    var history = this.modalItem.history.concat(res.data);
                    this.$set(this.modalItem, 'history', history);
                    this.$forceUpdate();
                }).catch(err => {
                    this.$root.$emit('Response', err);
                });
            })
        },
        LoadItemCostHistory()
        {
            this.$root.$emit('Loading');
            var history = this.modalItem.history_costs;
            axios.post(`/inventory/${this.Local.id}/history/item/cost${history.length>0?'/'+(history[history.length-1].date-1):''}`, {item: this.modalItem.item.title}).then(res => {
                this.$root.$emit('Response', res);
                var history = this.modalItem.history_costs.concat(res.data);
                this.$set(this.modalItem, 'history_costs', history);
                this.$forceUpdate();
            }).catch(err => {
                this.$root.$emit('Response', err);
            });
        },
        LoadHistory(bottom=false)
        {
            //url anterior
            //`/inventory/${this.Local.id}/history${this.history.length == 0 ? '' : '/' + (this.history[this.history.length-1].date-1)}`
            this.$refs.verticalHistory.Reset()
            if(!bottom) this.history = []
            this.Loading();
            this.$nextTick(() => {
                axios.get(`/inventory/${this.Local.id}/history/${!bottom ? this.dateFilter : this.history.length > 0 ? this.history[this.history.length-1].date-1 : ''}`).then(res => {
                    this.Response(res);
                    this.history = this.history.concat(res.data);
                }).catch(err => {
                    this.Response(err);
                });  
            })
        },
        ItemStats(item, updateKey) {

            if(item.subitems && item.subitems.length)
            {
                this.itemTabs = ['Resumen'];
                this.modalItemComposite = item;
                return;
            }

            this.Loading();
            axios.post(`/inventory/${this.Local.id}/item/stats`, {item: item.title}).then(res => {
                this.Response(res);
                if(updateKey)
                {
                    this.$set(this.modalItem, updateKey, res.data[updateKey]);
                }
                else
                {
                    var data = res.data;
                    data.history = [];
                    data.history_costs = [];
                    this.modalItem = data;
                    this.itemTabs = [];
                    if(this.Local.modules.module_inventory) this.itemTabs.push('Resumen');
                    if(this.Local.modules.module_inventory) this.itemTabs.push('Historial de Costos');
                    if(this.Local.modules.module_inventory) this.itemTabs.push('Proveedores');
                    if(this.Local.modules.module_inventory) this.itemTabs.push('Historial');
                }
            }).catch(err => {
                this.Response(err);
            });
        },
        Search()
        {
            this.Loading();
            axios.post(`/inventory/${this.Local.id}/stats`, this.range).then(res => {
                this.Response(res);
                console.log(res.data);
                this.stats = res.data;
            }).catch(err => {
                this.Response(err);
            });
        },
        GenerateInventory()
        {
            this.Loading();
            axios.get(`/inventory/${this.Local.id}/generate`).then(res => {
                this.inventory = this.inventory = this.Util.CalculateInventoryComposite(res.data);
                this.Response(res);
            }).catch(err => {
                this.Response(err);
            });
        },
        Print()
        {
            var moment = require('moment');
            var now = moment();
            var arr = [
                {text: 'Inventario ' + this.Local.name},
                {text: `Fecha: ${now.format('HH:mm DD/MM/YYYY')}`},
                {line: true},
            ];

            let inventory = this.Util.NaturalSort(Object.values(this.inventory), 'title');
            inventory.forEach(inv => {
                arr.push({text: [`${inv.title}${inv.unit?' ('+inv.unit+')':''}`, this.Util.Number(inv.quantity)]});
            });
            console.log(arr);

            this.Util.NotifyService('print', {data: arr, id: Math.random().toString()});
            
        },
        Export() 
        {

            this.Loading();
            axios.get(`/inventory/${this.Local.id}/export`, {responseType: 'blob'}).then(res => {
                this.Response(res);
                this.Util.DownloadFile(res, `inventario_${Util.Today().date.replace(/\//g, '-')}.xls`);
            }).catch(err => {
                this.Response(err);
            });
        },
        ExportResumen(){
            this.Loading();
            axios.post(`/inventory/${this.Local.id}/resumen/export`, this.range, {responseType: 'blob'})
            .then(res => {
                this.Response(res);
                this.Util.DownloadFile(res, `inventario_${Util.Today().date.replace(/\//g, '-')}.xls`);
            }).catch(err => {
                this.Response(err);
            });
        },
        SaveProvider()
        {
            if(!this.modalProvider.name || !this.modalProvider.rut) return this.Info('Datos inválidos');

            if(this.modalProvider.edit)
            {
                this.Loading();
                axios.patch(`/providers/${this.Local.id}/${this.modalProvider.rut}`, this.modalProvider).then(res => {
                    this.Response(res);
                    this.providers = res.data;
                    this.modalProvider = null;
                    this.modalProviderStats = null;
                }).catch(err => {
                    this.Response(err);
                });
            }
            else
            {
                this.Loading();
                axios.put(`/providers/${this.Local.id}`, this.modalProvider).then(res => {
                    this.Response(res);
                    this.providers = res.data;
                    this.modalProvider = null;
                }).catch(err => {
                    this.Response(err);
                });
            }
        },
        DeleteProvider(provider) 
        {
            this.Loading();
            axios.delete(`/providers/${this.Local.id}/${provider}`).then(res => {
                this.Response(res);
                this.providers = res.data;
                this.modalProviderStats = null;
            }).catch(err => {
                this.Response(err);
            });
        },
        SaveInvoice()
        {
            if(!this.modalInvoice.number) return this.Info('Falta un número de factura');
            if(!this.invoice) return this.Info('Falta el información de un item');

            this.Loading();
            axios.put(`/provider/invoice/${this.Local.id}`, this.invoice).then(res => {
                this.UpdateInventory();
                this.Response(res);
                if(this.modalProviderStats) this.OpenProviderStats();
                this.modalInvoice = null;
                this.CheckPendingInvoices();
                if(this.modalProviderStats) this.$set(this.modalProviderStats, 'history', []);
                this.LoadProviderInvoices();

                this.Confirm({
                    title: '¿Imprimir voucher de factura de proovedor de inventario?',
                    text: 'Imprimir',
                    class: 'selected-color',
                    function: () => {this.$refs.invoiceDetails.PrintInvoiceVoucher(res.data)}
                });

            }).catch(err => {
                this.Response(err);
            });

        },
        EditInvoice(){
            if(!this.modalInvoice) return this.Info('Error, ha ocurrido un problema.');
            if(!this.modalInvoice.id) return this.Info('No se ha seleccionado ninguna factura');
            if(!this.modalInvoice.number) return this.Info('Falta un número de factura');
            if(!this.invoice) return this.Info('Falta el información de un item');

            this.Loading();

            axios.patch(`/providers/invoice/${this.modalInvoice.id}/${this.Local.id}`, this.invoice).then(res => {
                this.Response(res);
                if(this.modalProviderStats) this.OpenProviderStats();
                this.modalInvoice = null;
                this.CheckPendingInvoices();
                this.modalProviderStats.history.length = 0;
                this.LoadProviderInvoices();
                this.UpdateInventory();

                this.Confirm({
                    title: '¿Imprimir voucher de factura de proovedor de inventario?',
                    text: 'Imprimir',
                    class: 'selected-color',
                    function: () => {this.$refs.invoiceDetails.PrintInvoiceVoucher(res.data)}
                });

            }).catch(err => {
                this.Response(err);
            });
        },
        DeleteInvoice(){
            this.$set(this.modalProviderStats, 'history', []); 
            this.LoadProviderInvoices();
        },
        LoadProviderInvoices(range) {

            if(!this.modalProviderStats) return;
            var invoices = this.modalProviderStats.history;
            var date = invoices.length ? invoices[invoices.length-1].created_at-1 : null;
            if(range)
            {
                this.$refs.verticalInvoicesHistory.Reset();
                date = range.endOf('day').unix();
                this.modalProviderStats.history = [];
            }

            this.Loading();
            
            axios.get(`/provider/${this.Local.id}/invoices/${this.modalProviderStats.provider.rut}${date?'/'+date:''}`).then(res => {
                this.Response(res);
                console.log(res.data);
                this.modalProviderStats.history = this.modalProviderStats.history.concat(res.data);
            }).catch(err => {
                this.Response(err);
            });
        },
        OpenLastInvoices() {
            this.Loading();
            axios.get(`/provider/${this.Local.id}/invoices/${this.modalInvoice.provider.rut}`).then(res => {
                if(!res.data.length) return this.Info(`No hay facturas de este proveedor`);
                this.Response(res);
                this.modalLastInvoices = {
                    invoices: res.data
                };
            }).catch(err => {
                this.Response(err);
            });
        },
        LoadPreviousInvoice(invoice)
        {
            var inventory = [];

            invoice.data.forEach(item => {
                if(this.inventory[item.item])
                {
                    inventory.push({
                        item: item.item,
                        quantity: item.quantity,
                        price: item.price,
                        tax: item.taxPercentage,
                        discount: item.discountPercentage,
                        delivery_fee: item.deliveryFeePercentage,
                        iva: item.ivaPercentage !== 0
                    });
                }
            });
            this.$set(this.modalInvoice, 'inventory', inventory);

            this.modalLastInvoices = null;
        },
        CheckPendingInvoices()
        {
            if(!this.canPayInvoices) return;
            axios.get(`/providers/${this.Local.id}/invoices/pending/count`).then(res => {
                this.pendingInvoices = res.data;
                console.log(res.data);
            }).catch(err => {
                console.log(err);
            });
        },
        OpenProviderStats(provider)
        {
            if(!provider && this.modalProviderStats)
            {
                provider = this.modalProviderStats.provider.rut;
                axios.get(`/providers/${this.Local.id}/stats/${provider}`).then(res => {
                    res.data.history = [];
                    this.modalProviderStats = res.data;
                });
                return;
            }

            this.Loading();
            axios.get(`/providers/${this.Local.id}/stats/${provider}`).then(res => {
                this.Response(res);
                res.data.history = [];
                this.modalProviderStats = res.data;
            }).catch(err => {
                this.Response(err);
            });
        },
        QuantityChanged(quantity, modal, isProvider){

            quantity = parseFloat(quantity);
            if(isNaN(quantity) || quantity == 0) return;

            var unitCost = parseFloat(modal[isProvider ? 'price' : 'unit_cost']);

            if(!isNaN(unitCost))
            {
                this.$set(modal, isProvider ? 'total_price' : 'cost', unitCost*quantity);
                return;
            }

            var totalCost = parseFloat(modal[isProvider ? 'total_price' : 'cost']);
            if(!isNaN(totalCost))
            {
                this.$set(modal, isProvider ? 'price' : 'unit_cost', totalCost / quantity);
                return;
            }
            
        },
        UnitCostChanged(unitCost, modal, isProvider)
        {
            var quantity = parseFloat(modal[isProvider ? 'quantity' : 'add']);

            if(isNaN(quantity)) return;
            unitCost = parseFloat(unitCost);
            if(isNaN(unitCost)) return this.$set(modal, isProvider ? 'total_price' : 'cost', '');
            this.$set(modal, isProvider ? 'total_price' : 'cost', unitCost * quantity);

        },
        CostChanged(cost, modal, isProvider)
        {

            var quantity = parseFloat(modal[isProvider ? 'quantity' : 'add']);
            if(isNaN(quantity) || quantity == 0) return;
            cost = parseFloat(cost);
            if(isNaN(cost)) return this.$set(modal, isProvider ? 'price' : 'unit_cost', '');
            if(!isProvider && modal.unit_cost && this.Auth.role == 'cashier' && !this.Auth.restrictions.can_edit_cost_unit) return this.$set(modal, 'add', cost / modal.unit_cost);
            this.$set(modal, isProvider ? 'price' : 'unit_cost', cost / quantity);
        },
        AddInventoryItemToInvoice()
        {
            
            if(!this.modalProviderItem.item) return this.Info('Item inválido');
            if(isNaN(this.modalProviderItem.quantity) || this.modalProviderItem.quantity <= 0) return this.Info('Cantidad inválida');
            if(isNaN(this.modalProviderItem.price) || this.modalProviderItem.price <= 0) return this.Info('Precio inválido');

            this.modalProviderItem.item = this.modalProviderItem.item.toUpperCase();

            var index = this.modalProviderItem.index;
            delete this.modalProviderItem[index];

            if(isNaN(index))
            {
                this.modalInvoice.inventory.push(this.modalProviderItem);
            }
            else
            {
                this.$set(this.modalInvoice.inventory, index, this.modalProviderItem);
            }

            
            this.modalProviderItem = null;
        },
        ItemColor(item)
        {
            if(!item || item.quantity <= 0) return '#ff495b';
            return '#0cc683';
        },
        ModalEditInvoice(invoice){
            console.log(invoice);
            this.modalInvoice = Util.Copy(invoice, 
            {
                title: 'Editar Factura de Proveedor',
                provider: invoice.custom_data.provider,
                inventory : invoice.data,
                pending: invoice.paid_at? false : true,
                comment: invoice.custom_data.comment,
                files: invoice.custom_data.files,
            });
        },
        TitleProduct(modalAdd){
            if(modalAdd.item.title && modalAdd.waste){
                return `Enviar ${modalAdd.item.title} a merma`;
            }else{
                return `Agregar ${modalAdd.item.title}`;
            }
        },
        SearchItemsWaste(callback){
            this.Loading();
            axios.post(`/inventory/waste/${this.Local.id}`, this.rangeWaste)
            .then(({data}) => {
                this.itemsWaste = data;
                this.Response(this.itemsWaste);
                if(callback) callback();
            }).catch(err => {
                this.Response(err);
            });
        },
        LoadItemWasteHistory(){
            this.Loading();

            axios.post(`/inventory/waste/${this.Local.id}/history`, {
                item: this.modalItemWaste.data,
                date: this.rangeWaste
            }).then( ({data}) => {
                this.$set(this.modalItemWaste, 'history', data);
                console.log(this.modalItemWaste);
                this.Response(this.modalItemWaste);
            }).catch(err => {
                this.Response(err);
            });
        },
        Percent(amount, total) 
        {
            if(total <= 0) return '';
            return Math.round(100*amount/total);
        },
        AddToInventory(inventory, data, noStockMsg){
            console.log('add',data);
            //Busca si el elemnto entrante existe en el array
            const itemExists = inventory.find(item => item.item.id === data.dataitem.id);

            //Si el elemento existe, retorna mensaje
            if (itemExists) return this.Info("Item ya se encuentra agregado en la lista.");
                
            // Si el stock del elemento es 0 y existe mensaje, retornara el mensaje 'Item sin stock para transferir.'
            //Esto solo aplica para cuando cuando se realizan "TRANSFERENCIAS MASIVAS" solo franquicia
            if(data.dataitem.quantity === 0 && noStockMsg) return this.Info(noStockMsg)
            this.$refs.CurrentItem.CleanCurrentItem()
            //En el caso que se "agregado masivo de stock"
            if(!noStockMsg){
                inventory.push({'item' : data.dataitem, 'pay_method' : 'UNDEFINED', 'unit_cost' : null });
                return
            }
           
            // En el caso que sea "transferencia masivo de stock"
            inventory.push({'item' : data.dataitem })
            
        },
        addSubItem(item){
            if(!item) return;

            let title = typeof item == 'object'? item.title.toUpperCase() : item.toUpperCase();

            this.subItems.push({
                title
            });
        },
        ItemResumenHistory(item){
            this.Loading();
            axios.post(`/inventory/${this.Local.id}/resumen/history`, {item, range: this.range}).then( ({data}) => {
                if(data.status){
                    this.modalItemResumenHistory = {};
                    this.$set(this.modalItemResumenHistory, 'history', data.history);
                    this.$set(this.modalItemResumenHistory, 'item', data.item);
                } 
                this.Response(data);
            }).catch(err => {
                this.Response(err);
            });
        },
        LoadHistoryResumen(item){
            let history = this.modalItemResumenHistory.history;
            this.Loading();
            axios.post(`/inventory/${this.Local.id}/resumen/history`, {
                lastDate: history.length == 0 ? null : history[history.length-1].date-1,
                item,
                range: this.range
            }).then( ({data}) => {
                this.Response(data);
                this.modalItemResumenHistory.history = this.modalItemResumenHistory.history.concat(data.history);
            }).catch(err => {
                this.Response(err);
            });
        },
        ImportInventory(){
            if(!this.ModalImportInventory.file) return this.Info('Se debe seleccionar un archivo');
            let formData = new FormData();
            formData.append("file", this.ModalImportInventory.file);

            this.Loading();
            axios.post(`/inventory/${this.Local.id}/import`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then( ({data}) => {
                this.Info(data.msg);
                if(data.status){
                    data.items.forEach( item => {
                        this.$set(this.inventory, item.title, item);
                    });
                }
                this.ModalImportInventory = null;
            }).catch(err => {
                this.Response(err);
            });
        },
        TransferItem(){
            if(!this.modalTransferItem) return;
            if(!this.attachedSublocals && !this.modalTransferItem.toItem) return this.Info(`El ítem de destino es obligatorio`);
            if(this.modalTransferItem.quantity <= 0) return this.Info(`No tiene cantidad suficiente para transferir`);
            if(!this.modalTransferItem.quantityTransfer) return this.Info(`La cantidad a transferir es obligatoria`);
            if(this.modalTransferItem.quantityTransfer > this.modalTransferItem.quantity) return this.Info(`La cantidad a transferir es superior a la cantidad disponible`);

            this.Loading();
            axios.patch(`/inventory/${this.Local.id}/item/transfer`, this.modalTransferItem).then( ({data}) => {
                this.inventory = data.inventory;
                if(!this.attachedSublocals) 
                    this.Info(`Se ha transferido ${this.modalTransferItem.quantityTransfer} ${this.modalTransferItem.unit} de ${this.modalTransferItem.title} a ${this.modalTransferItem.toItem.title}`);
                else
                    this.Info(`Se ha transferido ${this.modalTransferItem.quantityTransfer} ${this.modalTransferItem.unit} de ${this.modalTransferItem.title} al local ${data.to_local}`)
                return this.modalTransferItem = null;
            }).catch(err => {
                this.Response(err);
                if(err.response.data.message) this.Info(err.response.data.message);
            });
        },
        TransferItemFromFranchise(){
            let validation = true
            if(this.modalTransferInventory.inventory.length === 0) return this.Info("Debe ingresar al menos 1 item para transferir inventario.")
            this.modalTransferInventory.inventory.forEach(element => {

                if(!element.item.hasOwnProperty('quantityTransfer')){
                    validation = false
                    return this.Info("Debe ingresar un valor a transferir.")
                } 
                if(isNaN(element.item.quantityTransfer)){
                    validation = false
                    return this.Info("Debe ingresar un valor a transferir.")
                } 
                if(element.item.quantityTransfer === 0){
                    validation = false
                    return this.Info("Valor a transferir debe ser mayor a 0.")
                }
                
                if(element.item.quantityTransfer > element.item.quantity){
                    validation = false
                    return this.Info(`${element.item.title} : La cantidad a transferir no puede ser mayor a la cantidad actual del Item, intente nuevamente.`)
                }
            });

            if(validation) {
                this.Loading();
                axios.patch(`/inventory/${this.Local.id}/item/transfer`, this.modalTransferInventory).then( ({data}) => {
                    this.inventory = data.inventory;
                    let msg = ''
                    this.modalTransferInventory.inventory.forEach(element => {
                       msg += `<li>Se ha transferido ${element.item.quantityTransfer} ${element.item.unit} de ${element.item.title}.</li>`
                    });
                    
                    this.Info(`Transferencia de inventario hacia ${data.to_local} <br><br>
                    <ul> ${msg} </ul>`)
                    
                    return this.modalTransferInventory = null;
                }).catch(err => {
                    this.Response(err);
                    if(err.response.data.message) this.Info(err.response.data.message);
                });
            }

        },        
        ClickModalAddStockTransfer(modal){
            
            if(modal === 'TF') this.modalTransferInventory = { 'inventory' : [] }
            if(modal === 'AD') this.modalAddMasiveStock = { 'inventory' : [] }
            this.$nextTick(() => {this.$refs.CurrentItem.CleanCurrentItem()});
            this.LoadItemsToSelect()
        },
        AssignItemTransfer(item){
            this.$set(this.modalTransferItem, 'toItem', item);
            this.$forceUpdate();
        },
        ResetStock(all){
            this.Loading();
            axios.post(`/inventory/${this.Local.id}/reset${all ? '/all' : ''}`).then( ({data}) => {
                //this.UpdateInventory();
                this.inventory = data;
                this.Response(data);
            }).catch(err => {
                this.Response(err);
            });
        },
        OpenModalTransferItem(item){
            this.modalTransferItem = Util.Copy(item);
        },
        ExportWaste(){
            this.Loading();
            axios.post(`/inventory/waste/${this.Local.id}/export`, this.rangeWaste, {responseType: 'blob'}).then(res => {
                this.Response(res);
                this.Util.DownloadFile(res, `merma_${Util.Today().date.replace(/\//g, '-')}.xls`);
            }).catch(err => {
                this.Response(err);
            });
        },
        DeleteItemFromModal(index){
            this.$refs.CurrentItem.CleanCurrentItem()
            if(this.modalTransferInventory) this.modalTransferInventory.inventory.splice(index, 1)
            if(this.modalAddMasiveStock) this.modalAddMasiveStock.inventory.splice(index, 1)
        },
        LoadItemsToSelect(){
            console.log("LOAD ITEMS");
            if(!this.inventory) return;
            let itemSelect = [];
            for(let itemName in this.inventory){
                let item = this.inventory[itemName];
                if(!this.modalEdit || (item.title != this.modalEdit.title)){
                    itemSelect.push({text: `${item.title} (${item.unit ? item.quantity+' '+item.unit : item.quantity})`, value: {
                        dataitem : item },
                        class : item.quantity <= 0 ? 'error-color' : ''
                    },);
                }
            }
            // this.$refs.CurrentItem.CleanCurrentItem()
            this.itemsToArray = itemSelect;
        },
        OnInventoryTransactionDeleted(transaction) {
            this.history = this.history.filter(entry => entry.id !== transaction.id);
            if(this.modalItem)
            {
                if(this.modalItem.history) this.$set(this.modalItem, 'history', this.modalItem.history.filter(entry => entry.id !== transaction.id));
                if(this.modalItem.history_costs) this.$set(this.modalItem, 'history_costs', this.modalItem.history_costs.filter(entry => entry.id !== transaction.id));
                this.$set(this.modalItem, 'stats', null);
                this.$set(this.modalItem, 'providers', null);
            }
            if(this.tab == 'Merma') 
            {
                let callback = !this.modalItemWaste ? null : () => {
                    let history = this.modalItemWaste.history;
                    this.modalItemWaste = this.itemsWaste.items[this.modalItemWaste.data] ? this.itemsWaste.items[this.modalItemWaste.data] : null;
                    if(history) this.$set(this.modalItemWaste, 'history', history.filter(entry => entry.id !== transaction.id));
                };
                this.SearchItemsWaste(callback);
            }
            if(this.modalItemResumenHistory && this.modalItemResumenHistory.history)
                this.$set(this.modalItemResumenHistory, 'history', this.modalItemResumenHistory.history.filter(entry => entry.id !== transaction.id));
            
            if(this.tab == 'Resumen')
                this.Search();
            
        },
        handleValueChanged(value) {
            if (this.modalItem.tab === 'Resumen') {
                this.LoadItemStats(value);
            }
        }
    },
    computed: {

        inventorySelectItems() {
            var ret = [];
            for(var key in this.inventory)
            {
                var item = this.inventory[key];
                ret.push({
                    text: item.title + (item.unit ? ` (${item.unit})` : ''),
                    value: item.title
                });
            }
            return this.Util.NaturalSort(ret, 'text');
        },
        spaces(){
            var ret = {General: {text: 'General', value: 'General'}};
            for(var key in this.inventory)
            {
                var space = this.inventory[key].space;
                if(!space) space = 'General';
                if(!ret[space]) ret[space] = {text: space, value: space};
            }
            return Object.values(ret);
        },
        spacesTransfer(){
            if(!this.modalTransferItem && !this.modalTransferInventory && !this.modalAddMasiveStock) return;
            let ret = [];
            if(this.attachedSublocals){
                let sublocals = Util.Copy(this.attachedSublocals);
                if(this.attachedFranchises) sublocals = sublocals.concat(this.attachedFranchises);
                for(let key in sublocals){
                    let subLocal = sublocals[key];
                    ret.push({text: subLocal.name , value: subLocal.id});
                }

                return ret;
            }

            if(!this.spaces) return;

            return this.spaces.filter( (space) => space.value != this.modalTransferItem.space);
        },
        currentInventory() {
            var ret = [];
            for(var key in this.inventory)
            {
                var item = this.inventory[key];
                if(!this.search || key.toUpperCase().indexOf(this.search.toUpperCase()) > -1)
                {
                    if(!item.performance) item.performance = 100;

                    if(this.orderSpace){
                        if(item.space == this.orderSpace) ret.push(item);
                    }
                }
            }

            /*
            let inventory = Object.values(this.inventory);
            function getQuantityItemEnable(subitems){
                let countSubItemsEnable = [];
                subitems.forEach( subitem => {
                    let findItem = inventory.find( item => item.title == subitem.title);
                    if(findItem){
                        let inventorySubItemPerformance = (findItem.performance? findItem.performance : 100) / 100;
                        let inventorySubItemQuantity = (parseFloat(findItem.quantity * inventorySubItemPerformance)).toFixed(3);
                        let quantityEnable = (inventorySubItemQuantity / subitem.quantity);
                        countSubItemsEnable.push(parseFloat(quantityEnable));
                    }else{
                        countSubItemsEnable.push(0);
                    }
                });
                return Math.min(...countSubItemsEnable) >= 0? Math.min(...countSubItemsEnable) : null;
            }

            ret.forEach(item => {
                if(item.subitems && item.subitems.length > 0){
                    //Tiene subítems
                    let quantityItemEnable = getQuantityItemEnable(item.subitems);
                    if(quantityItemEnable !== null) item.quantity = quantityItemEnable;
                }
            });
            */

            if(this.orderBy == 'QUANTITY')
            {
                ret.sort((a, b) => {
                    return b.quantity - a.quantity;
                });
            }
            else if(this.orderBy == 'NAME')
            {
                ret = this.Util.NaturalSort(ret, 'title');
            }

            var sections = {};
            var others = {title: 'Otros', items: []};

            ret.forEach(item => {
                var section = item.section ? item.section : 'Otros';

                if(section == 'Otros')
                {
                    others.items.push(item);
                }
                else
                {
                    if(!sections[section]) {
                        sections[section] = {
                            title: section,
                            items: []
                        };
                    }
                    sections[section].items.push(item);
                }
                
            });

            sections = Object.values(sections);
            sections = this.Util.NaturalSort(sections, 'title');
            sections.push(others);

            for(var i = sections.length-1; i >= 0; i--)
            {
                if(!sections[i].items.length) sections.splice(i, 1);
            }

            return sections;
        },
        inventoryTransfer(){
            if(!this.modalTransferItem.toSpace) return;

            let ret = [];
            for(let key in this.inventory){
                let item = this.inventory[key];
                if(!item.performance) item.performance = 100;
                if(item.space == this.modalTransferItem.toSpace) ret.push({text: item.title , value: item});
            }

            const firstRecomendation = ret.find((item) => {
                const title = item.text.toLowerCase();
                return title.includes(this.modalTransferItem.title.toLowerCase());
            });

            if(!this.modalTransferItem.toItem) this.modalTransferItem.toItem = firstRecomendation? firstRecomendation.value : null;
            return ret;
        },
        sections() {
            var ret = {Otros: {text: 'Otros', value: 'Otros'}};
            for(var key in this.inventory)
            {
                var section = this.inventory[key].section;
                if(!section) section = 'Otros';
                if(!ret[section]) ret[section] = {text: section, value: section};
            }
            return Object.values(ret);
        },
        itemsMenuSelect(){
            console.log("menu select");
            if(!this.inventory) return;
            let itemSelect = [];
            for(let itemName in this.inventory){
                let item = this.inventory[itemName];
                if(!this.modalEdit || (item.title != this.modalEdit.title)){
                    itemSelect.push({text: item.title, value: {
                        title: item.title,
                        unit: item.unit,
                        stock : item.quantity,
                        dataitem : item }
                    },);
                }
            }
            return itemSelect;
        },
        visibleProviders() {
            if(!this.providers) return [];
            var ret = [];
            for(var key in this.providers)
            {
                if(this.Util.InString(`${this.providers[key].name} ${key}`, this.searchProvider))
                    ret.push(this.providers[key]);
            }
            return this.Util.NaturalSort(ret, 'name');
        },
        invoice() {
            if(!this.modalInvoice) return null;
            if(!this.modalInvoice.inventory.length) return null;

            var items = [];
            var subtotal = 0;
            var iva = 0;
            var taxes = 0;
            var discount = 0;
            var deliveryFee = 0;

            for(var i = 0; i < this.modalInvoice.inventory.length; i++)
            {
                var item = this.modalInvoice.inventory[i];
                if(item.quantity <= 0 || item.price <= 0) return null;
                var itemQuantity = item.quantity;
                var itemPrice = item.price;
                var itemTotal = item.quantity * item.price;
                subtotal += itemTotal;

                var itemTax = 0;
                var itemTaxPercentage = isNaN(item.tax) ? 0 : item.tax;
                if(itemTaxPercentage >= 0) 
                    itemTax = (itemTaxPercentage / 100) * itemTotal;
                else
                    itemTax = -itemTaxPercentage;
                taxes += itemTax;
                
                var itemIVA = 0;
                if(item.iva)
                    itemIVA = 0.19 * itemTotal;
                iva += itemIVA;

                var itemDiscount = 0;
                var itemDiscountPercentage = isNaN(item.discount) ? 0 : item.discount;
                if(itemDiscountPercentage >= 0)
                    itemDiscount = (itemDiscountPercentage / 100) * itemTotal;
                else
                    itemDiscount = -itemDiscountPercentage;    
                discount += itemDiscount;

                var itemDeliveryFee = 0;
                var itemDeliveryFeePercentage = isNaN(item.delivery_fee) ? 0 : item.delivery_fee;
                if(itemDeliveryFeePercentage >= 0)
                    itemDeliveryFee = (itemDeliveryFeePercentage / 100) * itemTotal;
                else
                    itemDeliveryFee = -itemDeliveryFeePercentage;
                deliveryFee += itemDeliveryFee;

                var entryTotal =  itemTotal + itemIVA + itemTax + itemDeliveryFee - itemDiscount;
                if(entryTotal < 0) return null;
                items.push({
                    item: item.item,
                    quantity: itemQuantity,
                    price: itemPrice,
                    tax: itemTax,
                    taxPercentage: itemTaxPercentage,
                    iva: itemIVA,
                    ivaPercentage: item.iva ? 19 : 0,
                    discount: itemDiscount,
                    discountPercentage: itemDiscountPercentage,
                    deliveryFee: itemDeliveryFee,
                    deliveryFeePercentage: itemDeliveryFeePercentage,
                    subtotal: itemTotal,
                    total: entryTotal,
                    unitCost: this.Util.RoundFloat(entryTotal / itemQuantity),
                    unit: this.inventory[item.item] && this.inventory[item.item].unit ? this.inventory[item.item].unit : null
                });

            }

            return {
                number: this.modalInvoice.number,
                date: this.modalInvoice.date,
                provider: {
                    rut: this.modalInvoice.provider.rut,
                    name: this.modalInvoice.provider.name
                },
                items: items,
                total: {
                    subtotal: subtotal,
                    iva: iva,
                    taxes: taxes,
                    discount: discount,
                    deliveryFee: deliveryFee,
                    total: subtotal + iva + taxes + deliveryFee - discount
                },
                files: this.modalInvoice.files,
                pay_method: this.modalInvoice.pending ? 'PENDING_PAYMENT' : this.modalInvoice.pay_method,
                comment: this.modalInvoice.comment
            };
        },
        providersItems() {
            if(!this.providers) return null;
            var ret = [{text: 'Sin proveedor', value: null}];
            for(var key in this.providers)
            {
                var name = this.providers[key].name;
                ret.push({text: `${name} - ${key}`, value: {rut: key, name: name}});
            }
            return ret;
        },
        canCreateProvider() {
            return !!(this.Auth.role.indexOf('admin') > -1 || this.Auth.restrictions.create_providers);
        },
        canEditProvider() {
            return !!(this.Auth.role.indexOf('admin') > -1 || this.Auth.restrictions.edit_providers);
        },
        canDeleteProvider() {
            return !!(this.Auth.role.indexOf('admin') > -1);
        },
        canCreateInvoices() {
            return !!(this.Auth.role.indexOf('admin') > -1 || this.Auth.restrictions.create_inventory_invoices);
        },
        canPayInvoices() {
            return !!(this.Auth.role.indexOf('admin') > -1 || this.Auth.restrictions.pay_pending_inventory_invoices);
        },
        filteredItemResumen(){
            if(!this.searchResumen) return this.stats.items;
            let items = [];

            for(let key in this.stats.items){
                if(this.Util.InString(this.stats.items[key].data, this.searchResumen)) items.push(this.stats.items[key]);
            }

            return items;
        },
    },
    watch : {
        showConfirm : function(n,o){
            this.modalConfirm.showAgain = n
        }
    },
    sockets: {
        INVENTORY_UPDATED() {
            this.UpdateInventory();
        }
    }
}

</script>

<style>

</style>
