<template>
<modal v-model="modal" dir="right" :title="title" :modalClass="['fill', 'right md h100']">

    <input-field v-if="tab == 'Locales'" slot="options"
    inputClass="secondary"
    v-model="searchLocals"
    placeholder="Buscar..."
    pre="search"
    cleareable>
    </input-field>

    <input-field v-if="tab == 'Restricciones'" slot="options"
    inputClass="secondary"
    v-model="searchRestrictions"
    placeholder="Buscar..."
    pre="search"
    cleareable
    :timeout="200"
    :icon="mobile ? 'search' : ''"
    useHeader
    @OnChange="SearchRestrictions">
    </input-field>

    <tabs v-model="tab" v-if="account" :tabs="tabs[account.role]" :keyByTab="true"
    @OnTabLocales="LoadLocals">
                
        <!-- Account General -->
        <div slot="General" class="pa20">
            <div>
                
                <div class="bold mb20 well">{{account.user}}</div>
                
                <input-field class="mb15"
                v-model="account.display_name"
                label="Nombre">
                </input-field>

                <input-field class="mb15"
                v-model="account.email"
                label="Email"
                placeholder="(Opcional)">
                </input-field>

                <input-field
                v-model="account.phone"
                label="Teléfono"
                placeholder="(Opcional)">
                </input-field>

                <input-image v-if="account.role == 'admin' || account.role == 'sub_admin'" class="mt15"
                v-model="account.image" :crop="true" 
                path="accounts/"
                label="Imagen"></input-image>

            </div>

            <div v-if="Local && Local.modules.module_turns && ['local_admin', 'cashier', 'waiter', 'delivery'].indexOf(account.role) > -1">
                <div class="separator mt20 mb20"></div>
                <label class="mb10">
                    <input type="checkbox" v-model="account.turns_enabled"> Puede hacer turnos <div class="checkmark"></div>
                </label>
                <label class="mb10" v-if="account.turns_enabled">
                    <input type="checkbox" v-model="account.turns_ghost_close"> Cierre fantasma <div class="checkmark"></div>
                </label>
            </div>

            <div v-if="(Local && account.role == 'local_admin') || account.delivery_enabled">
                <div class="separator mt20 mb20"></div>
                <label class="mb10" v-if="Local && account.role == 'local_admin'">
                    <input type="checkbox" v-model="account.delivery_enabled"> Puede hacer delivery <div class="checkmark"></div>
                </label>

                <label class="" v-if="account.delivery_enabled">
                    <input type="checkbox" v-model="account.external_delivery"> Delivery externo <div class="checkmark"></div>
                </label>
            </div>

            

        </div>

        <!-- Account admin reportes -->
        <div slot="Reportes" class="pa20" v-if="account.reports">
            
            
            <!--
            <div class="flex align-center">
                <div class="bold flex-fill">Reportes Programados</div>
                <i class="options icon-dots-vertical" @click="Options([
                    {text: 'Programar Reporte', class: 'selected-color', function: () => {modalReport = {}}}
                ])"></i>
            </div>
            

            <div class="secondary-text mb10">Envía un reporte automático al email asociado a la cuenta a la hora y día seleccionado.</div>
            -->

            <div class="secondary-text ta-center mt10" v-if="!account.reports.length">No hay reportes programados</div>
            <div class="well selectable mb10"
            v-for="(report, index) in account.reports"
            :key="index"
            @click="modalReport = Util.Copy(report)">
                <div class="fill flex">
                    <div v-html="ReportToString(report)" class="flex-fill"></div>
                    <i class="options icon-trash" @click.stop="Confirm({
                        title: '¿Eliminar reporte programado definitivamente?',
                        text: 'Eliminar',
                        class: 'error-color',
                        function: () => {DeleteReport(index)}
                    })"></i>
                </div>
            </div>

        </div>

        <!-- Account admin alertas -->
        <div slot="Alertas" v-if="account.alerts">
            <div class="section">

                <!--
                <div class="flex align-center">
                    <div class="bold flex-fill">Alertas Programadas</div>
                    <i class="options icon-dots-vertical" @click="Options([
                        {text: 'Programar Alerta', class: 'selected-color', function: () => {modalAlert = {send_email: true, send_mobile: true}}}
                    ])"></i>
                </div>
                -->

                <!-- <div class="secondary-text mb10">Envía alertas al email asociado a la cuenta cuando se cumplen las condiciones.</div> -->
                <div v-if="!account.alerts.length" class="secondary-text ta-center mt10">No hay alertas programadas</div>

                <div class="well mb10 selectable"
                v-for="(alert, index) in account.alerts"
                :key="index"
                @click="modalAlert=Util.Copy(alert)">
                    <div class="fill flex">
                        <div v-html="AlertToString(alert)" class="flex-fill"></div>
                        <i class="options icon-trash" @click.stop="Confirm({
                            text: 'Eliminar',
                            title: '¿Eliminar alerta programada?',
                            class: 'error-color',
                            function: () => {DeleteAlert(index)}
                        })"></i>
                    </div>
                    
                </div>

            </div>
        </div>

        <!-- permisos -->
        <div slot="Restricciones" class="pa20" id="restrictions-list">
            <div class="block" v-if="account.role == 'local_admin'">
                <div class="f18 mb20">Restricciones de cuenta</div>
                <div v-if="account.restrictions_admin">
                    <label class="mb10 restriction-option">
                        <input type="checkbox" v-model="account.restrictions_admin.dashboard_enabled"> Sección Resumen habilitada<div class="checkmark"></div>
                    </label>

                    <label class="mb10 restriction-option">
                        <input type="checkbox" v-model="account.restrictions_admin.transactions_enabled"> Sección Transacciones habilitada<div class="checkmark"></div>
                    </label>

                    <label class="mb10 restriction-option">
                        <input type="checkbox" v-model="account.restrictions_admin.inventory_enabled"> Sección Inventario habilitada<div class="checkmark"></div>
                    </label>

                    <label class="mb10 restriction-option" v-if="Local.type=='FRANCHISE'">
                        <input type="checkbox" v-model="account.restrictions_admin.locals_enabled"> Sección Locales habilitada<div class="checkmark"></div>
                    </label>

                    <label class="mb10 restriction-option" v-if="Local.type != 'FRANCHISE'">
                        <input type="checkbox" v-model="account.restrictions_admin.expenses_enabled"> Sección Gastos habilitada<div class="checkmark"></div>
                    </label>

                    <label class="mb10 restriction-option" v-if="Local.type != 'FRANCHISE'">
                        <input type="checkbox" v-model="account.restrictions_admin.facturas_enabled"> Sección Facturas Electrónicas habilitada<div class="checkmark"></div>
                    </label>

                    <label class="mb10 restriction-option" v-if="Local.type != 'FRANCHISE'">
                        <input type="checkbox" v-model="account.restrictions_admin.payments_enabled"> Sección Pagos Pendientes habilitada<div class="checkmark"></div>
                    </label>

                    <label class="mb10 restriction-option" v-if="Local.type != 'FRANCHISE'">
                        <input type="checkbox" v-model="account.restrictions_admin.turns_enabled"> Sección Turnos habilitada<div class="checkmark"></div>
                    </label>

                    <label class="mb10 restriction-option">
                        <input type="checkbox" v-model="account.restrictions_admin.customers_enabled"> Sección Clientes habilitada<div class="checkmark"></div>
                    </label>

                    <label class="mb10 restriction-option">
                        <input type="checkbox" v-model="account.restrictions_admin.menu_enabled"> Sección Menú habilitada<div class="checkmark"></div>
                    </label>

                    <label class="mb10 restriction-option" v-if="Local.type != 'FRANCHISE'">
                        <input type="checkbox" v-model="account.restrictions_admin.coupons_enabled"> Sección Cupones habilitada<div class="checkmark"></div>
                    </label>

                    <label class="mb10 restriction-option">
                        <input type="checkbox" v-model="account.restrictions_admin.settings_enabled"> Sección Ajustes habilitada<div class="checkmark"></div>
                    </label>

                    <div class="ml20 mb15" v-show="account.restrictions_admin.settings_enabled">
                        <div class="f15 mb5">Ajustes</div>
                        <div class="secondary-text mb15">Subsecciones de Ajustes</div>

                        <label class="mb10 restriction-option">
                            <input type="checkbox" v-model="account.restrictions_admin.settings_accounts_enabled"> Sub Sección Cuentas habilitada<div class="checkmark"></div>
                        </label>

                        <label class="mb10 restriction-option" v-if="Local.type != 'FRANCHISE'">
                            <input type="checkbox" v-model="account.restrictions_admin.settings_tables_enabled"> Sub Sección Mesas habilitada<div class="checkmark"></div>
                        </label>

                        <label class="mb10 restriction-option">
                            <input type="checkbox" v-model="account.restrictions_admin.settings_online_orders_enabled"> Sub Sección Pedidos Online habilitada<div class="checkmark"></div>
                        </label>

                        <label class="mb10 restriction-option">
                            <input type="checkbox" v-model="account.restrictions_admin.settings_menu_virtual_enabled"> Sub Sección Menú Virtual habilitada<div class="checkmark"></div>
                        </label>

                        <label class="mb10 restriction-option">
                            <input type="checkbox" v-model="account.restrictions_admin.settings_sii_enabled"> Sub Sección SII habilitada<div class="checkmark"></div>
                        </label>
                    </div>

                    <label class="mb10 restriction-option" v-if="Local.type != 'FRANCHISE'">
                        <input type="checkbox" v-model="account.restrictions_admin.orders_enabled"> Sección Pedidos habilitada<div class="checkmark"></div>
                    </label>
                </div>
            </div>

            <div class="block" v-if="account.role != 'local_admin'">
                <div class="f18 mb20">Restricciones de Transacciones</div>
                <label class="mb10 restriction-option" v-if="['cashier', 'kitchen'].indexOf(account.role) > -1">
                    <input type="checkbox" v-model="account.create_transactions"> Puede ingresar transacciones (abrir y operar mesas mesas)<div class="checkmark"></div>
                </label>
                <label class="mb10 restriction-option" v-if="account.role == 'cashier'">
                    <input type="checkbox" v-model="account.create_past_transactions"> Puede crear transacciones de fechas pasadas<div class="checkmark"></div>
                </label>
                <label class="mb10 restriction-option" v-if="account.role == 'cashier' || account.role == 'waiter'">
                    <input type="checkbox" v-model="account.transactions_turn_only"> Puede ingresar transacciones solo con un turno activo <div class="checkmark"></div>
                </label>
                <label class="mb10 restriction-option" v-if="['cashier', 'waiter', 'kitchen'].indexOf(account.role) > -1">
                    <input type="checkbox" v-model="account.apply_discount"> Puede aplicar un descuento en el POS <div class="checkmark"></div>
                </label>
                <label class="mb10 restriction-option" v-if="['cashier', 'waiter', 'kitchen'].indexOf(account.role) > -1">
                    <input type="checkbox" v-model="account.apply_coupon"> Puede aplicar un cupón en el POS <div class="checkmark"></div>
                </label>
                <label class="mb10 restriction-option" v-if="['cashier', 'waiter', 'kitchen'].indexOf(account.role) > -1">
                    <input type="checkbox" v-model="account.send_transactions"> Puede despachar transacciones (delivery) <div class="checkmark"></div>
                </label>
                <label class="mb10 restriction-option">
                    <input type="checkbox" v-model="account.cancel_transactions"> Puede cancelar transacciones <div class="checkmark"></div>
                </label>
                <label class="mb10 restriction-option">
                    <input type="checkbox" v-model="account.complete_transactions"> Puede completar transacciones (cerrar mesas)<div class="checkmark"></div>
                </label>
                <label class="mb10 restriction-option" v-if="account.role == 'cashier' || account.role == 'waiter'">
                    <input type="checkbox" v-model="account.complete_transactions_turn_only"> Puede completar transacciones solo con un turno activo <div class="checkmark"></div>
                </label>
                <label class="mb10 restriction-option" v-if="account.role == 'delivery'">
                    <input type="checkbox" v-model="account.complete_transactions_unpaid"> Puede completar transacciones no pagadas<div class="checkmark"></div>
                </label>
                <label class="mb10 restriction-option" v-if="account.role == 'cashier'">
                    <input type="checkbox" v-model="account.cashier_shared_transactions"> Puede ver las transacciones ingresadas por otras cuentas<div class="checkmark"></div>
                </label>
                <label class="mb10 restriction-option" v-if="['cashier', 'kitchen'].indexOf(account.role) > -1">
                    <input type="checkbox" v-model="account.edit_transactions"> Puede editar el pedido de transacciones pendientes (y cambiar el tipo de pedido)<div class="checkmark"></div>
                </label>
                <label class="mb10 restriction-option" v-if="['cashier', 'kitchen', 'waiter'].indexOf(account.role) > -1">
                    <input type="checkbox" v-model="account.edit_previous_items"> Puede editar/quitar items ingresados anteriormente<div class="checkmark"></div>
                </label>
                <label class="mb10 restriction-option">
                    <input type="checkbox" v-model="account.edit_transactions_payment_online">Puede editar una transacción con pago online <div class="checkmark"></div>
                </label>
                <label class="mb10 restriction-option" v-if="account.role != 'waiter'">
                    <input type="checkbox" v-model="account.edit_transactions_payment"> Puede editar el método de pago de transacciones pendientes <div class="checkmark"></div>
                </label>
                <label class="mb10 restriction-option">
                    <input type="checkbox" v-model="account.edit_tips"> Puede editar las propinas de un pedido <div class="checkmark"></div>
                </label>
                <label class="mb10 restriction-option" v-if="['cashier', 'waiter', 'kitchen'].indexOf(account.role) > -1">
                    <input type="checkbox" v-model="account.edit_order_status"> Puede cambiar el estado de un pedido e items (marcar pedido como preparado, pedido para retiro listo o items como preparados/entregados) <div class="checkmark"></div>
                </label>
                <label class="mb10 restriction-option" v-if="['cashier', 'kitchen'].indexOf(account.role) > -1">
                    <input type="checkbox" v-model="account.can_edit_name_client"> Puede editar nombre de clientes registrados<div class="checkmark"></div>
                </label>
                <label class="mb10 restriction-option">
                    <input type="checkbox" v-model="account.can_reprint"> Puede re imprimir vouchers de transacciones <div class="checkmark"></div>
                </label>

                <template v-if="account.can_reprint">   
                    <label class="mb10 ml35 restriction-option">
                        <input type="checkbox" value="precuenta" v-model="account.reprint_options"> Pre cuenta <div class="checkmark"></div>
                    </label>             
                    <label class="mb10 ml35 restriction-option">
                        <input type="checkbox" value="voucher" v-model="account.reprint_options"> Voucher <div class="checkmark"></div>
                    </label>
                    <label class="mb10 ml35 restriction-option">
                        <input type="checkbox" value="kitchen" v-model="account.reprint_options"> Cocina <div class="checkmark"></div>
                    </label>
                    <label class="mb10 ml35 restriction-option">
                        <input type="checkbox" value="delivery" v-model="account.reprint_options"> Delivery <div class="checkmark"></div>
                    </label>
                </template>

                
                <input-select v-if="account.role == 'cashier'" class="mt15 restriction-option"
                v-model="account.view_transactions"
                label="Puede ver transacciones completadas"
                :items="[
                    {value: 'NONE', text: 'Ninguna'}, 
                    {value: 'ALL', text: 'Todas'}, 
                    {value: 'LAST_6', text: 'Solo de las últimas 6 horas'},
                    {value: 'LAST_12', text: 'Solo de las últimas 12 horas'},
                    {value: 'LAST_24', text: 'Solo de las últimas 24 horas'},
                    {value: 'LAST_48', text: 'Solo de las últimos 2 días'},
                    {value: 'LAST_168', text: 'Solo de la últimos 7 días'},
                    {value: 'LAST_336', text: 'Solo de la últimos 14 días'},
                    {value: 'LAST_720', text: 'Solo de las últimos 30 días'}
                ]">
                </input-select>
            </div>

            <div class="block" v-if="account.role =='cashier' || account.role == 'kitchen'">
                <div class="f18 mb20">Restricciones de Pedidos Online</div>
                <label class="mb10 restriction-option">
                    <input type="checkbox" v-model="account.disable_iap"> Puede activar/desactivar pedidos online <div class="checkmark"></div>
                </label>
                <label class="mb10 restriction-option">
                    <input type="checkbox" v-model="account.auto_accept_iap"> Puede activar/desactivar auto-aceptar pedidos online <div class="checkmark"></div>
                </label>
                <label class="mb10 restriction-option">
                    <input type="checkbox" v-model="account.block_customers"> Puede bloquear clientes <div class="checkmark"></div>
                </label>
                <label class="mb10 restriction-option">
                    <input type="checkbox" v-model="account.disable_iap_pya"> Puede desactivar PedidosYa Courier de la página de comercio<div class="checkmark"></div>
                </label>
                <label class="mb10 restriction-option">
                    <input type="checkbox" v-model="account.print_iap_voucher"> Puede imprimir voucher de pedidos online antes de que sea aceptado <div class="checkmark"></div>
                </label>
            </div>

            <div class="block" v-if="account.role == 'waiter' || account.role =='cashier' || account.role == 'kitchen'">
                <div class="f18 mb20">Restricciones de Mesas</div>
                <label class="mb10 restriction-option">
                    <input type="checkbox" v-model="account.change_waiter"> Puede transferir la mesa a otro mesero <div class="checkmark"></div>
                </label>
                <label class="mb10 restriction-option">
                    <input type="checkbox" v-model="account.change_table"> Puede cambiar la mesa de un pedido <div class="checkmark"></div>
                </label>
                 <label class="restriction-option" v-if="account.role == 'waiter'">
                    <input type="checkbox" v-model="account.waiter_multi_tables"> Puede ver y editar mesas abiertas por otros meseros <div class="checkmark"></div>
                </label>
            </div>

            
            <div class="block" v-if="account.sections_tables && (account.role == 'cashier' || account.role == 'waiter' || account.role == 'kitchen')">
                <div class="f18 restriction-option">Mesas Restringidas</div>
                <div class="secondary-text mb20">Las mesas de las secciones restringidas se ocultarán (solo para esta cuenta).</div>
                <label v-for="(sectionTables, index) in account.sections_tables"
                :key="index"
                class="mb10">
                    <input type="checkbox" v-model="account.tables_restrictions[sectionTables.section]">{{sectionTables.section}} <div class="checkmark"></div>
                </label>
            </div>

            <div class="block" v-if="account.role == 'cashier'">
                <div class="f18 mb20">Restricciones de Gastos</div>
                <label class="mb10 restriction-option">
                    <input type="checkbox" v-model="account.create_expenses"> Puede ingresar gastos <div class="checkmark"></div>
                </label>
                <label class="mb10 restriction-option">
                    <input type="checkbox" v-model="account.create_inventory"> Puede ingresar inventario <div class="checkmark"></div>
                </label>
                <label class="mb10 restriction-option">
                    <input type="checkbox" v-model="account.can_edit_cost_unit"> Puede modificar costos unitarios <div class="checkmark"></div>
                </label>
                <label class="mb10 restriction-option">
                    <input type="checkbox" v-model="account.reduce_inventory"> Puede reducir inventario (merma) <div class="checkmark"></div>
                </label>
                <label class="mb10 restriction-option">
                    <input type="checkbox" v-model="account.create_inventory_invoices"> Puede ingresar facturas de inventario <div class="checkmark"></div>
                </label>
                <label class="mb10 restriction-option">
                    <input type="checkbox" v-model="account.pay_pending_inventory_invoices"> Puede liquidar facturas de inventario pendientes <div class="checkmark"></div>
                </label>
                <label class="mb10 restriction-option">
                    <input type="checkbox" v-model="account.create_providers"> Puede crear proveedores <div class="checkmark"></div>
                </label>
                <label class="mb10 restriction-option">
                    <input type="checkbox" v-model="account.edit_providers"> Puede editar proveedores <div class="checkmark"></div>
                </label>
                <label class="restriction-option">
                    <input type="checkbox" v-model="account.pay_pending_payments"> Puede liquidar pagos pendientes <div class="checkmark"></div>
                </label>
            </div>

            <div class="block" v-if="account.role == 'cashier'">
                <div class="f18 mb20">Restricciones de Factura Electrónica</div>
                <label class="mb10 restriction-option">
                    <input type="checkbox" v-model="account.can_create_facturas"> Puede emitir factura electrónica <div class="checkmark"></div>
                </label>
            </div>

            <div class="block" v-if="account.role == 'cashier' || account.role == 'waiter'">
                <div class="f18 mb20">Restricciones de Turno</div>
                <label class="mb10 restriction-option">
                    <input type="checkbox" v-model="account.turn_complete_pending_transactions"> Solo puede terminar el turno sin transacciones pendientes <div class="checkmark"></div>
                </label>
                <label class="mb10 restriction-option">
                    <input type="checkbox" v-model="account.requires_password"> Requiere autorización para terminar turno <div class="checkmark"></div>
                </label>
                <input-field v-if="account.requires_password" class="mb15 restriction-option"
                v-model="account.turn_password"
                label="Contraseña"
                placeholder="(Para terminar el turno)"
                type="password">
                </input-field>
                <label class="mb10 restriction-option">
                    <input type="checkbox" v-model="account.turns_ghost_close"> Cierre fantasma <div class="checkmark"></div>
                </label>
                <input-select class="restriction-option"
                v-model="account.turn_details"
                label="Puede ver el turno activo"
                :items="[
                    {value: 'NONE', text: 'No puede ver detalles del turno'}, 
                    {value: 'ALL', text: 'Puede ver todos los detalles'}, 
                    {value: 'TIPS', text: 'Puede ver solo las propinas'},
                ]">
                </input-select>
            </div>

            <div class="block" v-if="account.sections && account.role == 'cashier'">
                <div class="f18 mb20">Restricciones de Menu</div>
                <label class="restriction-option">
                    <input type="checkbox" v-model="account.edit_items_availability"> Puede editar la disponibilidad de los items del menu <div class="checkmark"></div>
                </label>
            </div>

            <div class="block" v-if="account.sections && (account.role == 'cashier' || account.role == 'waiter')">
                <div class="f18 restriction-option">Secciones Restringidas</div>
                <div class="secondary-text mb20">Los ítems del menu de las secciones restringidas se ocultarán en el ingreso de pedidos (solo para esta cuenta).</div>
                <label v-for="(section, index) in account.sections"
                :key="index"
                class="mb10">
                    <input type="checkbox" v-model="account.menu_restrictions[section]">{{section}} <div class="checkmark"></div>
                </label>
            </div>
        </div>

        <!-- permisos de locales -->
        <div slot="Locales">

            <div class="section" v-if="locals">
                <label class="mb10" v-for="local in filteredLocals" :key="local.id" @click="ToggleLocal(local)">
                    <div class="flex align-center">
                        <div :class="`check ${account.locals[local.id]?'selected':''} mr10`"></div> {{local.name}} <span class="secondary-text ml5" v-show="local.type=='FRANCHISE'">(Franquicia)</span>
                    </div>
                </label>
            </div>

        </div>

    </tabs>

    
    <button slot="actions" class="secondary" @click="modalAlert = {send_email: true, send_mobile: true}" v-show="tab == 'Alertas'">Programar Alerta</button>
    <button slot="actions" class="secondary" @click="modalReport = {}" v-show="tab == 'Reportes'">Programar Reporte</button>
    <button slot="actions" class="secondary" @click="modalPassword={}" v-show="tab == 'General'">Cambiar Contraseña</button>
    <button slot="actions" class="primary purple" @click="SaveAccount()">Guardar</button>

<modal v-model="modalReport" dir="right" :modalClass="['fill', 'right sm h100']" :title="modalReport && modalReport.id ? 'Editar Reporte' : 'Nuevo Reporte'">
    <div v-if="modalReport" class="section">

        <input-select
        v-model="modalReport.send_time"
        :items="reportTimes"
        label="Hora de Envío"
        description="Determina a que hora se enviará el reporte"
        class="mb15">
        </input-select>

        <!--
        <input-field
        v-model="modalReport.start_time"
        label="Tiempo"
        description="Determina cuantas horas atrás se tomarapn los datos para reporte"
        type="int+"
        suffix=" horas"
        placeholder="Horas"
        class="mb15">
        </input-field>
        -->

        <input-select
        v-model="modalReport.start_time"
        :items="reportStartTimes"
        label="Tiempo"
        description="Determina la cantidad de horas en que se tomarán los datos"
        class="mb15"
        canType>
        </input-select>

        <div class="bold mb10">Días</div>
        <div class="secondary-text mb15">Selecciona los días que será enviado el reporte</div>

        <label  v-for="(day, key) in Util.DaysArrEs"
        :key="key"
        class="mb10">
            <input type="checkbox" v-model="modalReport[Util.DaysArr[key]]">Enviar los {{day}}
            <div class="checkmark"></div>
        </label>

    </div>
    
    <button slot="actions" class="primary purple" @click="SaveReport()">Guardar</button>

</modal>

<modal v-model="modalAlert" dir="right" :modalClass="['fill', 'sm right h100']" :title="modalAlert && modalAlert.id ? 'Editar Alerta' : 'Nueva Alerta'">

    <div v-if="modalAlert" class="section">
        <input-select
        v-model="modalAlert.event"
        :items="alertsEvents"
        label="Evento">
        </input-select>

        <div class="well secondary-text mt5" v-if="alertsInfo[modalAlert.event]">{{alertsInfo[modalAlert.event].title}}</div>

        <input-field class="mt15"
        v-model="modalAlert.title"
        label="Título"
        description="Mensaje que aparece en el encabezado de la notificación"
        placeholder="(Opcional)">
        </input-field>

        <input-field class="mt15"
        v-model="modalAlert.text_condition"
        label="Condición de Contenido"
        :description="`Envía la alerta solo si se encuentran las palabras especificadas (separadas por coma). ${alertsInfo[modalAlert.event] && alertsInfo[modalAlert.event].text_condition_description ? alertsInfo[modalAlert.event].text_condition_description : ''}`"
        placeholder="(Opcional)"
        :lines="3">
        </input-field>

        <input-select class="" v-show="modalAlert.text_condition"
        v-model="modalAlert.text_condition_inclusive"
        :items="alertTextConditionOptions">
        </input-select>
        
        <!--
        <input-field class="mt15"
        v-model="modalAlert.number_condition"
        label="Condición Numérica"
        description="Envía la alerta solo si se cumple la condición númerica. El valor númerico para transacciones corresponde al monto toal, para turnos la hora y para inventario la cantidad restante"
        type="number"
        placeholder="(Opcional)">
        </input-field>
        -->

        <div class="mt15">

            <div class="bold">Condición Numérica</div>
            <div class="secondary-text mb10">Envía la alerta solo si se cumple la condición númerica</div>

            <div class="well mini input-top border secondary-text">{{alertsInfo[modalAlert.event] && alertsInfo[modalAlert.event].number_condition_title ? alertsInfo[modalAlert.event].number_condition_title : 'Número'}}</div>

            <input-select class="input-middle"
            v-model="modalAlert.number_condition_type"
            :items="alertNumberConditionOptions">
            </input-select>

            <input-field class="input-bottom"
            v-model="modalAlert.number_condition"
            type="number"
            placeholder="(Opcional)">
            </input-field>
        </div>

    </div>

    <button slot="actions" class="primary purple" @click="SaveAlert()">Guardar</button>
</modal>

<modal v-model="modalPassword" :dir="['center', 'center']" :modalClass="['sm', 'sm']" title="Cambiar Contraseña">
    <div class="section" v-if="modalPassword">
        <input-field class="mb15"
        v-model="modalPassword.password"
        label="Nueva Contraseña"
        type="password">
        </input-field>
        <input-field
        v-model="modalPassword.password_confirm"
        label="Repetir Contraseña"
        type="password">
        </input-field>
    </div>
    
    <button v-if="modalPassword" slot="actions" class="primary purple" @click="ChangePassword()" :disabled="!modalPassword.password || !modalPassword.password_confirm || modalPassword.password !== modalPassword.password_confirm">Cambiar Contraseña</button>
    
</modal>

</modal>
</template>

<script>
export default {
    data() {
        return {
            tab: 'General',
            modal: false,
            account: null,
            title: '',
            tabs: {
                'admin': ['General'],
                'sub_admin': ['General', 'Locales'],
                'local_admin': ['General', 'Restricciones'],
                'cashier': ['General', 'Restricciones'],
                'delivery': ['General', 'Restricciones'],
                'waiter': ['General', 'Restricciones'],
                'kitchen': ['General', 'Restricciones'],
            },
            modalReport: null,
            reportTimes: [
                {text: '00:00 hrs', value: 0},
                {text: '01:00 hrs', value: 1},
                {text: '02:00 hrs', value: 2},
                {text: '03:00 hrs', value: 3},
                {text: '04:00 hrs', value: 4},
                {text: '05:00 hrs', value: 5},
                {text: '06:00 hrs', value: 6},
                {text: '07:00 hrs', value: 7},
                {text: '08:00 hrs', value: 8},
                {text: '09:00 hrs', value: 9},
                {text: '10:00 hrs', value: 10},
                {text: '11:00 hrs', value: 11},
                {text: '12:00 hrs', value: 12},
                {text: '13:00 hrs', value: 13},
                {text: '14:00 hrs', value: 14},
                {text: '15:00 hrs', value: 15},
                {text: '16:00 hrs', value: 16},
                {text: '17:00 hrs', value: 17},
                {text: '18:00 hrs', value: 18},
                {text: '19:00 hrs', value: 19},
                {text: '20:00 hrs', value: 20},
                {text: '21:00 hrs', value: 21},
                {text: '22:00 hrs', value: 22},
                {text: '23:00 hrs', value: 23},
            ],
            reportStartTimes: [
                {text: 'última 1 hora', value: 1},
                {text: 'últimas 6 horas', value: 6},
                {text: 'últimas 8 horas', value: 8},
                {text: 'últimas 12 horas', value: 12},
                {text: '1 día', value: 24, description: 'últimas 24 horas'},
                {text: '2 días', value: 48, description: 'últimas 48 horas'},
                {text: '3 días', value: 72, description: 'últimas 72 horas'},
                {text: '4 días', value: 96, description: 'últimas 96 horas'},
                {text: '5 días', value: 120, description: 'últimas 120 horas'},
                {text: '6 días', value: 144, description: 'últimas 144 horas'},
                {text: '7 días', value: 168, description: 'últimas 168 horas'},
            ],
            modalAlert: null,
            modalPassword: null,
            locals: null,
            searchLocals: '',
            searchRestrictions: '',
            alertsEvents: [
                {text: 'Transacción Ingresada', value: 'TRANSACTION_CREATED'}, 
                {text: 'Transacción Completada', value: 'TRANSACTION_COMPLETED'}, 
                {text: 'Transacción Cancelada', value: 'TRANSACTION_CANCELLED'},
                {text: 'Transacción Editada', value: 'TRANSACTION_EDIT'},
                {text: 'Transacción Editada - Productos agregados', value: 'TRANSACTION_EDIT_ADDED_ITEMS'},
                {text: 'Transacción Editada - Productos quitados', value: 'TRANSACTION_EDIT_REMOVED_ITEMS'},
                {text: 'Trasancción Descuento Ingresado', value:'TRANSACTION_DISCOUNT'},
                {text: 'Gasto Ingresado', value: 'EXPENSE_CREATED'},
                {text: 'Inventario Ingresado', value: 'INVENTORY_CREATED'},
                {text: 'Inventario Bajo', value: 'INVENTORY_TRANSACTION'},
                {text: 'Inicio de Turno', value: 'TURN_STARTED'},
                {text: 'Fin de Turno', value: 'TURN_ENDED'},
                {text: 'Pedido Online Ingresado', value: 'IAP_CREATED'},
                {text: 'Pedido Online Aceptado', value: 'IAP_ACCEPTED'},
                {text: 'Pedido Online Rechazado', value: 'IAP_REJECTED'},
                {text: 'Pedido Online Descuento Ingresado', value:'IAP_DISCOUNT'},
                {text: 'SII: Cantidad de Folios bajos', value: 'FOLIO_CONSUMED'},
                {text: 'SII: Boleta Electrónica recibida', value: 'BOLETA_RECIVED'},
                {text: 'SII: Boleta Electrónica rechazada', value: 'BOLETA_REJECTED'},
                //{text: 'SII: Resumen de Ventas Diarias recibido', value: 'RVD_RECIVED'},
                //{text: 'SII: Resumen de Ventas Diarias rechazado', value: 'RVD_REJECTED'},
            ],
            alertTextConditionOptions: [
                {text: 'Debe contener todas las palabras', value: 1},
                {text: 'Debe contener al menos una palabra', value: 0}
            ],
            alertNumberConditionOptions: [
                {text: 'Menor o igual', value: '<='},
                {text: 'Menor', value: '<'},
                {text: 'Mayor o igual', value: '>='},
                {text: 'Mayor', value: '>'},
                {text: 'Igual', value: '='},
            ],
            alertsInfo: {
                TRANSACTION_DISCOUNT: {
                    title: 'Enviar alerta cuando se ingrese un descuento por caja.',
                    text_condition_description: 'Por ejemplo el nombre de un producto específico, una cuenta o cliente.',
                    number_condition_title: 'Total del descuento ($)',
                },
                TRANSACTION_CREATED: {
                    title: 'Enviar alerta cuando se ingresa una transacción',
                    text_condition_description: 'Por ejemplo el nombre de un producto específico, una cuenta o cliente.',
                    number_condition_title: 'Total del pedido ($)',
                },
                TRANSACTION_COMPLETED: {
                    title: 'Enviar alerta cuando se completa una transacción',
                    text_condition_description: 'Por ejemplo el nombre de un producto específico, una cuenta o cliente.',
                    number_condition_title: 'Total del pedido ($)',
                },
                TRANSACTION_CANCELLED: {
                    title: 'Enviar alerta cuando se cancela una transacción',
                    text_condition_description: 'Por ejemplo el nombre de un producto específico, una cuenta o cliente.',
                    number_condition_title: 'Total del pedido ($)',
                },
                TRANSACTION_EDIT: {
                    title: 'Enviar alerta cuando se edita una transacción',
                    text_condition_description: 'Por ejemplo el nombre de un producto específico, una cuenta o cliente.',
                    number_condition_title: 'Total del pedido ($)',
                },
                TRANSACTION_EDIT_ADDED_ITEMS: {
                    title: 'Enviar alerta cuando se edita una transacción y se agregan items',
                    text_condition_description: 'Por ejemplo el nombre de un producto específico.',
                    number_condition_title: 'Total del pedido ($)',
                },
                TRANSACTION_EDIT_REMOVED_ITEMS: {
                    title: 'Enviar alerta cuando se edita una transacción y se quitan items',
                    text_condition_description: 'Por ejemplo el nombre de un producto específico.',
                    number_condition_title: 'Total del pedido ($)',
                },
                TURN_STARTED: {
                    title: 'Enviar alerta cuando una cuenta inicia un turno',
                    text_condition_description: 'Por ejemplo el nombre de una cuenta.',
                    number_condition_title: 'Hora comienzo (24hrs)',
                },
                TURN_ENDED: {
                    title: 'Enviar alerta cuando una cuenta finaliza un turno',
                    text_condition_description: 'Por ejemplo el nombre de una cuenta.',
                    number_condition_title: 'Hora término (24hrs)',
                },
                EXPENSE_CREATED: {
                    title: 'Enviar alerta cuando se ingresa un gasto',
                    text_condition_description: 'Por ejemplo el nombre del gasto, una cuenta, método de pago.',
                    number_condition_title: 'Total del gasto ($)',
                },
                INVENTORY_CREATED: {
                    title: 'Enviar alerta cuando se ingresa inventario',
                    text_condition_description: 'Por ejemplo el nombre del ítem, una cuenta, método de pago.',
                    number_condition_title: 'Total del gasto ($)',
                },
                INVENTORY_TRANSACTION: {
                    title: 'Enviar alerta según la cantidad disponible de un item de inventario',
                    text_condition_description: 'Por ejemplo el nombre del ítem o una cuenta.',
                    number_condition_title: 'Cantidad resultante (Stock)',
                },
                IAP_ACCEPTED: {
                    title: 'Enviar alerta cuando se acepta un pedido online',
                    text_condition_description: 'Por ejemplo el nombre de un producto específico o un cliente',
                    number_condition_title: 'Total del pedido ($)',
                },
                IAP_REJECTED: {
                    title: 'Enviar alerta cuando se rechaza un pedido online',
                    text_condition_description: 'Por ejemplo el nombre de un producto específico o un cliente',
                    number_condition_title: 'Total del pedido ($)',
                },
                IAP_CREATED: {
                    title: 'Enviar alerta cuando un cliente hace un pedido a través de la aplicación.',
                    text_condition_description: 'Por ejemplo el nombre de un producto específico o un cliente',
                    number_condition_title: 'Total del pedido ($)',
                },
                IAP_DISCOUNT: {
                    title: 'Enviar alerta cuando se ingrese un descuento por pedido online.',
                    text_condition_description: 'Por ejemplo el nombre de un producto específico, una cuenta o cliente.',
                    number_condition_title: 'Total del descuento ($)',
                },
                FOLIO_CONSUMED: {
                    title: 'Enviar alerta según la cantidad de folios disponibles (SII).',
                    text_condition_description: false,
                    number_condition_title: 'Folios disponibles (Cantidad)',
                },
                BOLETA_RECIVED: {
                    title: 'Enviar alerta cuando una boleta electrónica emitida fue recibida exitosamente por el SII',
                    text_condition_description: false,
                    number_condition_title: 'Total boleta ($)',
                },
                BOLETA_REJECTED: {
                    title: 'Enviar alerta cuando una boleta electrónica emitida fue rechazada por el SII',
                    text_condition_description: false,
                    number_condition_title: 'Total boleta ($)',
                },
                RVD_RECIVED: {
                    title: 'Enviar alerta cuando un Resumen de Ventas Diarias fue recibido exitosamente por el SII'
                },
                RVD_REJECTED: {
                    title: 'Enviar alerta cuando un Resumen de Ventas Diarias fue rechazado por el SII'
                }
            }
        }
    },
    mounted() {
        if(this.Local && this.Local.modules.module_reports) this.tabs.local_admin.push('Reportes');
        if(this.Local && this.Local.modules.module_reports) this.tabs.local_admin.push('Alertas');
        if((this.Auth.role == 'admin' || this.Auth.role == 'sub_admin') && (this.Local && this.Local.type == 'FRANCHISE')) this.tabs.local_admin.push('Locales');
    },
    methods: {
        Open(id) {
            this.account = null;
            this.searchLocals = '';
            this.searchRestrictions = '';
            this.Loading();
            axios.get(`/account/${id}`).then(res => {
                this.modal = true;
                if(res.data.menu_restrictions && res.data.menu_restrictions.length == 0) res.data.menu_restrictions = {};
                if(res.data.tables_restrictions && res.data.tables_restrictions.length == 0) res.data.tables_restrictions = {};
                if('locals' in res.data && (res.data.locals === null || res.data.locals.length == 0)) res.data.locals = {};
                res.data.requires_password = res.data.turn_password !== null;
                this.account = res.data;
                if(res.data && res.data.restrictions_admin) this.$set(this.account, 'restrictions_admin', res.data.restrictions_admin);
                this.title = res.data.display_name;
                this.Response(res);
            }).catch(err => {
                this.Response(err);
            });
        },
        SaveAccount() {
            
            if( ['cashier', 'waiter'].indexOf(this.account.role) > -1 && this.account.requires_password && this.account.turn_password.length < 5) return this.Info('Contraseña de turno debe tener al menos 5 caracteres');
            if(this.account.can_reprint && this.account.reprint_options.length < 1) return this.Info('Debe seleccionar una opción al habilitar "Re imprimir vouchers de transacciones."')
            if (!(['voucher', 'kitchen', 'delivery'].some(value => this.account.reprint_options.includes(value))) && this.account.can_reprint) return this.Info('Debes seleccionar además "Voucher", "Cocina" o "Delivery".')
            this.$root.$emit('Loading');
            axios.patch(`/account/${this.account.id}`, this.account).then(res => {
                this.$root.$emit('Response', res);
                this.$emit('AccountSaved', this.account.display_name);
                this.modal = false;
            }).catch(err => {
                this.$root.$emit('Response', err);
            });
        },
        ReportToString(report)
        {
            var selectedDays = '';
            var count = 0;
            for(var i = 0; i < this.Util.DaysArr.length; i++)
            {
                if(report[this.Util.DaysArr[i]] == 1) 
                {
                    selectedDays += `<li>${this.Util.DaysArrEs[i]}</li>`;
                    count++;
                }
            }
            if(count == 7) selectedDays = '<li>todos los días</li>';
            return `Enviar reporte a las <span class="bold">${report.send_time}:00 hrs</span> de las últimas <span class="bold">${report.start_time} horas</span> los días: <ul>${selectedDays}</ul>`;
        },
        AlertToString(alert)
        {
            var ret = (this.alertsInfo[alert.event] ? this.alertsInfo[alert.event].title : 'Enviar alerta con evento '+alert.event) + '<ul>';// (alert.title ? ` (${alert.title})` : '');

            if(alert.title) ret+= `<li><span class="bold">Título: </span>${alert.title}</li>`;
            if(alert.text_condition) ret+= `<li><span class="bold">${alert.text_condition_inclusive ? 'Debe contener todas' : 'Debe contener al menos una'}: </span>${alert.text_condition}</li>`;
            if(alert.number_condition !== null)
            {
                var numberTitle = this.alertsInfo[alert.event] && this.alertsInfo[alert.event].number_condition_title ? this.alertsInfo[alert.event].number_condition_title : 'Número';
                if(alert.number_condition_type == '<=') ret += `<li><span class="bold">${numberTitle}: </span>debe ser menor o igual a ${this.Util.Number(alert.number_condition)}</li>`;
                else if(alert.number_condition_type == '<') ret += `<li><span class="bold">${numberTitle}: </span>debe ser menor a ${this.Util.Number(alert.number_condition)}</li>`;
                else if(alert.number_condition_type == '>') ret += `<li><span class="bold">${numberTitle}: </span>debe ser mayor a ${this.Util.Number(alert.number_condition)}</li>`;
                else if(alert.number_condition_type == '>=') ret += `<li><span class="bold">${numberTitle}: </span>debe ser mayor o igual a ${this.Util.Number(alert.number_condition)}</li>`;
                else if(alert.number_condition_type == '=') ret += `<li><span class="bold">${numberTitle}: </span>debe ser igual a ${this.Util.Number(alert.number_condition)}</li>`;
            }

            return ret + '</ul>';
        },
        SaveReport()
        {
            if(!this.modalReport.start_time || this.modalReport.start_time > 168) return this.Info('Periodo inválido');
            var days = false;
            for(var i = 0; i < this.Util.DaysArr.length; i++)
                if(this.modalReport[this.Util.DaysArr[i]]) days = true;
            if(!days) return this.Info('Seleccionar al menos un día');

            if(this.modalReport.id)
            {
                this.$root.$emit('Loading');
                axios.patch(`/report/${this.modalReport.id}`, this.modalReport).then(res => {
                    this.modalReport = null;
                    for(var i = 0; i < this.account.reports.length; i++)
                    {
                        if(this.account.reports[i].id == res.data.id)
                            this.$set(this.account.reports, i, res.data);
                    }
                    this.$root.$emit('Response', res);
                }).catch(err => {
                    this.$root.$emit('Response', err);
                });
            }
            else
            {
                this.$root.$emit('Loading');
                axios.put(`/account/${this.account.id}/reports`, this.modalReport).then(res => {
                    this.modalReport = null;
                    this.account.reports.push(res.data);
                    this.$root.$emit('Response', res);
                }).catch(err => {
                    this.$root.$emit('Response', err);
                });
            }

            
        },
        DeleteReport(index)
        {
            this.$root.$emit('Loading');
            axios.delete(`/reports/${this.account.reports[index].id}`).then(res => {
                this.account.reports.splice(index, 1);
                this.$forceUpdate();
                this.$root.$emit('Response', res);
            }).catch(err => {
                this.$root.$emit('Response', err);
            });
        },
        SaveAlert()
        {
            /*
            for(var i = 0; i < this.modalAlert.check_word.length; i++)
                if(!this.modalAlert.check_word[i].trim()) return this.Info('Condiciones de contenido inválidas.');
            if(!this.modalAlert.check_word.length && (this.modalAlert.check_total.trim() == '' || this.modalAlert.check_total < 0)) return this.Info('Condiciones inválidas');
            */
            if(this.modalAlert.id)
            {
                this.$root.$emit('Loading');
                axios.patch(`/alert/${this.modalAlert.id}`, this.modalAlert).then(res => {
                    this.modalAlert = null;
                    for(var i = 0; i < this.account.alerts.length; i++)
                        if(this.account.alerts[i].id == res.data.id) this.$set(this.account.alerts, i, res.data);
                    this.$root.$emit('Response', res);
                }).catch(err => {
                    this.$root.$emit('Response', err);
                });
            }
            else
            {
                this.$root.$emit('Loading');
                axios.put(`/account/${this.account.id}/alerts`, this.modalAlert).then(res => {
                    this.modalAlert = null;
                    this.account.alerts.push(res.data);
                    this.$root.$emit('Response', res);
                }).catch(err => {
                    this.$root.$emit('Response', err);
                });
            }
        },
        DeleteAlert(index)
        {           
            this.$root.$emit('Loading');
            axios.delete(`/alerts/${this.account.alerts[index].id}`).then(res => {
                this.$root.$emit('Response', res);
                this.account.alerts.splice(index, 1);
                this.$forceUpdate();
            }).catch(err => {
                this.$root.$emit('Response', res);
            });
        },
        ChangePassword()
        {
            this.Loading();
            axios.patch(`/account/${this.account.id}/password`, this.modalPassword).then(res => {
                this.Info('La contraseña ha sido cambiada');
                this.modalPassword = null;
            }).catch(err => {
                this.Response(err);
            });
        },
        LoadLocals() 
        {
            if(this.locals !== null) return;

            //franchise admin
            if(this.Local && this.Local.type == 'FRANCHISE')
            {
                axios.get(`/sublocals/${this.Local.id}`).then(res => {
                    this.locals = res.data.sublocals.concat(res.data.attached, res.data.attachedFranchises);
                    this.Response(res);
                }).catch(err => {
                    this.Response(err);
                });
            }
            else
            {
                //sub admin
                this.Loading();
                axios.get('/locals').then(res => {
                    this.Response(res);
                    this.locals = res.data;
                }).catch(err => {
                    this.Response(err);
                });
            }
        },
        ToggleLocal(local)
        {
            if(this.account.locals[local.id])
                this.$delete(this.account.locals, local.id);
            else
                this.$set(this.account.locals, local.id, true);
        },
        ChangePicture()
        {
            this.Loading();
            axios.put(`/account/${this.account.id}/picture`, this.modalPicture).then(res => {
                this.Info('Imagen guardada');
                this.modalPicture = null;
            }).catch(err => {
                this.Response(err);
            });
        },
        SearchRestrictions() 
        {
            var el = document.getElementById('restrictions-list');
            var blocks = el.querySelectorAll('.block');
            blocks.forEach(block => {
                var options = block.querySelectorAll('.restriction-option');
                var showBlock = false;
                for(var i = 0; i < options.length; i++)
                {
                    var elOption = options[i];
                    var txtOption = elOption.innerText.split("\n")[0];
                    
                    var isVisible = this.Util.InString(txtOption, this.searchRestrictions);
                    elOption.style.display = isVisible ? '' : 'none';
                    if(isVisible) showBlock = true;

                    if(i == options.length-1)
                    {
                        block.style.display = showBlock ? '' : 'none';
                    }
                }
            });
        }
    },
    computed: 
    {
        filteredLocals() {
            if(!this.locals) return [];
            var ret = [];
            this.locals.forEach(local => {
                if(this.Util.InString(local.name, this.searchLocals))
                    ret.push(local);
            });
            ret.sort((a, b) => {
                return b.id - a.id;
            })
            return ret;
        },
    }
}
</script>