<template>
<div class="selectable" @click="modalColor=true">
    <div class="flex column fill">
        <div v-if="label" class="bold mb10">{{label}}</div>
        <div class="input-group input-field flex align-center" ref="input">
            <div class="input" :style="{'background-color': value}"></div>
            <div class="append" style="height: 40px">
                <i class="options icon-copy h100" @click.stop="CopyColor()"></i>
                <i class="options icon-paste h100" @click.stop="PasteColor()"></i>
                <i v-if="canRemove" class="options icon-cross h100" @click.stop="RemoveColor()"></i>
            </div>
        </div>
    </div>

    <modal v-model="modalColor" ref="modal" dir="center" :modalClass="['sm', '']" :title="desktop ? null : 'Seleccionar Color'" :domElement="$refs.input"
    :hideBackdrop="desktop" :hideActions="desktop">
        <div class="input-popover pa20 flex">
            <div class="" :acp-color="value" ref="colorPicker"></div>
            <div class="ml10" style="display: grid; grid-template-columns: 1fr 1fr; gap: 10px; row-gap: 10px; align-content: flex-start;">
                <div class="color-swatch" style="background-color: rgb(255, 73, 91)" @click="SetColor('rgb(255, 73, 91)')"></div>
                <div class="color-swatch" style="background-color: rgb(230, 1, 235)" @click="SetColor('rgb(230, 1, 235)')"></div>
                <div class="color-swatch" style="background-color: rgb(35, 144, 250)" @click="SetColor('rgb(35, 144, 250)')"></div>
                <div class="color-swatch" style="background-color: rgb(96, 106, 233)" @click="SetColor('rgb(96, 106, 233)')"></div>
                <div class="color-swatch" style="background-color: rgb(243, 187, 81)" @click="SetColor('rgb(243, 187, 81)')"></div>
                <div class="color-swatch" style="background-color: rgb(255, 141, 52)" @click="SetColor('rgb(255, 141, 52)')"></div>
                <div class="color-swatch" style="background-color: rgb(12, 198, 131)" @click="SetColor('rgb(12, 198, 131)')"></div>
                <div class="color-swatch" style="background-color: rgb(70, 185, 180)" @click="SetColor('rgb(70, 185, 180)')"></div>
                <div class="color-swatch" style="background-color: rgb(89, 8, 254)" @click="SetColor('rgb(89, 8, 254)')"></div>
                <div class="color-swatch" style="background-color: rgb(157, 159, 183)" @click="SetColor('rgb(157, 159, 183)')"></div>
            </div>
        </div>
        
        <button slot="actions" class="primary purple" @click="Accept()">Aceptar</button>
        
    </modal>
</div>
</template>

<script>
var ColorPicker = require('a-color-picker');
export default {
    props: {
        value: {
            type: String,
            default: 'gray'
        },
        label: {
            type: String,
            default: 'Color'
        },
        canRemove : {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            modalColor: false,
            colorPicker: null,
            currentColor: null,
        }
    },
    mounted() {
        
    },
    methods: {
        Accept() {
            this.$emit('input', this.currentColor);
            this.modalColor = false;
        },
        CopyColor() {
            localStorage.color = this.value;
        },
        PasteColor() {
            if(localStorage.color)
            {
                this.currentColor = localStorage.color;
                this.$emit('input', this.currentColor);
            }
        },
        SetColor(color) {
            this.$emit('input', color);
            this.modalColor = false;
        },
        RemoveColor(){
            this.$emit('input', '');
        }
    },
    beforeDestroy() {
        document.body.removeChild(this.$refs.modal.$el);
    },
    watch: {
        modalColor: function(n, o) {
            if(n) {
                this.$nextTick(() => {
                    this.colorPicker = ColorPicker.from(this.$refs.colorPicker,
                    {
                        slBarSize: [232,100],
                        showHSL: false
                    }).on('change', (picker, color) => {
                        this.currentColor = color;
                    });
                });
            }
            else
            {
                if(this.colorPicker)
                {
                    this.colorPicker[0].destroy();
                }
            }
        },
        currentColor: function(n, o) {
            if(n && this.desktop) 
            {
                this.$emit('input', n);
            }
        }
    }
}
</script>

<style>
.input-color {
    width: 100%;
    height: 30px;
    border-radius: 5px;
    border: 1px solid black;
}

.a-color-picker {
    box-shadow: none;
    display: flex;
}
.a-color-picker-row
{
    padding: 0px;
    padding-top: 5px;
    border-bottom: none;
}
.a-color-picker-preview {
    border-radius: 0px;
    height: 15px;
    width: 65px;
    margin-top: -4px;
}
.color-swatch {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    cursor: pointer;
}
</style>
