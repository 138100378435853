<template>
<modal v-model="pending_payment" :dir="['right', 'center']" :modalClass="['fill', 'sm']" title="Pago Pendiente" hideActions>
    <div class="section flex column copyable" v-if="pending_payment">
        <div class="mb15">
            <div class="bold">Pago Pendiente</div>
            <div>{{pending_payment.method}}</div>
        </div>

        <div class="flex mb15">
            <div class="flex-fill">
                <div class="bold">Transacciones</div>
                <div class="flex-fill">{{Util.Number(pending_payment.transactions.length)}}</div>
            </div>
            
            <button v-if="pending_payment.transactions.length" class="secondary" @click="$refs.transactionsList.Open(transactions)">Ver Transacciones</button>
        </div>

        <div class="mb15">
            <div class="bold">Total</div>
            <div>{{Util.Price(pending_payment.total_money)}}</div>
        </div>

        <div class="mb15">
            <div class="bold">Fecha</div>
            <div>{{Util.Date(pending_payment.date, 'LLL')}}</div>
        </div>

        <div class="mb15">
            <div class="bold">Método de Pago</div>
            <div>{{Util.PayMethod(pending_payment.pay_method)}}</div>
        </div>
        <div class="mb15">
            <div class="bold">Ingresado por</div>
            <div>{{pending_payment.custom_data.created_by}}</div>
        </div>
        <div class="mb15" v-if="pending_payment.comment">
            <div class="bold">Comentario</div>
            <div>{{pending_payment.comment}}</div>
        </div>
    </div>

    <transactions-list ref="transactionsList"></transactions-list>
</modal>
</template>
<script>
export default {
    data() {
        return {
            pending_payment: null
        }
    },
    methods: {
        Open(pending_payment) 
        {
            if(typeof pending_payment == 'number')
            {
                this.Loading();
                axios.get(`/payments/pending/transaction/${pending_payment}`).then(res => {
                    this.Response(res);
                    this.pending_payment = res.data;
                }).catch(err => {
                    console.log(err);
                    this.Response(err);
                });
            }
            else
            {
                this.pending_payment = pending_payment;
            }
        }
    },
    computed: {
        transactions() {
            var ret = [];
            if(!this.pending_payment) return ret;
            for(var i = 0; i < this.pending_payment.transactions.length; i++)
                ret.push(this.pending_payment.transactions[i].id);
            return ret;
        }
    }
}
</script>