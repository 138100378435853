<template>
<div class="flex-fill fill flex column ofy">

    <!-- Menu de opciones ... -->
    <div class="opt r">
         
        <input-field v-show="tab=='Clientes'"
        class=""
        placeholder="Buscar por nombre o teléfono..."
        v-model="search"
        pre="search"
        cleareable
        inputClass="secondary"
        :icon="mobile ? 'search' : ''"
        :timeout="300"
        useHeader
        @OnChange="Search(true)">
        </input-field>

        <input-select v-show="tab=='Clientes'" class="ml5"
        ref="sort"
        inputClass="secondary"
        @OnValueChanged="Search(true)"
        v-model="orderBy"
        :items="customerSortItems"
        :icon="mobile ? 'sort' : ''"
        :triggerFirstValueChanged="false">
        </input-select>

        <i v-show="tab=='Clientes'" class="options icon-dots-vertical ml5" @click="Options([
            {text: 'Eliminar Seleccionados', class: 'error-color', function: () => {DeleteMultiLocalCustomers()}, if: arraySelectedUsers.length > 0, confirm : '¿Deseas eliminar los clientes seleccionados?'},
            {text: 'Deseleccionar Todos', class: 'selected-color', function: () => {
                arraySelectedUsers = []
                selectionMode = false
            }, if: arraySelectedUsers.length > 0},
            {text: 'Unificar Clientes', function: () => {modalUnifyCustomers = { selected: arrayUnified[0], array: []}}, if: arraySelectedUsers.length > 1},
            {text: 'Exportar Clientes', function: () => {ExportCustomers()}},
            {text: 'Importar Clientes', function: () => {modalImportCustomers = {name: 1, phone: 3}}},
            {text: 'Crear Cliente', class: 'selected-color', function: () => {modalLocalCustomer={}}}
        ])"></i>

        <input-range inputClass="secondary" v-show="tab == 'Evaluaciones'" 
        v-model="rangeSurvey"
        @OnValueChanged="tab == 'Evaluaciones' ? $refs.surveyResults.Search($event) : null"
        :triggerFirstValue="false">
        </input-range>

    </div>

    <tabs v-if="tabs" class="fill" v-model="tab" :tabs="tabs" :keyByTab="true"
    @OnTabMapa="!summary ? LoadMap() : null"
    @OnTabBloqueados="LoadBannedCustomers(true)"
    @OnTabEvaluaciones="$refs.surveyResults.Search(rangeSurvey)">

        <!-- Tab con lista de clientes -->
        <vertical-content slot="Clientes" class="pa-body" ref="verticalContentCustomers" @OnBottom="Search()">

            <div class="secondary-text ta-center mt10" v-if="!users || !users.length">No hay clientes</div>
            <div v-else>
                
                <!--
                <div class="flex align-center mb20">
                    <div class="f18">{{group.title}} </div>
                    <div class="counter ml5">{{Util.Number(group.clients.length)}}</div>    
                </div>
                -->
                
                <div class="section mb10 selectable" 
                v-for="(user, index2) in users"
                :key="index2"
                v-show="search.trim() === '' || Util.InString(user.name, search) || user.phone.indexOf(search) > -1"
                @click="mobile && selectedClients?SelectClient(user):$refs.customerDetails.Open(user.id)">
                    <div class="flex fill align-center">

                        <avatar class="mr10"
                        :label="user.name"
                        color="AUTO">
                        </avatar>

                        
                        <div class="flex column">
                            <div class="bold">{{user.name}}</div>
                            <div class="secondary-text"><span class="bold">Visitas: </span>{{user.transactions.length}} <span v-if="user.mean">({{Util.Price(user.mean)}} por visita)</span></div>
                            <div class="secondary-text" v-if="user.transactions.length"><span class="bold">Periodo: </span>{{Util.Date(user.first_transaction_at, 'L')}} - {{Util.Date(user.last_transaction_at, 'L')}} <span v-if="user.age">({{Util.SecondsToTime(user.age, 'SINGLE')}})</span></div>
                        </div>
                        <div class="flex-fill"></div>
                        <div class="bold mr10">{{Util.Price(user.total_money)}}</div>
                        <i v-if="!selectionMode" class="options icon-dots-vertical ml5" @click.stop="Options([
                            {text: 'Eliminar', function: () => {
                                $refs.customerDetails.DeleteLocalCustomer(user.phone)
                            }, class : 'error-color', confirm : '¿Deseas eliminar el cliente?'},
                            {text: 'Seleccionar ', function: () => {
                                selectionMode = true
                                arraySelectedUsers.push(user.id)
                            }},
                        ])"></i>


                        <!-- Es correcto como esta el checkmark??? -->
                        <label class="" v-else @click.stop="">
                            <input type="checkbox" :value="user.id" v-model="arraySelectedUsers">  <div class="checkmark"></div>
                        </label>

                        <!--<i class="icon icon-arrow-right-o c4"></i>-->
                        <!-- <div v-if="mobile && selectedClients" :class="selectedClients[user.id_user]?'check selected':'check'"></div> -->
                    </div>
                </div>
            </div>

            <div class="flex-fill"></div>

            <div class="bottom-info ta-center selectable" v-if="selectedClients" @click="Options([
                {text: 'Cancelar Selección', class: 'error-color', if: selectedClients !== null, function: () => {selectedClients=null}},
                {text: 'Enviar Mensaje', class: 'selected-color', if: arrSelected.length > 0, function: () => {modalSendMessage=true}},
                {text: 'Enviar Cupón', class: 'selected-color', if: arrSelected.length > 0, function: () => {$refs.benefitForm.New()}},
            ])">
                <span>{{arrSelected.length}} clientes seleccionados</span>
            </div>

        </vertical-content>

        <vertical-content slot="Bloqueados" @OnBottom="LoadBannedCustomers()" class="pa-body">
            <div class="secondary-text ta-center mt10" v-if="!bannedCustomers.length">No hay datos</div>
            <div v-else>
                <div class="section mb10"
                v-for="ban in bannedCustomers"
                :key="ban.id">
                    <div class="fill flex align-center">
                        <div class="flex column flex-fill">
                            <div v-if="ban.phone" class="bold">{{ban.phone}}</div>
                            <div v-if="ban.name" class="secondary-text"><span class="bold">Nombre: </span>{{ban.name}}</div>
                            <div v-if="ban.email" class="secondary-text"><span class="bold">Email: </span>{{ban.email}}</div>
                            <div class="secondary-text"><span class="bold">Fecha: </span>{{Util.Date(ban.created_at)}}</div>
                        </div>
                        <i class="options icon-dots-vertical" @click="Options([
                            {text: 'Eliminar', class: 'error-color', function: () => {DeleteBan(ban)}}
                        ])"></i>
                    </div>
                </div>
            </div>
        </vertical-content>

        <!-- Tab con mapa de calor -->
        <div slot="Mapa" class="fill">
            <div class="fill" style="overflow: hidden" v-if="summary">
                <div class="secondary-text ta-center mt10" v-if="!summary.locations.length">No hay direcciones. Los datos del mapa se actualizarán una vez al día tomando las direcciones de las transacciones completadas exitosamente.</div>
                <map-component v-else
                :pois="summary.pois"
                :useMarker="true"
                :useHeatMap="true"
                :heatMapPoints="summary.locations">
                </map-component>
            </div>
        </div>

        <!-- evaluaciones -->
        <survey-results-view slot="Evaluaciones" class="fill" ref="surveyResults"></survey-results-view>

    </tabs>

    <!-- Modal para crear un nuevo cliente -->
    <modal v-model="modalLocalCustomer" :dir="['right', 'center']" :modalClass="['fill', 'sm']" :title="modalLocalCustomer ? 'Nuevo Cliente' : ''">
        <div class="section" v-if="modalLocalCustomer">
            <input-field class="mb15"
            v-model="modalLocalCustomer.phone"
            label="Teléfono">
            </input-field>

            <input-field class="mb15"
            v-model="modalLocalCustomer.name"
            label="Nombre">
            </input-field>

            <input-field class="mb15"
            v-model="modalLocalCustomer.rut"
            label="Rut"
            placeholder="(Opcional)">
            </input-field>

            <input-field class="mb15"
            v-model="modalLocalCustomer.address"
            label="Dirección"
            placeholder="(Opcional)">
            </input-field>

            <input-field
            v-model="modalLocalCustomer.email"
            label="Email"
            placeholder="(Opcional)">
            </input-field>
        </div>
        
        <button slot="actions" @click="SaveLocalCustomer()" class="primary purple">Crear</button>
        
    </modal>

    <!-- Modal con los detalles y estadisticas de un cliente -->
    <customer-details ref="customerDetails"
    @OnCustomerEdit="OnCustomerEdit($event)"
    @OnCustomerDeleted="OnCustomerDeleted($event)"></customer-details>

    <!-- Modal para importar clientes a través de un excel -->
    <modal v-model="modalImportCustomers" :dir="['right', 'center']" :modalClass="['fill', 'sm']" title="Importar Clientes">
        <div class="section" v-if="modalImportCustomers">
            
            <input-field class="mb15"
            v-model="modalImportCustomers.name"
            label="Columna Nombre"
            type="number">
            </input-field>

            <input-field class="mb15"
            v-model="modalImportCustomers.phone"
            label="Columna Teléfono"
            type="number">
            </input-field>

            <input-field class="mb15"
            v-model="modalImportCustomers.rut"
            label="Columna Rut"
            type="number"
            placeholder="(Opcional)">
            </input-field>

            <input-field class="mb15"
            v-model="modalImportCustomers.address"
            label="Columna Dirección"
            type="number"
            placeholder="(Opcional)">
            </input-field>
            <input-field
            v-model="modalImportCustomers.email"
            label="Columna Email"
            type="number"
            placeholder="(Opcional)">
            </input-field>
        </div>
        
        <button-file v-if="modalImportCustomers" accept=".xlsx, .xls, .csv"
        :type="null" slot="actions"
        @FileSelected="$set(modalImportCustomers, 'file', $event)">
            <button :class="`secondary ${modalImportCustomers.file ? '' : ''}`">{{modalImportCustomers.file ? modalImportCustomers.file.name : 'Seleccionar Archivo'}}</button>
        </button-file>
        <button slot="actions" class="primary purple" @click="ImportCustomers()">Importar</button>
        
    </modal>

        <!-- Modal para importar clientes a través de un excel -->
    <modal v-model="modalUnifyCustomers" :dir="['right', 'center']" :modalClass="['fill', 'md']" title="Unificar Clientes">
        <div class="section " v-if="modalUnifyCustomers">

                <div class="secondary-text mb10" style="width: 100%;">Selecciona el cliente que deseas mantener. Los demás clientes serán eliminados y sus estadísticas se transferirán al cliente que seleccionaste.</div>


            <div class="well mb10 selectable"
                v-for="(user, index2) in arrayUnified"
                :key="index2">
                <label>
                    <div class="flex fill align-center">
                        <avatar class="mr10"
                        :label="user.name"
                        color="AUTO">
                        </avatar>
                        <div class="flex column">
                            <div><span class="bold">{{user.name}}</span> <span class="secondary-text ml5">{{ user.rut }}</span></div>
                            <div class="secondary-text" v-if="user.phone"><span class="bold">Teléfono: </span> {{ user.phone }}</div>
                        </div>
                        <div class="flex-fill"></div>
                        <div class="bold mr10">{{Util.Price(user.total_money)}}</div>
                            <input type="radio" :value="user" v-model="modalUnifyCustomers.selected">  <div class="checkmark"></div>
                    </div>
                </label>
                </div>
        </div>
        
        <button slot="actions" class="primary purple" @click="UnifyCustomers()">Unificar clientes</button>
        
    </modal>

</div>
</template>

<script>
import SurveyResultsView from './SurveyResultsView.vue';
export default {
  components: { SurveyResultsView },
    data() {
        return {
            tabs: null,
            tab: null,
            users: [],
            selectedUser: null,
            menu: null,
            categories: {},
            modalBenefit: false,
            clientsType: 'INFLUYE',
            customClients: [],
            clientMessage: '',
            displayClients: null,
            modalClientMessage: false,
            clientsGrouped: false,
            sort: 'level',
            selectedInfluyeClients: {},
            selectedCustomClients: {},
            shiftSelect: null,
            modalSendMessage: null,
            modalLocalCustomer: null,
            search: '',
            searchLocalCustomer: '',
            previousCustomers: null,

            orderBy: 'TOTAL',
            groupBy: 'NO_GROUP',
            selectedClients: null,
            message: {},
            modalWhatsappMessage: null,
            whatsappMessage: '',
            modalImportCustomers: null,
            summary: null,

            //total consumido por todos los clientes registrados
            totalMoney: 0,
            //promedio entre la primerda compra y ultima de los clientes
            activeTime: 0,

            bannedCustomers: [],
            customerSortItems: [
                {text: 'Total Gastado', value: 'TOTAL'}, 
                {text: 'Promedio de Compra', value: 'MEAN'}, 
                {text: 'Visitas', value: 'COUNT'}, 
                {text: 'Última Compra', value: 'LAST_TRANSACTION'}, 
                {text: 'Antiguedad', value: 'AGE'}, 
                //{text: 'Valor de Cliente', value: 'VALUE'}, 
                {text: 'Nombre', value: 'NAME'}
            ],
            rangeSurvey: {option: 'MONTH', index: 12},

            // Habilita la selección multiple para eliminación de cliente. Aparece checkbox
            selectionMode: false,
            arraySelectedUsers : [],
            modalUnifyCustomers : null,
            arrayUnified : []

        }
    },
    mounted() {

        this.tabs = [];
        this.tabs.push('Clientes');
        if(this.Local.modules.module_store_plus) this.tabs.push('Evaluaciones');
        if(this.Local.type == 'LOCAL') this.tabs.push('Bloqueados');
        this.tabs.push('Mapa');

    },
    methods: {
        UnifyCustomers(){
            this.modalUnifyCustomers.array = this.arraySelectedUsers
            this.Loading();
            axios.post(`/customers/unify/${this.Local.id}`, this.modalUnifyCustomers).then(res => {
                if(res.data.status){
                    this.users = []
                    this.modalUnifyCustomers = null
                    this.arraySelectedUsers = []
                    this.arrayUnified = []
                    this.Info(res.data.message);
                    this.Response(res);
                    this.Search(true)
                }else{
                    this.Info('Ha ocurrido un error al unificar clientes!');
                }

            }).catch(err => {
                this.Response(err);
            });
        },
        DeleteMultiLocalCustomers(){
            if(this.arraySelectedUsers.length === 0){
                this.Info("NO HAY CLIENTES SELECCIONADOS PARA ELIMINAR");
                return
            }
            this.Loading();
            let data = {
                'data' : {
                    'ids' : this.arraySelectedUsers
                }
            }
            axios.delete(`/customer/${this.Local.id}`, data).then(res => {
                this.selectionMode = false;
                this.arraySelectedUsers = [];

                if(this.arraySelectedUsers.length === 1){
                    this.Info(`Cliente ha eliminado`);
                }else if(this.arraySelectedUsers.length > 1){
                    this.Info(`Clientes han sido elimnados`);
                }
                this.OnCustomersMultiDeleted(res.data)
                this.Response(res)

            }).catch(err => {
                this.Response(err);
            });

        },

        Search(clear)
        {
            if(clear)
            {
                this.users = [];
                this.$refs.verticalContentCustomers.Reset();
            }

            this.Loading();
            axios.get(`/customers/${this.Local.id}?orderBy=${this.orderBy}&skip=${this.users.length}&search=${this.search}`).then(res => {
                this.Response(res)
                this.users = this.users.concat(res.data);
            }).catch(err => {
                this.Response(err);
            });
        },
        /*
        Ordena los clientes segun el criterio seleccionado (orderBy)
        */
        SortClients() 
        {
            if(!this.users) return;
            switch(this.orderBy)
            {
                //por total gastado
                case 'TOTAL':
                    this.users.sort((a, b) => {
                        return b.total_money - a.total_money;
                    });
                    break;
                //por nombre
                case 'NAME':
                    this.users.sort((a, b) => {
                        var nameA = a.name.toUpperCase();
                        var nameB = b.name.toUpperCase();
                        if(nameA < nameB) return -1;
                        else if(nameA > nameB) return 1;
                        return 0;
                    });
                    break;
                //última visita primero
                case 'LAST_TRANSACTION':
                    this.users.sort((a, b) => {
                        return b.last_transaction_at - a.last_transaction_at;
                    });
                    break;
                //Promedio de compra
                case 'MEAN':
                    this.users.sort((a, b) => {
                        return (b.mean) - (a.mean);
                    });
                    break;
                //visitas
                case 'COUNT':
                    this.users.sort((a, b) => {
                        return (b.transactions.length) - (a.transactions.length);
                    });
                    break;
                //tiempo como cliente
                case 'AGE':
                    this.users.sort((a, b) => {
                        return (b.age) - (a.age);
                    });
                    break;
                //Valor del cliente
                case 'VALUE':
                    this.users.sort((a, b) => {
                        return (b.value) - (a.value);
                    });
                    break;
            }
         
            this.displayClients = [{
                title: 'Clientes',
                description: 'Clientes que han realizado compras en el local y las han validado con su cuenta Influye.',
                clients: this.users
            }];      
            
        },
        /*
        Crea un cliente del local y lo agrega a la lista
        */
        SaveLocalCustomer()
        {
            this.Loading();
            axios.put(`/customer/${this.Local.id}`, this.modalLocalCustomer).then(res => {
                this.Response(res);
                this.modalLocalCustomer = null;
                this.users.push(res.data);
                this.SortClients();
            }).catch(err => {
                this.Response(err);
            });
        },
        ExportCustomers() {
            this.Loading();
            axios.get(`/customers/${this.Local.id}/export`, {responseType: 'blob'}).then(res => {
                this.Response(res);
                var moment = require('moment');
                var today = moment();
                this.Util.DownloadFile(res, `clientes-${today.format('DD-MM-YYYY')}.xls`);
            }).catch(err => {
                this.Response(err);
            });
        },
        ImportCustomers()
        {
            if(!this.modalImportCustomers.file) return this.Info('Se debe seleccionar un archivo');
            if(!this.modalImportCustomers.name) return this.Info('Columna nombre inválida');
            if(!this.modalImportCustomers.phone) return this.Info('Columna teléfono inválida');

            var formData = new FormData();
            formData.append("file", this.modalImportCustomers.file);
            formData.append('name', this.modalImportCustomers.name);
            formData.append('phone', this.modalImportCustomers.phone);
            formData.append('rut', this.modalImportCustomers.rut);
            formData.append('address', this.modalImportCustomers.address);
            formData.append('email', this.modalImportCustomers.email);
            this.Loading();
            axios.post(`/customers/${this.Local.id}/import`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then(res => {
                this.Info('Clientes importados correctamente');
                this.modalImportCustomers = null;
                this.Search()
            }).catch(err => {
                this.Response(err);
            });
        },
        /*
        Descarga los datos para el mapa de calor
        */
        LoadMap()
        {
            this.Loading();
            axios.get(`/customers/${this.Local.id}/summary`).then(res => {
                this.$root.$emit('Response', res);
                this.summary = res.data;
                console.log(res.data);
            }).catch(err => {
                this.$root.$emit('Response', err);
            });
        },
        /*
            Busca en la lista de clientes y edita el correspondiente
            @param customer datos del cliente editado
        */
        OnCustomerEdit(customer)
        {
            for(var i = 0; i < this.users.length; i++)
            {
                if(this.users[i].id === customer.id)
                {
                    this.$set(this.users, i, customer);
                    break;
                }
            }
        },

        /*
            Busca en la lista de clientes y elimina el correspondiente
            @param customer datos del cliente editado
        */
        OnCustomerDeleted(customer)
        {
            for(var i = 0; i < this.users.length; i++)
            {
                if(this.users[i].id === customer.id)
                {
                    this.users.splice(i, 1);
                    break;
                }
            }
        },

        /*
            Busca en la lista de clientes y elimina el correspondiente
            @param customer array con los clientes eliminados
        */
        OnCustomersMultiDeleted(customer)
        {
            customer.forEach(element => {
                for(var i = 0; i < this.users.length; i++)
                {
                    if(this.users[i].id === element.id)
                    {
                        this.users.splice(i, 1);
                        break;
                    }
                }
            });
        },

        LoadBannedCustomers(emptyOnly)
        {
            if((emptyOnly && !this.bannedCustomers.length) || !emptyOnly)
            this.Loading();
            axios.get(`/customers/${this.Local.id}/blocked${this.bannedCustomers.length ? '/'+this.bannedCustomers[this.bannedCustomers.length-1].id : ''}`).then(res => {
                this.Response(res);
                this.bannedCustomers = this.bannedCustomers.concat(res.data);
            }).catch(err => {
                this.Response(err);
            });
        },

        DeleteBan(ban)
        {
            this.Loading();
            axios.delete(`/customers/${this.Local.id}/blocked/${ban.id}`).then(res => {
                for(var i = 0; i < this.bannedCustomers.length; i++)
                {
                    if(this.bannedCustomers[i].id == res.data.id)
                    {
                        this.bannedCustomers.splice(i, 1);
                        break;
                    }
                }
                this.Info('Se ha eliminado el bloqueo');
            }).catch(err => {
                this.Response(err);
            });
        }

    },
    computed : {
        
    },
    watch: {
        arraySelectedUsers: function(n, o) 
        {
            if(this.arraySelectedUsers.length === 0){
                this.selectionMode = false
                this.arrayUnified = []
            }
            this.arrayUnified = this.arrayUnified.filter(user => n.includes(user.id));
            this.users.forEach(user => {
                if (n.includes(user.id) && !this.arrayUnified.some(u => u.id === user.id)) {
                    this.arrayUnified.push(user);
                }
            });
        }
    }

}
</script>

<style>

</style>
