<template>
<modal v-model="modal" dir="right" :modalClass="['fill', 'xl right h100']" title="Editar Pedido" hideActions>

    <input-select
    v-if="platforms && platforms.length"
    slot="options"
    :icon="mobile? 'menu': ''"
    :items="platforms"
    :triggerFirstValueChanged="false"
    v-model="selectedPlatform"
    @OnValueChanged="ChangePlatform($event)">
    </input-select>
    <!--
    <i class="options icon-dots-vertical" slot="options" @click="Options([
        {text: 'Cambiar para Servir', function: () => {ChangeType('SERVE')}},
        {text: 'Cambiar para Llevar', function: () => {ChangeType('TAKEAWAY')}},
        {text: 'Cambiar para Delivery', function: () => {ChangeType('DELIVERY')}},
    ])"></i>
    -->
    
    <div :class="`fill flex ${mobile ? 'column' : ''}`" v-if="modal">
        <div class="fill relative">
            

            <items-selection class="absolute trbl0"
            bodyClass="pl20 pr20 bgbg1"
            ref="itemsSelection"
            :menu="menu" 
            :showSelected="false"
            :callbackOnly="true"
            :disableUnavailable="true"
            :selectModifiers="true"
            :overwrites="filteredOverwrites"
            :timestamp="timestamp"
            :inventory="inventory"
            typeSale="TAKEAWAY"
            @OnItemAdded="AddItem($event)"
            @OnSelectionClosed="currentEditItem=null"></items-selection>
            
            
        </div>
        <div :style="desktop ? {width: '500px'} : {}" :class="`flex column ${desktop ? 'bl' : ''}`">
            <div class="flex-fill flex column ofy pa10" v-if="desktop">
                <div class="f18 mb10">Nuevos pedidos</div>
                <div>
                    <div class="well mb10 selectable unshrink"
                    v-for="(item, index) in orders"
                    :key="index"
                    v-longclick="() => {EditItem(item, index)}"
                    @click="RemoveItem(item)">
                        <div class="order-item">
                            <div>{{item.quantity}}</div>
                            <div>
                                <div>{{item.title}}</div>
                                <ul v-if="item.modifiers.length > 0">
                                    <li :class="modifier.type" v-for="(modifier, indexMod) in Util.ModifiersList(item)" :key="indexMod">{{modifier.value}}
                                        <span class="tag-total-modifiers" v-if="modifier.count > 1">{{modifier.count}}</span>
                                    </li>
                                </ul>
                            </div>
                            <div>{{Util.Price(item.quantity * item.price)}}</div>
                        </div>
                    </div> 
                </div>

                
                <div v-if="modal.orders.length">

                    <div class="separator mb20 mt20"></div>

                    <div class="f18 mb10">Pedidos anteriores</div>

                    <div :class="`well mb10 unshrink ${canEditOld ? 'selectable' : ''}`"
                    v-for="(item, index) in modal.orders"
                    :key="index"
                    v-longclick="() => {canEditOld ? EditItem(item, index, 'OLD') : null}"
                    @click="canEditOld ? RemoveOldItem(item) : null">
                        <div class="order-item">
                            <div>{{Util.Number(item.quantity)}}</div>
                            <div>
                                <div>{{item.title}}</div>
                                <ul v-if="item.modifiers.length > 0">
                                    <li :class="modifier.type" v-for="(modifier, indexMod) in Util.ModifiersList(item)" :key="indexMod">{{modifier.value}}
                                        <span class="tag-total-modifiers" v-if="modifier.count > 1">{{modifier.count}}</span>
                                    </li>
                                </ul>
                            </div>
                            <div>{{Util.Price(item.quantity * item.price)}}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="sticky pa10 flex column bt" style="bottom: 0;">
                <div class="flex mb5" style="justify-content: space-around">
                    <div class="flex column secondary-text align-center">
                        <div class="bold">Anterior </div>
                        <div>{{Util.Price(oldTotal)}}</div>
                    </div>
                    <div class="flex column secondary-text align-center" v-if="oldTotal-total.old > 0">
                        <div class="bold">Quitado </div>
                        <div>-{{Util.Price(oldTotal - total.old)}}</div>
                    </div>
                    <div class="flex column secondary-text align-center" v-if="total.new">
                        <div class="bold">Agregado </div>
                        <div>{{Util.Price(total.new)}}</div>
                    </div>
                    <div class="flex column secondary-text align-center" v-if="total.total != oldTotal">
                        <div class="bold">Total </div>
                        <div>{{Util.Price(total.total)}}</div>
                    </div>
                </div>
                
                <div class="flex mt5">
                    <button class="secondary mr10 flex-fill" @click="modalCurrentOrder=true" v-if="mobile">Ver Pedido</button>
                    <button class="primary purple flex-fill" @click="Edit()">{{total.new && oldTotal-total.old > 0 ? 'Editar Pedido' : (total.new ? 'Agregar Items' : (oldTotal-total.old > 0 ? 'Quitar Items' : 'Editar Pedido'))}}</button>
                </div>
            </div>

        </div>
        <!--
        <div v-if="false && mobile" class="sticky pa10 flex column unshrink bt bgbg" style="bottom: 0;">
            <div class="flex mb5" style="justify-content: space-around">
                <div class="flex column secondary-text align-center">
                    <div class="bold">Anterior </div>
                    <div>{{Util.Price(oldTotal)}}</div>
                </div>
                <div class="flex column secondary-text align-center" v-if="oldTotal-total.old > 0">
                    <div class="bold">Quitado </div>
                    <div>-{{Util.Price(oldTotal - total.old)}}</div>
                </div>
                <div class="flex column secondary-text align-center" v-if="total.new">
                    <div class="bold">Agregado </div>
                    <div>{{Util.Price(total.new)}}</div>
                </div>
                <div class="flex column secondary-text align-center" v-if="total.total != oldTotal">
                    <div class="bold">Total </div>
                    <div>{{Util.Price(total.total)}}</div>
                </div>
            </div>
            <div class="flex">
                <button class="secondary mr5 flex-fill" @click="modalCurrentOrder=true">Ver Pedido</button>
                <button class="primary purple ml5 flex-fill" @click="Edit()">{{total.new && oldTotal-total.old > 0 ? 'Editar Pedido' : (total.new ? 'Agregar Items' : (oldTotal-total.old > 0 ? 'Quitar Items' : 'Editar Pedido'))}}</button>
            </div>
        </div>
        -->
    </div>

    <modal v-if="mobile" v-model="modalCurrentOrder" dir="up" modalClass="up fill" title="Pedido" hideActions>
        <div class="pa20" v-if="modalCurrentOrder">
            <div class="f18 mb20">Nuevos pedidos</div>
            <div class="well mb10 selectable unshrink"
            v-for="(item, index) in orders"
            :key="index"
            v-longclick="() => {EditItem(item, index)}"
            @click="RemoveItem(item)">
                <div class="order-item">
                    <div>{{item.quantity}}</div>
                    <div>
                        <div>{{item.title}}</div>
                        <ul v-if="item.modifiers.length > 0">
                            <li :class="modifier.type" v-for="(modifier, indexMod) in Util.ModifiersList(item)" :key="indexMod">{{modifier.value}}
                                <span class="tag-total-modifiers" v-if="modifier.count > 1">{{modifier.count}}</span>
                            </li>
                        </ul>
                    </div>
                    <div>{{Util.Price(item.quantity * item.price)}}</div>
                </div>
            </div> 

            <div class="separator mb20 mt20"></div>

            <div class="f18 mb20">Pedidos anteriores</div>
            <div :class="`well mb10 unshrink ${canEditOld ? 'selectable' : ''}`"
            v-for="(item, index) in modal.orders"
            :key="index"
            v-longclick="() => {canEditOld ? EditItem(item, index, 'OLD') : null}"
            @click="canEditOld ? RemoveOldItem(item) : null">
                <div class="order-item">
                    <div>{{Util.Number(item.quantity)}}</div>
                    <div>
                        <div>{{item.title}}</div>
                        <ul v-if="item.modifiers.length > 0">
                            <li :class="modifier.type" v-for="(modifier, indexMod) in Util.ModifiersList(item)" :key="indexMod">{{modifier.value}}
                                <span class="tag-total-modifiers" v-if="modifier.count > 1">{{modifier.count}}</span>
                            </li>
                        </ul>
                    </div>
                    <div>{{Util.Price(item.quantity * item.price)}}</div>
                </div>
            </div>
        </div>
    </modal>

</modal>
</template>

<script>
export default {
    props: {
        menu: {
			type: Array,
			default: null
		},
		overwrites: {
			type: Array,
			default: null
		},
		inventory: {
			type: [Object, Array], 
			default: null
		},
		timestamp: {
			type: Number,
			default: 0
		},
    },
    data() {
        return {
            modal: null,
            orders: {},
            oldTotal: 0,
            modalCurrentOrder: false,
            timer: null,
            currentEditItem: null,
            typeMenu: 'SCHEDULE',
            dictPlatforms: {
                SCHEDULE: 'Ninguno',
                pedidos_ya: 'PedidosYa',
                uber_eats: 'Uber Eats',
            },
            selectedPlatform: null
        }
    }, 
    mounted() {
        /*
        this.timer = setInterval(() => {
            if(this.timestamp) this.timestamp++;
        }, 1000);
        */
    },
    methods: {
        Open(transaction)
        {
            console.log(this.platforms);
            let platform = localStorage.getItem('platform') || 'SCHEDULE';
            let isValidPlatform = this.platforms.some(p => p.value === platform);
            this.typeMenu = this.selectedPlatform = isValidPlatform ? platform : 'SCHEDULE';
            this.orders = {};
            this.modal = JSON.parse(JSON.stringify(transaction));
            this.oldTotal = 0;
            for(var key in transaction.orders)
                this.oldTotal += transaction.orders[key].price * transaction.orders[key].quantity;
        },
        AddItem(data)
        {
            if(this.currentEditItem)
            {
                if(this.currentEditItem.list == 'NEW')
                {
                    this.$delete(this.orders, this.currentEditItem.index);
                }
                else
                {
                    for(var i = 0; i < this.modal.orders.length; i++)
                    {
                        if(this.modal.orders[i] == this.currentEditItem.item)
                        {
                            this.modal.orders.splice(i, 1);
                            break;
                        }
                    }
                }
                
                this.currentEditItem = null;
            }

            this.$nextTick(() => {
                var item = data;
                let quantity = item.quantity;
                if(this.orders[item.key])
                {
                    if(item.max_quantity_enabled){
                        if(quantity > item.max_quantity) return this.Info(`La cantidad máxima permitida de ${item.title} es ${item.max_quantity}`);
                        if(this.orderGroupById && quantity + this.orderGroupById[item.id] > item.max_quantity) return this.Info(`La cantidad máxima permitida de ${item.title} es ${item.max_quantity}`);
                    }

                    this.$set(this.orders[item.key], 'quantity', this.Util.RoundFloat(this.orders[item.key].quantity + item.quantity));
                }
                else
                {
                    if(item.max_quantity_enabled && this.orderGroupById && quantity + this.orderGroupById[item.id] > item.max_quantity) return this.Info(`La cantidad máxima permitida de ${item.title} es ${item.max_quantity}`);
                    this.$set(this.orders, item.key, item);
                }
            })
        },
        RemoveItem(item)
        {
            if(item.quantity <= 1)
                this.$delete(this.orders, item.key);
            else
                this.$set(item, 'quantity', this.Util.RoundFloat(item.quantity-1));
        },
        RemoveOldItem(item)
        {
            this.Confirm({
                title: '¿Quitar item?',
                text: 'Quitar',
                class: 'error-color',
                function: () => {
                    if(item.quantity == 1)
                    {
                        for(var i = 0; i < this.modal.orders.length; i++)
                        {
                            if(this.modal.orders[i] == item)
                                return this.modal.orders.splice(i, 1);
                        }
                    }
                    else
                    {
                        this.$set(item, 'quantity', item.quantity-1);
                    }
                        
                }
            });
        },
        EditItem(item, index, list)
        {
            this.currentEditItem = {
                item: item,
                index: index,
                list: list ? list : 'NEW'
            };
            this.$refs.itemsSelection.SearchAddItem(item, true);
        },
        Edit() 
        {
            var oldOrders = JSON.parse(JSON.stringify(this.modal.orders));
            var orders = JSON.parse(JSON.stringify(this.modal.orders));

            for(var key in this.orders)
            {
                var item = this.orders[key];
                orders.push(item);
            }

            for(var i = 0; i < orders.length; i++)
            {
                orders[i].total_money = parseInt(orders[i].quantity * orders[i].price);
            }

            var func = (comment) => {
                this.Loading();
                axios.patch(`/transaction/${this.modal.id}/order`, {orders: orders, edit_at: this.modal.edit_at, edit_comment: comment}).then(res => {
                    console.log(res);
                    this.Response(res);
                    this.modal = null;
                    this.$emit('OnTransactionEdit', {transaction: res.data, new: this.orders, old: oldOrders});
                }).catch(err => {
                    this.Response(err);
                    var transaction = err.response.data.transaction;
                    if(transaction)
                    {
                        var added = this.orders;
                        this.Open(transaction);
                        this.orders = added;
                    }
                });
            }

            //se quitaron items y se requiere comentario
            if(this.oldTotal - this.total.old > 0) {
                this.Confirm({
                    title: '¿Editar pedido?',
                    text: 'Editar pedido',
                    input: 'Comentario',
                    function: func
                });
            }
            else
                func();

        },
        ChangePlatform(platform){
            this.typeMenu = platform;
            localStorage.setItem('platform', platform);
            this.$emit('OnChangePlatform');
        }
        /*
        ChangeType(type)
        {
            this.Confirm({
                text: 'Cambiar',
                title: '¿Cambiar tipo de pedido?',
                input: 'Comentario',
                function: (comment) => {
                    this.Loading();
                    axios.patch(`/transaction/${this.modal.id}/order/type`, {type: type, edit_comment: comment}).then(res => {
                        this.Response(res);
                        this.modal = null;
                        this.$emit('OnTransactionEdit', res.data);
                    }).catch(err => {
                        this.Response(err);
                    });
                }
            })
        }
        */
    },
    computed: {
        total() {
            if(!this.modal) return {old: 0, new: 0, total: 0};
            var oldTotal = 0;
            var newTotal = 0;
            for(var key in this.modal.orders)
                oldTotal += this.modal.orders[key].price * this.modal.orders[key].quantity;
            
            for(var key in this.orders)
                newTotal += this.orders[key].price * this.orders[key].quantity;

            return {old: oldTotal, new: newTotal, total: oldTotal + newTotal};
        },
        canEditOld() {
            return !!(this.Auth.role.indexOf('admin') > -1 || this.Auth.restrictions.edit_previous_items);
        },
        orderGroupById(){
            if(!this.modal || !this.modal.orders.length) return;
            const groupedData = {};
            this.modal.orders.forEach( item => {
				const id = item.id;
				const quantity = item.quantity;

				if (!groupedData[id]) {
                    groupedData[id] = 0;
                }

				groupedData[id] += quantity;
			});

            if(!this.orders || !Object.keys(this.orders).length) return groupedData;

            for(let index in this.orders){
                if(this.orders.hasOwnProperty(index)){
                    const tmpItem = this.orders[index];
                    const id = tmpItem.id;
                    const quantity = tmpItem.quantity;

                    if (!groupedData[id]) {
                        groupedData[id] = 0;
                    }
                    
                    groupedData[id] += quantity;
                }
            }

            return groupedData;
        },
        platforms(){
            if(!this.overwrites) return [];

            let overwrites = Util.Copy(this.overwrites);
            const uniqueTypes = [...new Set(overwrites.map(overwrite => overwrite.type))];

            if(!uniqueTypes.length || (uniqueTypes.length == 1 && uniqueTypes[0] == "SCHEDULE")) return []; //Sí sólo tiene horarios SCHEDULE

            const index = uniqueTypes.indexOf("SCHEDULE");
            if(index !== -1) uniqueTypes.splice(index, 1);

            uniqueTypes.unshift("SCHEDULE");

            const formattedTypes = uniqueTypes.map(type => {
                return {
                    value: type,
                    text: this.dictPlatforms[type]? this.dictPlatforms[type] : type
                };
            });

            return formattedTypes;
        },
        filteredOverwrites(){
            if(!this.overwrites) return;
            if(!this.typeMenu) return this.overwrites;
            let overwrites = Util.Copy(this.overwrites);
            const filtered = overwrites.filter(overwrite => overwrite.type  == this.typeMenu);
            return filtered;
        }
    },
    beforeDestroy() {
        if(this.timer) clearInterval(this.timer);
    }

}
</script>

<style>

</style>