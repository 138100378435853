<template>
<div>
<modal v-model="modal" dir="right" :modalClass="['fill', 'md right h100']" :title="`Pedido Código: ${transaction ? Util.Zeropad(transaction.id, 10) : ''}`" hideActions>
	
	<i v-if="transaction && (isAdmin || (Auth.restrictions && view == 'IAP' ? Auth.restrictions.print_iap_voucher : Auth.restrictions.can_reprint)) && (transaction.status == 'SCHEDULED' || (Auth.role.indexOf('admin') > -1 || Auth.restrictions.print_iap_voucher))" class="options icon-printing ml5" slot="options" @click="Options([
		{text: 'Imprimir Voucher', function: () => {Util.PrintTransaction(Util.InfluyeTransactionToTransactionV2(transaction), 'DEFAULT')}, if: !!Local.modules.module_service && (Auth.role.indexOf('admin') > -1 || view == 'IAP' ? true :  Auth.restrictions.reprint_options.includes('voucher'))},
		{text: 'Imprimir para Cocina', function: () => {Util.PrintTransaction(Util.InfluyeTransactionToTransactionV2(transaction), 'PENDING')}, if: !!Local.modules.module_service && (Auth.role.indexOf('admin') > -1 || view == 'IAP' ? true :  Auth.restrictions.reprint_options.includes('kitchen'))},
		{text: 'Imprimir para Delivery', function: () => {Util.PrintTransaction(Util.InfluyeTransactionToTransactionV2(transaction), 'DELIVERY')}, if: !!Local.modules.module_service && (Auth.role.indexOf('admin') > -1 || view == 'IAP' ? true :  Auth.restrictions.reprint_options.includes('delivery'))},
		{text: 'Imprimir Todos', function: () => {Util.PrintTransaction(Util.InfluyeTransactionToTransactionV2(transaction))}, if: Auth.role.indexOf('admin') > -1 || view == 'IAP' ? true : ['voucher', 'kitchen', 'delivery'].every(value => Auth.restrictions.reprint_options.includes(value))},
	])"></i>
	

	<div v-if="transaction" class="pa20 copyable">
	
	<!-- Log -->
	<div>

		<div class="f18 mb20">Historial</div>

		<div class="mb15">
			<div class="bold">{{Util.Date(transaction.created_at, 'LLLL')}}</div>
			<div>Pedido creado {{transaction.source == 'INFLUYE_WEB' ? 'desde Ecommerce' : 'desde POS'}}</div>
		</div>

		<div class="mb15" v-if="transaction.payment_at">
			<div class="bold">{{Util.Date(transaction.payment_at, 'LLLL')}}</div>
			<div>Pago confirmado</div>
		</div>

		<div class="mb15" v-if="transaction.accepted_at">
			<div class="bold">{{Util.Date(transaction.accepted_at, 'LLLL')}}</div>
			<div>Pedido aceptado</div>
		</div>
		
		<div class="mb15" v-if="transaction.delivery_at">
			<div class="bold">{{Util.Date(transaction.delivery_at, 'LLLL')}}</div>
			<div>Pedido enviado</div>
		</div>

		<div class="mb15" v-if="transaction.ready_at">
			<div class="bold">{{Util.Date(transaction.ready_at, 'LLLL')}}</div>
			<div>Pedido listo para retirar</div>
		</div>
		
		<div class="cblue" v-if="transaction.completed_at">
			<div class="bold">{{Util.Date(transaction.completed_at, 'LLLL')}}</div>
			<div>Pedido completedo</div>
		</div>
		<div class="cred" v-if="transaction.rejected_at">
			<div class="bold">{{Util.Date(transaction.rejected_at, 'LLLL')}}</div>
			<div>Pedido cancelado/rechazado <span class="secondary-text">({{Util.CancelledReason(transaction.rejected_message ? transaction.rejected_message : transaction.messages)}})</span></div>
		</div>

	</div>

	<div>

		<div class="separator mb20 mt20"></div>

		<div class="f18 mb20">Datos del Pedido</div>

		<div class="flex mb15" v-if="transaction.id_transaction" >
			<div class="flex-fill">
				<div class="bold">Transacción </div>
				<div>#{{Util.Zeropad(transaction.id_transaction, 10)}}</div>
			</div>
			<button class="secondary" @click="$refs.transactionDetails.Open(transaction.id_transaction)">Ver Transacción</button>
		</div>

		<div class="mb15">
			<div class="bold">Tipo Pedido</div>
			<div>{{TransactionType[transaction.type]}}</div>
		</div>

		<div class="mb15" v-if="transaction.takeaway_time && transaction.takeaway_time !== 'SCHEDULE'">
			<div class="bold">Hora Entrega</div>
			<div v-if="transaction.takeaway_time == 'AUTO'">Lo antes posible</div>
			<div v-else>{{transaction.takeaway_time}}</div>
		</div>

		<div class="mb15" v-if="transaction.scheduled_at">
			<div class="bold">Fecha Entrega</div>
			<div>{{Util.Date(transaction.scheduled_at, 'HH:mm DD/MM/YYYY')}}</div>
		</div>

		<div class="mb15" v-if="transaction.type == 'SHIPMENT' && typeof transaction.messages !== 'string'">
			<div class="bold">Envio</div>
			<div v-if="transaction.messages.shipment_end">entre el {{Util.Date(transaction.messages.shipment_start, 'D [de] MMMM')}} y el {{Util.Date(transaction.messages.shipment_end, 'D [de] MMMM')}}</div>
			<div v-else>el {{Util.Date(transaction.messages.shipment_start, 'D [de] MMMM')}}</div>
		</div>

		<div class="mb15" v-if="transaction.customer_name">
			<div class="bold">Cliente</div>
			<div>{{transaction.customer_name}}</div>
		</div>
		
		<div class="mb15 flex" v-if="transaction.address">
			<div class="flex-fill">
				<div class="bold">Dirección </div>
				<div>{{transaction.address}}</div>
				<div v-if="transaction.address_comment">{{transaction.address_comment}}</div>
			</div>
			
			<i class="options icon-dots-vertical" @click="Options([
				{text: 'Buscar en Waze', function: () => {Util.OpenWaze(transaction.address)}, if: mobile},
				{text: 'Buscar en Google Maps', function: () => {Util.OpenGoogleMaps(transaction.addresss)}},
				{text: 'Abrir Ubicación en Google Maps', function: () => {Util.OpenGoogleMaps(transaction)}, if: !!transaction.longitude},
			])"></i>
			
		</div>

		<div class="mb15 flex" v-if="transaction.phone">
			<div class="flex-fill">
				<div class="bold">Contacto </div>
				<div>{{transaction.phone}}</div>
			</div>
			
			<i class="options icon-dots-vertical" @click="Options([
				{text: 'Llamar', function: () => {Util.Open(`tel:${transaction.phone}`)}},
				{text: 'Abrir en Whastapp', function: () => {Util.OpenWhatsapp(transaction.phone, '')}},
			])"></i>
			
		</div>

		<div class="" v-if="transaction.custom_data && transaction.custom_data.email">
			<div class="bold">Email </div>
			<div>{{transaction.custom_data.email}}</div>
		</div>
		
	</div>

	<div class="" v-if="transaction.custom_data && transaction.custom_data.pedidosya_courier">
		<div class="separator mb20 mt20"></div>

		<div class="flex mb20 align-center">

			<div class="f18 flex-fill">Datos de PedidosYa Courier</div>

			<i class="options icon-dots-vertical" @click="Options([
				{text: 'Soporte', function: () => { Util.Open(transaction.custom_data.pedidosya_courier.onlineSupportUrl) } },
				{text: 'Seguir envío', function: () => { Util.Open(transaction.custom_data.pedidosya_courier.shareLocationUrl) }, if: transaction.custom_data.pedidosya_courier.shareLocationUrl },
			])"></i>
		</div>

		<div class="hdvm mb15">
			<div>
				<div class="bold">N° Referencia</div>
				<div>{{transaction.custom_data.pedidosya_courier.referenceId}}</div>
			</div>
			<div>
				<div class="bold">Código de confirmación </div>
				<div>{{transaction.custom_data.pedidosya_courier.confirmationCode}}</div>
			</div>
		</div>
		
		
		<div class="hdvm mb15">
			<div>
				<div class="bold">Recolección</div>
				<div>{{Util.Date(transaction.custom_data.pedidosya_courier.pickupTime, 'HH:mm DD/MM/YYYY')}}</div>
			</div>

			<div>
				<div class="bold">Entrega</div>
				<div>{{Util.Date(transaction.custom_data.pedidosya_courier.deliveryTime, 'HH:mm DD/MM/YYYY')}}</div>
			</div>
		</div>

		<div class="">
			<div class="bold">Estado </div>
			<div>{{Util.StatusOrderPya[transaction.custom_data.pedidosya_courier.status]}}</div>
		</div>

	</div>

	<!-- items -->
	<div>

		<div class="separator mb20 mt20"></div>

		<div class="f18 mb20">Pedido</div>

		<div>
			<div class="well mb10" 
			v-for="(order, index) in transaction.orders"
			:key="index">
				<div class="order-item">
					<div>{{Util.Number(order.quantity)}}</div>
					<div>{{order.title}}
						<ul>
							<li v-for="(modifier, index) in Util.ModifiersList(order)" :class="modifier.type" :key="index">{{modifier.value}}</li>
						</ul>
					</div>
					<div v-if="order.price">{{Util.Price(order.price * order.quantity)}}</div>
					<div v-else>{{Util.Price(order.total * order.quantity)}}</div>
				</div>
			</div>
		</div>

		<div class="well mb10" v-if="transaction.comment">
			<div class="bold">Comentario</div>
			<div>{{transaction.comment}}</div>
		</div>
		<div class="mt20 flex" v-if="transaction.total.order">
			<div class="bold flex-fill">Subtotal</div>
			<div class="bold">{{Util.Price(transaction.total.order)}}</div>
		</div>
		<div class="flex" v-if="transaction.total.discount">
			<div class="bold flex-fill">Descuento</div>
			<div class="bold">{{Util.Price(transaction.total.discount)}}</div>
		</div>
		<div class="flex" v-if="transaction.total.round">
			<div class="bold flex-fill">Ley del Redondeo</div>
			<div class="bold">{{Util.Price(transaction.total.round)}}</div>
		</div>
		<div class="flex" v-if="transaction.total.delivery">
			<div class="bold flex-fill">Reparto</div>
			<div class="bold">{{Util.Price(transaction.total.delivery)}}</div>
		</div>
		<div :class="`f18 flex`">
			<div class="bold flex-fill">Total</div>
			<div class="bold">{{Util.Price(transaction.total.total)}}</div>
		</div>
	</div>
	
	<!-- metodos de pago -->
	<div>

		<div class="separator mb20 mt20"></div>

		<div class="f18 mb20">Método de Pago</div>
		
		<div class="">
			<div class="">{{Util.PayMethod(transaction.payment_method)}}</div>
			<div v-if="transaction.cash" class="secondary-text"><span class="bold">Se paga con: </span>{{Util.Price(transaction.cash)}}</div>
			<div v-if="transaction.cash" class="secondary-text"><span class="bold">Vuelto: </span> {{Util.Price(transaction.cash - transaction.total.total)}}</div>
		</div>

	</div>

	</div>

</modal>

<transaction-details v-if="modal" ref="transactionDetails"></transaction-details>

</div>
</template>

<script>
export default {
	data() {
		return {
			modal: null,
			transaction: null,
			TransactionType: {
				DELIVERY: 'Delivery',
				TAKEAWAY: 'Retirar',
				SHIPMENT: 'Encargo'
			},
			view : null
		}
	},
	methods: {
		Open(transaction, view) {
			this.view = view
			if(typeof transaction == 'number')
			{
				this.Loading();
				axios.get(`/iap/${transaction}`).then(res => {
					this.transaction = res.data;
					this.modal = true;
					this.Response(res);
				}).catch(err => {
					this.Response(err);
				});
			}
			else
			{
				this.modal = true;
				this.transaction = transaction;
			}
		},
		Close() {
			this.modal = false;
			this.transaction = null;
		},
	},
	computed: {

	}
}
</script>
<style>

</style>